<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="pa-2 br-20">
      <div class="mitr d-flex align-center mt-4 mb-4 pl-4 pr-4" style="font-size: 1rem;">
        <div style="width: 24px;">

        </div>
        <v-spacer></v-spacer>
        Where to?
        <v-spacer></v-spacer>
        <v-btn icon color="text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-row dense class="pl-2 pr-2">
        <v-col cols="6">
          <a href="https://unicrypt.network" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-home</v-icon>
              <div>
                Home
              </div>
            </v-card>
          </a>
        </v-col>
        <v-col cols="6">
          <a href="https://docs.unicrypt.network" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-file-document-outline</v-icon>
              <div>
                Docs
              </div>
            </v-card>
          </a>
        </v-col>
        <!--
        <v-col cols="6">
          <a href="https://farm.unicrypt.network/" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-sprout-outline</v-icon>
              <div>
                Yield farming
              </div>
              <div class="t-small textFaint--text">
                Mainnet
              </div>
            </v-card>
          </a>
        </v-col>
        -->
        <v-col cols="6">
          <v-card outlined class="pa-4 text-center textFaint--text">
            <v-icon color="textFaint">mdi-sprout-outline</v-icon>
            <div>
              Yield farming
            </div>
            <div class="t-small textFaint--text">
              Mainnet
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <a href="https://kovan-farm.unicrypt.network/" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon color="">mdi-sprout-outline</v-icon>
              <div class="">
                Yield farming
              </div>
              <div class="t-small textFaint--text">
                Kovan testnet
              </div>
            </v-card>
          </a>
        </v-col>

        <v-col cols="6">
          <a href="https://v2.unicrypt.network" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-lock-outline</v-icon>
              <div>
                Locker
              </div>
            </v-card>
          </a>
        </v-col>

        <v-col cols="6">
          <a href="https://unibot.network/" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <img 
              src="@/assets/img/unibot.svg" 
              height="30px"
              width="30px">
              <div style="margin-top: -11px;">
                Unibot
              </div>
            </v-card>
          </a>
        </v-col>

        <v-col cols="6">
          <a href="https://uncldrop.unicrypt.network/" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-airplane</v-icon>
              <div>
                UNCL airdrop
              </div>
              <div class="t-small textFaint--text">
                Finished
              </div>
            </v-card>
          </a>
        </v-col>
        <v-col cols="6">
          <a href="https://unicrypt.network/swap" class="deadlink">
            <v-card class="pa-4 text-center c-list">
              <v-icon>mdi-autorenew</v-icon>
              <div>
                Swap
              </div>
              <div class="t-small textFaint--text">
                UNC
                <v-icon x-small color="textFaint" class="ml-1 mr-1">mdi-arrow-right</v-icon>
                UNCX
              </div>
            </v-card>
          </a>
        </v-col>

      </v-row>

      <div class="mt-4 mb-2 d-flex align-center justify-center">
        <v-btn icon color="textFaint" x-large href="https://twitter.com/UNCX_token" target="_blank">
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
        <v-btn color="textFaint" x-large icon rounded href="https://t.me/uncx_token" target="_blank">
          <v-icon>mdi-telegram</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false
  }),

  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
  }
}
</script>

<style lang="scss">
.deadlink {
   text-decoration: none;
   color: unset!important;
}
</style>