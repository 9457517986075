<template>
  <div style="display: inline-block;">
    <div class="d-flex align-center">
      <v-btn small text rounded @click="copyTokenAddress" :color="color" class="pa-0 pl-1 pr-1">
        {{ tokenAddressCondensed }}
        <v-icon x-small class="ml-2">mdi-content-copy</v-icon>
        <v-card v-if="textCopied" class="pa-2 text" style="position: absolute;left: 0;top: -20px;z-index: 1;">
          <div class="background--text">
            Copied
          </div>
        </v-card>
      </v-btn>
      <div style="visibility: hidden; position: absolute;" class="t-small">
        Copied
      </div>
      <div ref="copyContainer" class="t-small">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      deafult: ''
    },
    color: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    textCopied: false,
  }),

  computed: {
    tokenAddressCondensed () {
      var address = this.address
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    }
  },
  
  methods: {
    copyTokenAddress: function () {
      var container = this.$refs.copyContainer
      this.$copyText(this.address, container).then(() => {
        this.textCopied = true
        setTimeout(() => {
          this.textCopied = false
        }, 1000)
      }, function (e) {
        alert('Can not copy')
      })
    },
  }
}
</script>