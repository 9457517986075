import {store} from '@/store/index'
import { ethers } from "ethers"
import farmABI from '@/smart-contracts/farm-abis'
import ERC20 from '@/smart-contracts/erc20'

const Self = {
  getGasInfo: async () => {
    var sEthers = store.state.ethers
    var FarmGenerator01 = new ethers.Contract(farmABI.farm_generator_01_address, farmABI.farm_generator_01_abi, sEthers.provider)
    var fees = await FarmGenerator01.gFees()
    var address = fees[0]
    var token = {}
    try {
      token = await ERC20.getERC20(address)
    } catch {}
    return {
      token: token,
      useGasToken: Boolean(fees[1]),
      gasFee: fees[2].toString(),
      ethFee: fees[3].toString(),
      tokenFee: fees[4].toString()
    }
  },
  createFarm: async (_token, _amount, _lpToken, _blockReward, _startBlock, _bonusEndBlock, _bonus, ethFee) => {
    var sEthers = store.state.ethers
    var FarmGenerator01 = new ethers.Contract(farmABI.farm_generator_01_address, farmABI.farm_generator_01_abi, sEthers.signer)
    return FarmGenerator01.createFarm(_token, _amount, _lpToken, _blockReward, _startBlock, _bonusEndBlock, _bonus, {value: ethFee})
  },
  farmsLength: async () => {
    var sEthers = store.state.ethers
    var FarmFactory = new ethers.Contract(farmABI.farm_factory_address, farmABI.farm_factory_abi, sEthers.provider)
    var length = await FarmFactory.farmsLength()
    return length.toString()
  },
  determineEndBlock: async (amount, blockReward, startBlock, bonusEndBlock, bonus) => {
    var sEthers = store.state.ethers
    var FarmGenerator01 = new ethers.Contract(farmABI.farm_generator_01_address, farmABI.farm_generator_01_abi, sEthers.provider)
    var info = await FarmGenerator01.determineEndBlock(amount, blockReward, startBlock, bonusEndBlock, bonus)
    return {
      endBlock: info[0].toString(),
      requiredAmount: info[1].toString(),
      fee: info[2].toString(),
    }
  },
  determineBlockReward: async (amount, startBlock, bonusEndBlock, bonus, endBlock) => {
    var sEthers = store.state.ethers
    var FarmGenerator01 = new ethers.Contract(farmABI.farm_generator_01_address, farmABI.farm_generator_01_abi, sEthers.provider)
    var info = await FarmGenerator01.determineBlockReward(amount, startBlock, bonusEndBlock, bonus, endBlock)
    return {
      blockReward: info[0].toString(),
      requiredAmount: info[1].toString(),
      fee: info[2].toString()
    }
  },
  getFarmById: async (_id) => {
    var sEthers = store.state.ethers

    var FarmFactory = new ethers.Contract(farmABI.farm_factory_address, farmABI.farm_factory_abi, sEthers.provider)
    var farmAddress = await FarmFactory.farmAtIndex(_id)

    var Farm = new ethers.Contract(farmAddress, farmABI.farm_01_abi, sEthers.provider)
    var farmInfo = await Farm.farmInfo()
    return {
      farmAddress: farmAddress,
      lpToken: farmInfo[0],
      rewardToken: farmInfo[1],
      startBlock: farmInfo[2].toString(),
      blockReward: farmInfo[3].toString(),
      bonusEndBlock: farmInfo[4].toString(),
      bonus: farmInfo[5].toString(),
      endBlock: farmInfo[6].toString(),
      lastRewardBlock: farmInfo[7].toString(),
      accRewardPerShare: farmInfo[8].toString(),
      farmableSupply: farmInfo[9].toString(),
      num_farmers: farmInfo[10].toString()
    }
  },
  pendingFarmRewards: async (_farmAddress, _user) => {
    var sEthers = store.state.ethers
    var Farm = new ethers.Contract(_farmAddress, farmABI.farm_01_abi, sEthers.provider)
    var balance = await Farm.pendingReward(_user)
    return balance.toString()
  },
  updatePool: async (farmAddress) => {
    var sEthers = store.state.ethers
    var Minter = new ethers.Contract(farmAddress, farmABI.farm_01_abi, sEthers.signer)
    return Minter.updatePool()
  },
  depositUniswapPool: async (farmAddress, _amount) => {
    var sEthers = store.state.ethers
    var Farm = new ethers.Contract(farmAddress, farmABI.farm_01_abi, sEthers.signer)
    return Farm.deposit(_amount)
  },
  emergencyWithdraw: async (farmAddress) => {
    var sEthers = store.state.ethers
    var Farm = new ethers.Contract(farmAddress, farmABI.farm_01_abi, sEthers.signer)
    return Farm.emergencyWithdraw()
  },
  withdrawUniswapPool: async (farmAddress, amount) => {
    var sEthers = store.state.ethers
    var Farm = new ethers.Contract(farmAddress, farmABI.farm_01_abi, sEthers.signer)
    return Farm.withdraw(amount)
  },
  userInfo: async (_farmAddress, _user) => {
    var sEthers = store.state.ethers
    var Farm = new ethers.Contract(_farmAddress, farmABI.farm_01_abi, sEthers.provider)
    var info = await Farm.userInfo(_user)
    return {
      amountWithdrawable: info[0].toString(),
      rewardDebt: info[1].toString()
    }
  },
  // USER INFO
  userFarmsLength: async (_user) => {
    var sEthers = store.state.ethers
    var FarmFactory = new ethers.Contract(farmABI.farm_factory_address, farmABI.farm_factory_abi, sEthers.provider)
    var length = await FarmFactory.userFarmsLength(_user)
    return length.toString()
  },
  userFarmAtIndex: async (_user, _index) => {
    var sEthers = store.state.ethers
    var FarmFactory = new ethers.Contract(farmABI.farm_factory_address, farmABI.farm_factory_abi, sEthers.provider)
    var address = await FarmFactory.userFarmAtIndex(_user, _index)
    return address;
  },
  // UNISWAP
  getV2PairInfo: async (_lpToken) => {
    var sEthers = store.state.ethers
    var UniswapPair = new ethers.Contract(_lpToken, farmABI.v2_pair_abi, sEthers.provider)
    var token0 = await UniswapPair.token0()
    var token1 = await UniswapPair.token1()
    var totalSupply = await UniswapPair.totalSupply()
    var reserves = await UniswapPair.getReserves()
    token0 = await ERC20.getERC20(token0)
    token1 = await ERC20.getERC20(token1)
    return {
      address: _lpToken,
      token0: token0,
      token1: token1,
      reserve0: reserves[0].toString(),
      reserve1: reserves[1].toString(),
      totalSupply: totalSupply.toString()
    }
  },
}

export default Self