<template>
  <img 
  :src="`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`" 
  :height="`${size}px`"
  :width="`${size}px`"
  @error="setAltImg">
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 30
    }
  },

  methods: {
    setAltImg (event) { 
      event.target.src = require('@/assets/img/no-icon.png')
      // event.target.src = require('@/assets/img/UNCL_svg.svg')
    }
  }
}
</script>