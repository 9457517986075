<template>
  <v-row dense class="align-center">
    <v-col cols="6">
      <v-btn color="" text class="font-weight-bold" @click="$router.push(`/pairs/${farm.lpToken}`)">
        {{ poolInfo.token0.symbol }} / {{ poolInfo.token1.symbol }}
      </v-btn>
      <!--
      <v-btn :to="`/farms/edit/${farm.id}`" small text color="text">
        <v-icon small>mdi-tractor</v-icon>
        <span>{{ farm.id }}</span>
      </v-btn>
      -->
    </v-col>
    <v-col cols="6" class="text-end">
      <div v-if="farmingHasEnded" class="text--text caption font-italic">
        Farming has ended {{farm.endBlock}}
      </div>
      <div v-else class='caption text--text'>
        {{ farm.apy / 10 }}% APY
        <div class="t-small">
          per {{ farm.token0IsPeg ? poolInfo.token0.symbol : poolInfo.token1.symbol }}
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { ethers } from 'ethers'

export default {
  props: {
    farm: {
      type: Object,
      default: function () {
        return {
          id: null
        }
      }
    }
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    poolInfo () {
      return this.$store.state.pairs[this.farm.lpToken]
    },
    farmingHasEnded () {
      if (this.farm.endBlock === '0') {
        return false
      }
      return ethers.BigNumber.from(this.blockNumber).gt(this.farm.endBlock)
    }
  }
}
</script>