<template>
  <div v-if="uniswapPoolInfo.farmLength > 0" class="pa-1">
    <div class="d-flex align-center">
      <div class="ml-4 mt-6 mb-3">
        {{ uniswapPoolInfo.farmLength }} farm{{ uniswapPoolInfo.farmLength === '1' ? '' : 's' }}
      </div>
      <v-spacer></v-spacer>
      <v-btn to="/account" outlined rounded color="primary" class="font-weight-bold">
        Harvest
      </v-btn>
    </div>
    
    <div v-for="farm of farms" :key="farm.id + 'farm'" class="">
      <farm-for-pool :farmId="String(farm.id)" class="mt-4 br-20"></farm-for-pool>
    </div>
  </div>

  <div v-else class="pa-6 text-center">
    No farms for this pool
  </div>
</template>

<script>
import FarmForPool from '@/views/farm-for-pool'

export default {
  props: {
    address: {
      type: String,
      default: ''
    },
  },

  components: {
    FarmForPool
  },

  data: () => ({
    uniswapPoolInfo: {
      token0: {},
      token1: {},
      farmLength: 0,
      reserve0: '0',
      reserve1: '0',
      totalSupply: '0'
    }
  }),

  methods: {
    async refresh () {
      var uniswapPoolInfo = JSON.parse(JSON.stringify(this.$store.state.pairs[this.address]))
      this.uniswapPoolInfo = uniswapPoolInfo
      var farms = JSON.parse(JSON.stringify(Object.values(this.$store.state.farms)))
      this.farms = farms.filter(farm => farm.lpToken === this.address)
    },
  },

  created () {
    this.refresh()
  }
}
</script>