<template>
  <v-card class="">
    <expansion-panel>
      <template v-slot:header="{ expand, toggle }">
        <div @click.stop="toggle">
          
          <!--
          <template v-if="userStakedBalance !== '0'">
            <div class="purple-button white--text pa-2 pl-4 pr-4 d-flex">
              <template>
                Staking {{ userStakedBalanceHuman }} univ2
              </template>
              <v-spacer></v-spacer>
              <v-icon color="white" class="ml-1">mdi-sprout-outline</v-icon>
            </div>
          </template>
          -->

          <!--
          <div v-if="farmingHasEnded" class="border-b pa-2 pl-4 pr-4 d-flex align-center">
            Farming period is over
            <v-spacer></v-spacer>
            <span class="textFaint--text caption font-italic">No more rewards</span>
          </div>
          -->

          <!--
          <div v-if="rewardBalanceHuman !== '0'">
            <v-row dense class="d-flex align-end ma-0 pa-0 border-b">
              <v-col cols="6" class="pl-4">
                <v-icon small v-if="userStakedBalance !== '0'" color="">mdi-sprout-outline</v-icon>
                <span v-if="userStakedBalance !== '0'" class="caption">
                  {{ usersPercentOfPool }}%
                </span>
              </v-col>
              <v-col cols="6" class="text-end pr-4">
                <div class="mitr">
                  ${{ rewardValueHuman }}
                </div>
              </v-col>
            </v-row>
          </div>
          -->

          <div class="d-flex align-center pl-4 pr-4 pt-4 pb-2">
            <coin-icon :address="farm.reward_token.address" :size="32"></coin-icon>
            <div class="ml-2">
              <div class="t-large font-weight-bold d-flex align-center">
                Farm {{ farm.reward_token.symbol }}
                <v-icon small color="textFaint" :class="['ml-1', {'rotate-180': expand}]">mdi-chevron-down</v-icon>
              </div>
              <div class="d-flex align-center textFaint--text t-small text-uppercase">
                {{ farm.token0.symbol }} / {{ farm.token1.symbol }}
                <!--
                <coin-icon :address="farm.token0.address" :size="16" class="ml-2"></coin-icon>
                <coin-icon :address="farm.token1.address" :size="16" class="mr-1"></coin-icon>
                -->
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="text-end">
              <template v-if="rewardBalanceHuman !== '0'">
                <div class="t-large font-weight-bold">
                  ${{ rewardValueHuman }}
                </div>
                <div class="t-small textFaint--text">
                  {{ rewardBalanceHuman }}
                </div>
              </template>
            </div>
          </div>

          <!-- INFO ROW -->
          <div class="textFaint--text" style="min-height: 76px;">

            <div v-if="firstLoadInfoRow" class="textFaint--text caption pa-2 pl-4 pr-4">
              Loading...
            </div>

            <v-scroll-y-transition>
              <div v-if="!firstLoadInfoRow" class="textFaint--text">

                <div class="d-flex align-center caption pl-4 pa-2">
                  <div v-if="false" class="mr-1 font-weight-bold">
                    #
                    {{ farm.farm_index }}
                  </div>

                  <div v-if="startBlockUpcoming">
                    Farming starts {{ timeToStartBlock }} - {{ dateStartBlockHuman }}
                  </div>
                  <div v-else-if="bonusPeriodActive" class="font-weight-bold">
                    {{ farm.bonus }}x Bonus period!
                  </div>
                  <div v-else-if="endBlockUpcoming">
                    Farming ends {{ timeToEndBlock }}
                  </div>
                  <div v-else-if="farmingHasEnded">
                    Farming has ended
                  </div>

                  <v-spacer></v-spacer>
                </div>

                <v-row dense :class="['d-flex align-center ma-0 pa-0 font-weight-bold', {'caption': $vuetify.breakpoint.xs}]">
                  <v-col cols="3" class="text-center">
                    <template v-if="userStakedBalance !== '0'">
                      <v-icon small color="text">mdi-sprout-outline</v-icon>
                      <span class="text--text">
                        {{ usersPercentOfPool }}%
                      </span>
                    </template>
                    <template v-else>
                      <v-icon small color="textFaint">mdi-sprout-outline</v-icon>
                    </template>
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <!--
                    <v-icon small v-if="userStakedBalance !== '0'" color="primary">mdi-sprout-outline</v-icon>
                    <v-icon small v-else color="textFaint">mdi-sprout-outline</v-icon>
                    <span v-if="userStakedBalance !== '0'" class="caption primary--text">
                      {{ usersPercentOfPool }}%
                    </span>
                    -->

                    <v-icon small v-if="userUnstakedBalance !== '0'" color="text">mdi-water</v-icon>
                    <v-icon small v-else color="textFaint">mdi-water-outline</v-icon>
                    <span>
                      {{ farmingLpValueCondensed }}
                    </span>

                    <!--
                    <v-icon v-if="userStakedBalance !== '0'" x-small color="white" class="purple-button ml-1 pa-1" style="border-radius: 20px;">mdi-sprout</v-icon>
                    -->
                  </v-col>

                  <v-col cols="3" class="text-center">
                    <v-icon small color="textFaint">mdi-account-multiple-outline</v-icon>
                    <span>
                      {{ farm.num_farmers }}
                    </span>
                  </v-col>

                  <v-col cols="3" class="text-center">
                    <div v-if="!farmingHasEnded" class="ml-1 pa-1 pl-1 pr-1">
                      <!-- {{ blockRewardHuman }} -->
                      <v-icon small color="textFaint">mdi-arrow-up</v-icon>
                      <span>
                        {{ apy }}%
                      </span>
                    </div>
                  </v-col>

                </v-row>

              </div>

            </v-scroll-y-transition>
          </div>
          <!-- INFO ROW -->

          <div v-if="false" class="d-flex align-center mt-3 caption pa-4 pt-0">
            
            <div class="ml-1 text--text">
              <v-icon small color="textFaint">mdi-cube-outline</v-icon>
              {{ blockRewardHuman }}
            </div>

            <div v-if="farmingHasEnded" class="ml-4 textFaint--text">
              <v-icon small color="textFaint">mdi-ray-end</v-icon>
              ended
            </div>

            <div class="ml-4 textFaint--text">
              <v-icon small color="textFaint">mdi-ray-end</v-icon>
              {{ farm.end_block }}
            </div>

            <div v-if="userUnstakedBalance !== '0'" class="ml-1">
              | <span class="primary--text">Stake</span>
            </div>
            <v-spacer></v-spacer>
            <div v-if="rewardBalanceHuman !== '0'" class="mitr primary--text font-weight-regular">
              {{ rewardBalanceHuman }}
            </div>
          </div>

        </div>
      </template>
      <template v-slot:body>
        <div>

          <div class="border-b border-t pa-1">
            <v-btn @click="tab = 0" :color="tab === 0 ? 'text' : 'textFaint'" text>
              Stake
            </v-btn>
            <v-btn @click="tab = 1" :color="tab === 1 ? 'text' : 'textFaint'" text>
              Contracts
            </v-btn>
            <v-btn @click="tab = 2" :color="tab === 2 ? 'text' : 'textFaint'" text>
              Farm info
            </v-btn>
          </div>

          <div class="pa-4">

            <!-- USER TAB -->
            <template v-if="tab === 0">
              
              <div v-if="!sEthers.coinbase" class="text-center pa-4">
                <v-btn
                @click="connectWallet"
                :class="['mr-2 font-weight-bold']"
                text
                >
                    <span>Connect your wallet</span>
                </v-btn>
              </div>

              <div v-else-if="userUnstakedBalance === '0' && pendingAmount === '0' && userStakedBalance ==='0'" class="pa-6 mt-2">
                <div class="mb-6">
                  <div class="d-flex align-center text-uppercase mb-2 font-weight-bold">
                    <coin-icon :address="farm.token0.address" :size="24" class="mr-1"></coin-icon>
                    {{ farm.token0.symbol }}
                    <coin-icon :address="farm.token1.address" :size="24" class="ml-3 mr-1"></coin-icon>
                    {{ farm.token1.symbol }}
                  </div>
                  You need UniV2 liquidity tokens to farm this pool
                </div>
                <div class="mt-2">
                  <v-btn rounded outlined large block class="mt-2" :href="`https://v2.info.uniswap.org/pair/${farm.lp_token}`" target="_blank">
                    <img 
                    src="@/assets/img/uniswap_logo.svg" 
                    height="30px"
                    width="30px"
                    class="mr-3">
                      Get Liquidity tokens
                  </v-btn>

                </div>
              </div>

              <template v-else>
                
                <!--
                <div v-if="userStakedBalance !== '0'" class="mt-6 pa-2 pl-4 pr-4 d-flex align-center">
                  Your Farming this pool
                  <v-spacer></v-spacer>
                </div>
                -->
                <div v-if="isEmergecyFarm">
                  <div class="pink--text">
                    There is an error with this farm, please withdraw your LP tokens as rewards will not be harvestable.
                  </div>
                  <v-card :class="['pa-4 mt-6 br-8', {'background textFaint--text v-sheet--outlined': userStakedBalance === '0'}]">
                    <div class="caption d-flex align-center">
                      Staking
                    </div>
                    <div outlined class="mt-2 d-flex align-center">
                      <div class="mitr">
                        {{ userStakedBalanceHuman }} UNIv2
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn outlined rounded @click="emergencyWithdraw" :disabled="userStakedBalance === '0'">
                        Withdraw
                      </v-btn>
                    </div>
                    <div>
                      <span class="textFaint--text caption mitr">$ {{ userFarmLiquidityValue }}</span>
                    </div>
                  </v-card>
                </div>
                <template v-else>
                <v-card :class="['pa-4 mt-8 br-8', {'background textFaint--text v-sheet--outlined': userUnstakedBalance === '0'}]">
                  <div class="caption">
                    Available to stake
                  </div>
                  <div outlined class="mt-2 d-flex align-center">
                    <div class="mitr">
                      {{ userUnstakedBalanceHuman }} UNIv2
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="stake" class="" :disabled="userUnstakedBalance === '0'">
                      Stake
                    </v-btn>
                  </div>
                  <div>
                    <span class="textFaint--text caption mitr">$ {{ userUnstakedLiquidityValue }}</span>
                  </div>
                </v-card>

                <div class="text-end">
                  <v-btn text class="" :href="`https://v2.info.uniswap.org/pair/${farm.lp_token}`" target="_blank">
                    Add more liquidity <v-icon small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>

                <div v-if="userStakedBalance !== '0'" class="mt-6 ml-2 caption">
                  <v-icon small v-if="userStakedBalance !== '0'" color="primary" class="mr-2">mdi-sprout-outline</v-icon>
                  Your pool share: {{ usersPercentOfPool }}%
                </div>

                <v-card :class="['pa-4 mt-6 br-8', {'background textFaint--text v-sheet--outlined': userStakedBalance === '0'}]">
                  <div class="caption d-flex align-center">
                    Staking
                  </div>
                  <div outlined class="mt-2 d-flex align-center">
                    <div class="mitr">
                      {{ userStakedBalanceHuman }} UNIv2
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn outlined rounded @click="withdraw" :disabled="userStakedBalance === '0'">
                      Withdraw
                    </v-btn>
                  </div>
                  <div>
                    <span class="textFaint--text caption mitr">$ {{ userFarmLiquidityValue }}</span>
                  </div>
                </v-card>

                <!-- Unclaimed rewards -->
                <v-card :class="['pa-4 mt-6 br-8', {'background textFaint--text v-sheet--outlined': pendingAmount === '0'}]">
                  <div :class="['text-center mb-6', {'': pendingAmount !== '0'}, {'textFaint--text': pendingAmount === '0'}]">
                    Unclaimed rewards
                  </div>
                  <div class="d-flex align-center mb-1">
                    <coin-icon :address="farm.reward_token.address" :class="['mr-2', {'greyscale': pendingAmount === '0'}]" :size="24"></coin-icon>
                    <div>
                      <div class="font-weight-bold">{{ farm.reward_token.symbol }}</div>
                    </div>

                    <v-spacer></v-spacer>
                    <div class="text-end">
                      <div :class="['mitr', {'textFaint--text': pendingAmount === '0'}]">
                        {{ rewardBalanceHuman }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    Wallet balance:
                    <v-spacer></v-spacer>
                    <div>{{ userRewardTokenWalletBalanceHuman }}</div>
                  </div>
                  <v-btn :disabled="pendingAmount === '0'" class="mt-4 mb-4" @click="harvest" :loading="harvestLoading" block outlined color="primary">
                    Harvest
                  </v-btn>
                  <!--
                  <div class="t-small">
                    Decimals: {{ farm.rewardToken.decimals }}
                  </div>
                  -->
                  <div v-if="false" class="caption">
                    Your wallet balance: {{ userRewardTokenWalletBalance }}
                  </div>
                </v-card>
                </template>

              </template>

            </template>

            <!-- Contract TAB -->
            <template v-if="tab === 1">

              <!-- Token info rewards -->

              <div class="border-b">
                <div class="d-flex align-center pa-4">
                  <img 
                  src="@/assets/img/uniswap_logo.svg" 
                  height="40px"
                  width="40px"
                  class="white br-20 pa-1">
                  <div class="ml-4">
                    <div class="caption textFaint--text">
                      Uniswap pair
                    </div>
                    <div class="">
                      {{ farm.token0.symbol }} / {{ farm.token1.symbol }}
                    </div>
                    <div>
                      <copy-address :address="farm.lp_token"></copy-address>
                    </div>
                    <v-btn text class="pa-0" :href="`https://v2.info.uniswap.org/pair/${farm.lp_token}`" target="_blank">
                      View on uniswap
                    </v-btn>
                  </div>
                </div>
              </div>

              <div class="border-b">
                <div class="d-flex align-center pa-4">
                  <coin-icon :address="farm.reward_token.address" :size="40"></coin-icon>
                  <div class="ml-4">
                    <div class="caption textFaint--text">
                      Farmable Token
                    </div>
                    <div class="">
                      <span class="font-weight-bold">{{ farm.reward_token.symbol }}</span> / {{ farm.reward_token.name }}
                    </div>
                    <div>
                      <copy-address :address="farm.reward_token.address"></copy-address>
                    </div>
                    <v-btn text class="pa-0" :href="`https://v2.info.uniswap.org/token/${farm.reward_token.address}`" target="_blank">
                      View on uniswap
                    </v-btn>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex align-center pa-4">
                  <v-icon size="40" color="textFaint">mdi-sprout-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption textFaint--text">
                      Farm contract
                    </div>
                    <div>
                      <copy-address :address="farm.farm_address"></copy-address>
                    </div>
                    <v-btn text class="pa-0" :href="`https://etherscan.io/address/${farm.farm_address}`" target="_blank">
                      View on etherscan
                    </v-btn>
                  </div>
                </div>
              </div>
              
            </template>

            <!-- Contract TAB -->
            <template v-if="tab === 2">

              <!-- Token info rewards -->

              <div class="d-flex align-center caption textFaint--text justify-end pt-2 pb-2">
                show unformatted amounts
                <v-switch
                dense
                class="ml-2 pa-0 ma-0"
                v-model="showRawData"
                hide-details
                ></v-switch>
              </div>

              <v-card outlined v-if="showRawData" class="background br-8 pa-3 mb-4 mt-4 primary--text">
                Showing amounts with no decimal places (useful for developers)
              </v-card>

              <div>
                
                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-arrow-up</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      APY (in dollar value)
                    </div>
                    <div class="mitr display-1">
                      {{ apy }}%
                    </div>
                    <div class="caption textFaint--text mt-2">
                      This fluctuates based on the reward token price and the value of total lp tokens farming
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-water-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Total lp tokens farming
                    </div>
                    <div class="mitr headline">
                      $ {{ farmingLpValueHuman }}
                    </div>
                    <div class="caption">
                      <template v-if="showRawData">
                        {{ farm.total_lp_farming }}
                      </template>
                      <template v-else>
                        {{ totalLpInFarmHuman }}
                      </template>
                      UNIV2
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-cube-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Block reward
                    </div>
                    <div class="mitr">
                      <template v-if="showRawData">
                        {{ farm.block_reward }}
                      </template>
                      <template v-else>
                        {{ blockRewardHuman }}
                      </template>
                      {{ farm.reward_token.symbol }}
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-ray-start</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Start Block
                    </div>
                    <div class="mitr">
                      {{ farm.start_block }}
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-ray-end</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Bonus Multiplier / Bonus end block
                    </div>
                    <div class="">
                      {{ farm.bonus }}x Bonus
                    </div>
                    <div class="">
                      {{ dateBonusEndHuman }} / {{ timeToBonusEndBlock }}
                    </div>
                    <div class="caption">
                      Block: {{ farm.bonusEndBlock }}
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-ray-end</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      End block ({{ timeToEndBlock }})
                    </div>
                    <div class="mitr">
                      {{ farm.end_block }}
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-shield-lock-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Amount in vault
                    </div>
                    <div class="mitr">
                      <template v-if="showRawData">
                        {{ vaultBalance }}
                      </template>
                      <template v-else>
                        {{ vaultBalanceHuman }}
                      </template>
                      {{ farm.reward_token.symbol }}
                    </div>
                  </div>
                </div>

                <div class="d-flex border-b pa-4">
                  <v-icon size="40" color="textFaint">mdi-account-multiple-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Number of farmers
                    </div>
                    <div class="mitr">
                      {{ farm.num_farmers }}
                    </div>
                  </div>
                </div>

                <div class="d-flex pa-4">
                  <v-icon size="40" color="textFaint">mdi-arrow-down-bold-circle-outline</v-icon>
                  <div class="ml-4">
                    <div class="caption">
                      Your pending reward
                    </div>
                    <div class="mitr">
                      <template v-if="showRawData">
                        {{ pendingAmount }}
                      </template>
                      <template v-else>
                        {{ rewardBalanceHuman }}
                      </template>
                      {{ farm.reward_token.symbol }}
                    </div>
                  </div>
                </div>

                <!--
                <div class="t-small">
                  Decimals: {{ farm.rewardToken.decimals }}
                </div>
                -->
                <div v-if="false" class="caption">
                  Your wallet balance: {{ userRewardTokenWalletBalance }}
                </div>
              </div>

              <!-- NEW -->

              <div v-if="false" class="mt-4 mb-4">
                <v-btn text @click="refresh">
                  refresh
                </v-btn>
                <v-btn text @click="updatePool">
                  update pool
                </v-btn>
              </div>

              <div v-if="false" class="pa-4">

                <div>
                  Reward: {{ pendingAmount }}
                </div>

                <v-card v-if="true" class="pa-4 br-20 mt-3">
                  <div class="caption">
                    Block Reward: {{ farm.block_reward }}
                  </div>
                  <div class="d-flex align-center caption">
                    Vault: 
                    {{ vaultBalance }}
                  </div>
                </v-card>

                <div v-if="true" class="caption mt-5">
                  {{ farm }}
                </div>

              </div>
            </template>

          </div>
        </div>
      </template>
    </expansion-panel>

    <stake-lp-dialog ref="stakeDialog"></stake-lp-dialog>
    <unstake-lp-dialog ref="unstakeDialog"></unstake-lp-dialog>
  </v-card>
  
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakeLpDialog from '@/components/dialogs/uni-lp-staking/stake-lp'
import UnstakeLpDialog from '@/components/dialogs/uni-lp-staking/unstake-lp'
import moment from 'moment'

export default {
  props: {
    farm: {
      type: Object,
      default: () => ({

      })
    }
  },

  components: {
    StakeLpDialog,
    UnstakeLpDialog
  },

  data: () => ({
    mode: 0,
    uniswapPoolInfo: {},
    pendingAmount: '0',
    vaultBalance: '0',
    userRewardTokenWalletBalance: '0',
    userStakedBalance: '0',
    userUnstakedBalance: '0',
    tab: 0,
    showRawData: false,
    blocksPerSecond: 13,
    harvestLoading: false,
    firstLoadInfoRow: true,
    emergencyFarms: ['0x38334a3399E23e330f1017CE2a0D15089c3df71a']
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    isEmergecyFarm () {
      return this.emergencyFarms.includes(this.farm.farm_address)
      // return false
    },
    userFarmLiquidityValue () {
      if (ethers.BigNumber.from(this.userStakedBalance).gt(this.farm.total_lp_farming)) {
        return '?'
      }
      // avoid division by zero failure locking up the drop down
      if (Number(this.farm.farming_lp_value) === 0) {
        return '0'
      }
      var value = ethers.BigNumber.from(this.userStakedBalance).mul(parseInt(this.farm.farming_lp_value)).div(this.farm.total_lp_farming)
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    userUnstakedLiquidityValue () {
      if (ethers.BigNumber.from(this.userUnstakedBalance).gt(this.farm.total_lp_farming)) {
        return '?'
      }
      // avoid division by zero failure locking up the drop down
      if (Number(this.farm.farming_lp_value) === 0) {
        return '0'
      }
      var value = ethers.BigNumber.from(this.userUnstakedBalance).mul(parseInt(this.farm.farming_lp_value)).div(this.farm.total_lp_farming)
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    usersPercentOfPool () {
      if (ethers.BigNumber.from(this.userStakedBalance).gt(this.farm.total_lp_farming)) {
        return '?'
      }
      var value = ethers.BigNumber.from(this.userStakedBalance).mul(1000).div(this.farm.total_lp_farming).toString()
      value = value / 10
      value = Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      if (value < 0.01) {
        return '<0.01'
      }
      return value
    },
    apy () {
      var amount = this.farm.apy
      var decimals = amount > 1 ? 0 : 4
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: decimals})
    },
    farmingLpValueHuman () {
      var amount = this.farm.farming_lp_value
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    farmingLpValueCondensed () {
      var amount = parseInt(this.farm.farming_lp_value)
      // amount = '1230000' // for testing
      if (amount >= 1000000) {
        var deci = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return deci + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return amount
      // return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    totalLpInFarmHuman () {
      var amount = ethers.utils.formatUnits(this.farm.total_lp_farming, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    blockRewardHuman () {
      var amount = ethers.utils.formatUnits(this.farm.block_reward, this.farm.reward_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userStakedBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userStakedBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 9})
    },
    userUnstakedBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userUnstakedBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 9})
    },
    userRewardTokenWalletBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.userRewardTokenWalletBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    rewardBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.pendingAmount, this.farm.reward_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 6})
    },
    rewardValueHuman () {
      var amount = ethers.utils.formatUnits(this.pendingAmount, this.farm.reward_token.decimals)
      amount *= this.farm.reward_token.price_usd
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    vaultBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.vaultBalance, this.farm.reward_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    // start block
    distanceToStartBlock () {
      return this.farm.start_block - this.blockNumber
    },
    dateStartBlock () {
      var seconds = this.distanceToStartBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    startBlockUpcoming () {
      return this.farm.start_block > this.blockNumber
    },
    startBlockUpcomingInBlocks () {
      return this.farm.start_block - this.blockNumber
    },
    // end block
    distanceToEndBlock () {
      return this.farm.end_block - this.blockNumber
    },
    dateEndBlock () {
      var seconds = this.distanceToEndBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    endBlockUpcoming () {
      return this.farm.end_block > this.blockNumber
    },
    // bonus
    distanceToBonusEndBlock () {
      return this.farm.bonusEndBlock - this.blockNumber
    },
    dateBonusEndBlock () {
      var seconds = this.distanceToBonusEndBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateBonusEndHuman () {
      return this.dateBonusEndBlock.format('ddd D MMM HH:mm')
    },
    timeToBonusEndBlock () {
      return this.dateBonusEndBlock.fromNow()
    },
    bonusPeriodActive () {
      if (this.farm.bonus === '1') {
        return false
      }
      return (this.blockNumber >= this.farm.start_block) && (this.blockNumber < this.farm.bonusEndBlock)
    },
    farmingHasEnded () {
      if (this.farm.end_block === '0') {
        return false
      }
      return ethers.BigNumber.from(this.blockNumber).gt(this.farm.end_block)
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // lag just past a new block so balances are accurate
      setTimeout(() => {
        this.refresh()
      }, 1200)
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
    async updateFarmInfo () {
      var farm = await FarmContract.getFarmById(this.farm.farm_index)
      // farm.reward_token = await ERC20.getERC20(farm.reward_token)
      // var lpToken = await FarmContract.getV2PairInfo(farm.lpToken) // might not be an lpToken
      this.farm.num_farmers = farm.num_farmers
      this.farm.bonusEndBlock = farm.bonusEndBlock
    },
    async getVaultBalance () {
      var vaultBalance = await ERC20.getBalance(this.farm.farm_address, this.farm.reward_token.address)
      this.vaultBalance = vaultBalance
    },
    async getPendingReward () {
      if (!this.sEthers.coinbase) {
        return
      }
      var pendingAmount = await FarmContract.pendingFarmRewards(this.farm.farm_address, this.sEthers.coinbase)
      this.pendingAmount = pendingAmount
    },
    async getUserRewardTokenWalletBalance () {
      if (!this.sEthers.coinbase) {
        this.userRewardTokenWalletBalance = '0'
        return
      }
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.farm.reward_token.address)
      this.userRewardTokenWalletBalance = balance
    },
    async getUserStakedLPBalance () {
      if (!this.sEthers.coinbase) {
        this.userStakedBalance = '0'
        return
      }
      var stakedLPBalance = await FarmContract.userInfo(this.farm.farm_address, this.sEthers.coinbase)
      this.userStakedBalance = stakedLPBalance.amountWithdrawable
    },
    async getUserUnstakedLPBalance () {
      if (!this.sEthers.coinbase) {
        this.userStakedBalance = '0'
        return
      }
      var unstakedLPBalance = await ERC20.getBalance(this.sEthers.coinbase, this.farm.lp_token)
      this.userUnstakedBalance = unstakedLPBalance
    },
    updatePool () {
      TxWrapper.doTransaction(FarmContract.updatePool(this.farm.farm_address), this.$root.$dialog.confirmTx)
        .then(() => {
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
        })
    },
    harvest () {
      // this.harvestLoading = true
      TxWrapper.doTransaction(FarmContract.depositUniswapPool(this.farm.farm_address, '0'), this.$root.$dialog.confirmTx)
        .then(() => {
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.harvestLoading = false
        })
    },
    async refresh () {
      await this.updateFarmInfo()
      this.getVaultBalance()
      this.getPendingReward()
      this.getUserRewardTokenWalletBalance()
      await this.getUserStakedLPBalance()
      this.getUserUnstakedLPBalance()
      this.firstLoadInfoRow = false
    },
    stake () {
      this.$refs.stakeDialog.open(this.farm.lp_token, this.farm.farm_address, this.farm.farm_index)
        .then()
        .catch(e => {})
    },
    withdraw () {
      this.$refs.unstakeDialog.open(this.farm.lp_token, this.farm.farm_address, this.farm.farm_index)
        .then()
        .catch(e => {})
    },
    emergencyWithdraw () {
      TxWrapper.doTransaction(FarmContract.emergencyWithdraw(this.farm.farm_address), this.$root.$dialog.confirmTx)
        .then(() => {
          this.resolve()
          this.dialog = false
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
        })
    },
    
  },

  created () {
    this.refresh()
  }
}
</script>