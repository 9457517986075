<template>
  <v-container>

    <!--
    <div class="text-center mt-8">
      <img 
      src="@/assets/img/eth.png" 
      height="100px"
      width="100px">
    </div>

    <div class="text-center">
      Multi-token stable Yield Farming.
    </div>
    -->
    <v-card :class="['br-20', {'pa-2': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">
      <div class="mt-3 d-flex align-center white pa-3 r-outlined ml-2 br-20 mr-2 background">
        <c-input :value.sync="searchPairs" placeholder="Search Uniswap pairs..." class="heading--text">
        </c-input>
        <v-icon>
          mdi-magnify
        </v-icon>
      </div>

      <div class="pl-4 d-flex align-center mt-6 headline font-weight-medium">
        Farming pairs
        <v-spacer></v-spacer>
        <v-btn @click="addFarm" text color="primary" class="font-weight-bold">
          Add a farm
        </v-btn>
        <!--
        <v-btn @click="createAPair" text color="primary">
          Add a pair
        </v-btn>
        -->
      </div>

      <div v-if="loading" class="text-center pa-8 text--text">
        Loading...
      </div>
      <v-scroll-x-transition>
        <div v-if="!loading">

          <template v-if="filteredPairs.length > 0">
            <div class="ml-8 mt-4 mr-8 caption text--text font-italic d-flex">
              <div>
                Uniswap Pair
              </div>
              <v-spacer></v-spacer>
              <div>
                Info
              </div>
            </div>

            <pair-row v-for="pool of filteredPairs" :key="pool.address" :pool="pool">
            </pair-row>

          </template>

          <div v-else class="pa-3 text-center">
            No pairs match your search
          </div>
          
          <testing-card v-if="true" style="margin-top: 50px!important;"></testing-card>

        </div>
      </v-scroll-x-transition>
    </v-card>
    <!-- DIALOGS -->
    <create-a-pair-dialog ref="createPairDialog"></create-a-pair-dialog>
    <add-farm-dialog ref="addFarmDialog"></add-farm-dialog>
    <!-- DIALOGS -->
    
  </v-container>
</template>

<script>
import TestingCard from '@/views/testing/main-card'
import FarmContract from '@/smart-contracts/farm'
import CreateAPairDialog from '@/views/add-pair/dialog'
import AddFarmDialog from '@/views/add-farm/dialog'
import PairRow from './row'

export default {
  components: {
    TestingCard,
    CreateAPairDialog,
    AddFarmDialog,
    PairRow
  },

  data: () => ({
    searchPairs: '',
    pools: [],
    loading: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    pairs () {
      return this.$store.state.pairs
    },
    filteredPairs () {
      return this.pools.filter(pair => {
        var nameSymbol = pair.token0.symbol + ' ' + pair.token1.symbol
        return nameSymbol.toLowerCase().includes(this.searchPairs.toLowerCase())
      })
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    pairs () {
      this.refresh()
    }
  },

  methods: {
    async getPools () {
      var pools = JSON.parse(JSON.stringify(Object.values(this.$store.state.pairs)))
      console.log(pools)
      for (var pool of pools) {
        var stakingInfo = {
          stakedBalance: '0'
        }
        if (this.sEthers.coinbase) {
          var userInfo = await FarmContract.userInfo(pool.address, this.sEthers.coinbase)
          stakingInfo.stakedBalance = userInfo.amountWithdrawable
        }
        pool.stakingInfo = stakingInfo
      }
      this.pools = pools
      this.pools.sort((a,b) => {
        return b.stakingInfo.stakedBalance - a.stakingInfo.stakedBalance
      })
    },
    refresh () {
      this.loading = true
      this.getPools()
        .then(() => {
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    createAPair () {
      this.$refs.createPairDialog.open()
    },
    addFarm () {
      this.$root.$dialog.connectWalletDialog.requireConnectionThen(this.$refs.addFarmDialog.open)
      // this.$refs.addFarmDialog.open()
    }
  },
  created () {
    this.refresh()
  }
}
</script>