<template>
  <div outlined class="align-center border-b">
    <expansion-panel>
      <template v-slot:header="{ expand, toggle }">
        <div @click.stop="toggle" class="d-flex align-center pa-4 ">

          <coin-icon :address="token.address" :size="32"></coin-icon>

          <div class="ml-2">
            <div class="d-flex">
              <span class="font-weight-bold">{{ token.symbol }}</span>
              <div class="textFaint--text ml-2">${{ priceHuman }}</div>
            </div>
            <div class="t-small textFaint--text">
              {{ token.name }}
            </div>
          </div>

          <div v-if="false" class="ml-2 caption font-italic textFaint--text">
            (unverified)
          </div>

          <v-spacer></v-spacer>

          <div v-if="sEthers.coinbase" class="text-end">
            <div class="font-weight-bold">
              ${{ balanceValueHuman }}
            </div>
            <div class="t-small textFaint--text">
              {{ balanceHuman }}
            </div>
          </div>

          <v-icon color="" :class="['ml-1', {'rotate-180': expand}]">mdi-chevron-down</v-icon>

        </div>
      </template>

      <template v-slot:body>
        <div class="border-t pa-4 background">
          <v-btn text small @click="$router.push(`/farms?token=${token.address}`)">
            View farms
          </v-btn>
          <v-btn text small :href="`https://v2.info.uniswap.org/token/${token.address}`" target="_blank">
            Uniswap
            <v-icon x-small>mdi-arrow-top-right</v-icon>
          </v-btn>
          <v-btn text small :href="`https://etherscan.io/address/${token.address}`" target="_blank">
            Etherscan
            <v-icon x-small>mdi-arrow-top-right</v-icon>
          </v-btn>
          <div class="d-flex justify-center">
            <copy-address :address="token.address" color=""></copy-address>
          </div>
        </div>
      </template>
    </expansion-panel>
    
  </div>
</template>

<script>
import ERC20 from '@/smart-contracts/erc20'
import { ethers } from 'ethers'

export default {
  props: {
    token: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data: () => ({
    balance: '0'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    balanceHuman () {
      var amount = ethers.utils.formatUnits(this.balance, this.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    balanceValueHuman () {
      var amount = ethers.utils.formatUnits(this.balance, this.token.decimals)
      amount *= this.token.price_usd
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    priceHuman () {
      var value = this.token.price_usd
      var decimals = value < 0.01 ? 8 : 2
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: decimals})
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    async refresh () {
      if (!this.sEthers.coinbase) {
        this.balance = '0'
        return
      }
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.token.address)
      this.balance = balance
    }
  },

  created () {
    this.refresh()
  }
}
</script>