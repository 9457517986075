<template>
  <v-app :class="$store.state.theme === 'theme2' ? 'darktheme' : ''">

    <v-app-bar
      v-if="!$vuetify.breakpoint.xs"
      app
      flat
      height="80"
      absolute
      color="foreground"
    >

      <img 
      src="@/assets/img/UNCL_svg.svg" 
      height="30px"
      width="30px"
      class="mr-3">
      <v-toolbar-title class="font-weight-bold">
        <span class="orbitron">FARM</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="ml-2 mr-2">
        <tx-spinner color="text"></tx-spinner>
      </div>

      <v-btn small icon color="text" class="mr-1" @click="changeTheme">
        <v-icon small>mdi-white-balance-sunny</v-icon>
      </v-btn>

      <div class="text-center">
        <v-btn
        @click="connectWallet"
        :class="['mr-2 font-weight-bold', {'blue-button white--text': !walletConnected}]"
        rounded depressed
        >
            <span v-if="!walletConnected">Connect Wallet</span>
            <span v-else>{{ sEthers.coinbase_condensed }}</span>
        </v-btn>
      </div>

      <v-btn text @click="openMenu">
        <span>Menu</span>
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>

    </v-app-bar>
    <v-app-bar
      v-else-if="$vuetify.breakpoint.mobile && acceptWarning"
      app
      flat
      color="midground"
    >

      <img 
      src="@/assets/img/UNCL_svg.svg"  
      height="30px"
      width="30px"
      @click="showBackground = !showBackground"
      class="mr-3">
      <v-toolbar-title class="font-weight-bold">
        <span class="orbitron">FARM</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-btn
        @click="connectWallet"
        small
        :class="['mr-1 font-weight-bold', {'blue-button white--text': !walletConnected}]"
        rounded depressed
        >
            <span v-if="!walletConnected">Connect Wallet</span>
            <span v-else>{{ sEthers.coinbase_condensed }}</span>
        </v-btn>
      </div>

      <div class="mr-1">
        <tx-spinner color="text"></tx-spinner>
      </div>

      <v-btn small icon color="text" class="mr-1" @click="changeTheme">
        <v-icon small>mdi-white-balance-sunny</v-icon>
      </v-btn>

      <!--
      <v-menu v-if="$vuetify.breakpoint.mobile" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
          icon
          v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list style="min-width: 200px;">
          <v-list-item to="/">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      -->

    </v-app-bar>

    <v-main :class="['background radial-bb', {'ima': showBackground && !$vuetify.breakpoint.xs}]">
      <div v-if="!acceptWarning" class="d-flex mx-auto justify-center align-center pa-8 text--text" style="height: 100%; max-width: 500px;">
        <v-card class="pa-6 br-20" style="overflow: unset;">
          <div class="text-center">
            <img 
            src="@/assets/img/UNCL_svg.svg" 
            height="80px"
            width="80px"
            style="margin-top: -80px;">
          </div>
          <div class="text-center">
            <div v-if="firstSync">
              Loading...
            </div>
            <div v-else class="mitr">
              Ready to farm!
            </div>
          </div>
          <div>
            <!-- RELEASE -->
            <template>
              <div class="text-center title mitr mt-8 mb-2">
                Yield farming
              </div>
              <div class="mt-4 text-center">
                Unicrypt yield-farming provides one platform to manage and track all farms.
              </div>
              <div class="mt-4 text-center">
                You earn 0.3% fees on any liquidity provided to uniswap <span class="font-weight-bold">plus</span> the yields stated in this app.
              </div>
              <div class="text-center title mitr mt-8 mb-2">
                Caution
              </div>
              <div class="caption">
                Our software can be used by anyone, like uniswap or the UniCrypt lockers.
                Other tokens and developers can launch farming contracts from within this dApp.
                UniCrypt takes no responsibility for other projects nor are we recommending any of the projects providing
                yield farming on this platform, please remember to DYOR.
                Neither uniswap nor ourselves have control
                over which tokens use our platforms.
                By using this dApp you agree to take full responsibility for your own losses.
              </div>
            </template>
            <!-- RELEASE -->
        </div>
  
          <v-btn
          @click="acceptWarning = true"
          class="br-12 title mt-8 white--text blue-button" x-large block depressed :disabled="firstSync">
            I Accept
          </v-btn>
        </v-card>
      </div>
      <template v-else>
        <!--
        <div class="caption text--text text-center">
          block {{ blockNumber }}
        </div>
        -->
        
        <div v-if="!$vuetify.breakpoint.xs" class="border-b midground">
          <div class="mx-auto" style="max-width: 600px;">
            <v-bottom-navigation
            color="text"
            grow
            class="align-center transparent"
            style="box-shadow: none;"
            >

              <v-btn to="/tokens">
                <span>Tokens</span>
                <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
              </v-btn>

              <v-btn v-if="showSuperUserButton" to="/pairs">
                <span>Pairs</span>
                <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
              </v-btn>

              <v-btn to="/farms">
                <span>Farms</span>
                <v-icon>mdi-sprout-outline</v-icon>
              </v-btn>

              <v-btn to="/account">
                <span>Account</span>
                <v-icon>mdi-account-outline</v-icon>
              </v-btn>

              <v-btn v-if="showSuperUserButton" to="/asdaefsfsdbvfhdfgdfgdgasrtert">
                <span>Cron</span>
                <v-icon>mdi-atom</v-icon>
              </v-btn>

            </v-bottom-navigation>
          </div>
        </div>

        <div style="max-width: 600px;" class="mx-auto">
          <router-view></router-view>
        </div>

      </template>
    </v-main>

    <v-footer v-if="$vuetify.breakpoint.xs && acceptWarning" app fixed class="justify-center pa-0 white">

      <v-bottom-navigation
        color="text"
        grow
        class="align-center midground"
        style=""
      >
        <v-btn to="/tokens" @click="clickTitle">
          <span>Tokens</span>
          <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn v-if="showSuperUserButton" to="/pairs">
          <span>Pairs</span>
          <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn to="/farms">
          <span>Farms</span>
          <v-icon>mdi-sprout-outline</v-icon>
        </v-btn>

        <v-btn to="/account">
          <span>Account</span>
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>

        <v-btn @click="openMenu">
          <span>Menu</span>
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-btn v-if="showSuperUserButton" to="/asdaefsfsdbvfhdfgdfgdgasrtert">
          <span>Cron</span>
          <v-icon>mdi-atom</v-icon>
        </v-btn>

      </v-bottom-navigation>

    </v-footer>

    <main-menu ref="mainMenu"></main-menu>

    <!-- DIALOGS -->
    <web-3-error ref="web3Error"></web-3-error>
    <connect-wallet-dialog ref="connectWalletDialog"></connect-wallet-dialog>
    <confirm-tx-dialog ref="confirmTxInWallet"></confirm-tx-dialog>
    <!-- DIALOGS -->

  </v-app>
</template>

<script>
import Web3Error from '@/views/DialogWeb3Error'
import ConnectWalletDialog from '@/web3/connect-dialog'
import ConnectProvider from '@/web3/connect-provider'
import ConfirmTxDialog from '@/web3/confirm-tx-in-wallet'
import TxSpinner from '@/web3/transaction-spinner'
import moment from 'moment'
import MainMenu from '@/components/ui/main-menu'

export default {
  name: 'App',

  components: {
    Web3Error,
    ConnectWalletDialog,
    TxSpinner,
    ConfirmTxDialog,
    MainMenu
  },

  data: () => ({
    firstSync: true,
    acceptWarning: false,
    showBackground: false,
    titleClicks: [],
    showSuperUserButton: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    walletConnected () {
      return this.sEthers.coinbase
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  methods: {
    changeTheme () {
      var themeName = "theme1"
      // localStorage.getItem('theme') === "theme1")
      if (this.$store.state.theme === "theme1") {
        themeName = "theme2"
      }
      this.$store.commit('setTheme',{
        themeName: themeName,
        vuetify: this.$vuetify
      })
    },
    openMenu () {
      this.$refs.mainMenu.open()
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
    registerBlockListener () {
      this.sEthers.provider.on('block', (blockNumber) => {
        this.$store.commit('setBlockNumber', blockNumber)
        this.syncState(blockNumber)
      })
    },
    async syncState (blockNumber) {
      this.$store.commit('setLastBlockSync', blockNumber)
      this.firstSync = false
    },
    clickTitle () {
      if (this.titleClicks.length === 15) {
        this.titleClicks.shift()
      }
      this.titleClicks.push(moment().unix())
      this.toggleSuperUser()
    },
    toggleSuperUser () {
      if (this.titleClicks.length !== 15) {
        return
      }
      var moment1 = this.titleClicks[0]
      if (moment1 > moment().subtract(3, 'seconds').unix()) {
        this.titleClicks = []
        this.showSuperUserButton = !this.showSuperUserButton
      }
    },
  },

  created () {
    ConnectProvider.connectViewOnlyProvider()
    this.registerBlockListener()
  },
  mounted () {
    this.$root.$dialog = {}
    this.$root.$dialog.web3Error = this.$refs.web3Error
    this.$root.$dialog.confirmTx = this.$refs.confirmTxInWallet
    this.$root.$dialog.connectWalletDialog = this.$refs.connectWalletDialog
  }
}
</script>

<style scoped lang="scss">
.ima {
  background-image: url(https://picsum.photos/1000?image=521);
  background-image: url(https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2016/07/28/14/40/space-1548139_1280.jpg);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.93)), url(https://cdn.pixabay.com/photo/2016/07/28/14/40/space-1548139_1280.jpg);
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.93)), url(https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2016/04/15/04/02/water-1330252_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2020/06/28/00/35/sky-5347490_1280.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.radial-b {
  background-color: #fafafa!important;
  // background: radial-gradient(50% 50% at 50% 50%, rgba(132, 84, 228, 0.05) 0%, rgba(255, 255, 255, 0.01) 100%);
}
</style>
