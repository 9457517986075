<template>
  <v-card>
    <div v-if="mode === 0">
      <div v-if="showPairName" class="pa-4 border-b d-flex align-center font-weight-bold">

        <v-btn :to="`/pairs/${uniswapPoolInfo.address}`" text color="primary" class="pa-0">
          {{ uniswapPoolInfo.token0.symbol }} / {{ uniswapPoolInfo.token1.symbol }} Pool
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn :to="`/farms/edit/${farmId}`" text color="primary" class="pa-0">
          View farm {{ farmId }}
        </v-btn>

      </div>
      <v-row dense class="align-center pa-4 ma-0">
        <v-col cols="7" class="d-flex align-center">

          <div class="">
            <!--
            <div class="t-small" style="visibility: hidden;">
              invis
            </div>
            -->
            <div class="d-flex align-center">
              <img 
              src="@/assets/img/UNCX_outline.svg"
              height="36px"
              width="36px"
              class="mr-2">
              <div>
                <v-btn color="primary" text class="font-weight-bold pa-0" style="min-width: unset; height: unset;" @click="$router.push(`/tokens/${farm.rewardToken.address}`)">
                  {{ farm.rewardToken.symbol }}
                </v-btn>

                <!-- INFO -->
                <div class="textFaint--text t-small" v-if="true">
                  <div v-if="farmingHasEnded" class="">
                    Farming ended
                  </div>

                  <div v-else-if="startBlockUpcoming" class="">
                    <div class="">
                      starts in {{ startBlockUpcomingInBlocks }} block{{ startBlockUpcomingInBlocks === 1 ? '' : 's' }}
                    </div>

                  </div>

                  <div v-else-if="bonusPeriodUpcoming" class="">
                    <div class="">
                      <span class="font-weight-bold">{{ farm.bonus }}X</span>
                      bonus in {{ bonusPeriodUpcomingInBlocks }} block{{ bonusPeriodUpcomingInBlocks === 1 ? '' : 's' }}
                    </div>

                  </div>

                  <template v-else>

                    <div v-if="bonusPeriodActive" class="">
                      <div class="">
                        <span class="font-weight-bold">{{ farm.bonus }}X</span> bonus
                      </div>
                    </div>

                    <div v-else>
                      APY
                    </div>
                  </template>
                </div>

              </div>
            </div>
            
          </div>
        </v-col>

        <v-col cols="5" class="text-end">
          <!-- <div v-if="sEthers.coinbase && pendingAmount !== '0'" class="">{{ rewardBalanceHuman }} earned</div> -->
          <div :class="['font-weight-bold', {'textFaint--text': pendingAmount === '0'}]">
            {{ rewardBalanceHuman }}
          </div>

          <div v-if="farmingHasEnded" class="textFaint--text t-small">
            <span class="">0</span>%
          </div>
          <div v-else class="textFaint--text t-small font-weight-bold">
            <span class="">{{ currentAPY }}</span>%
          </div>
        </v-col>

        <!--
        <div v-else class=''>
          {{ currentAPY }}%
        </div>

        <div v-if="bonusPeriodActive" class="text-center">
          <div class="text--text font-italic">
            <span class="">({{ farm.bonus }}X bonus)</span>
          </div>
        </div>
        -->

        <!--
        <div v-else-if="startBlockUpcoming" class="text-center">
          <div class="caption">
            {{ farm.apy / 10 }}% farming starts in {{ startBlockUpcomingInBlocks }} block{{ startBlockUpcomingInBlocks === 1 ? '' : 's' }}
          </div>
          
          <v-progress-linear
          color="text"
          height="3"
          buffer-value="0"
          :value="progressTillStart"
          striped
          rounded
          ></v-progress-linear>

        </div>

        <div v-else-if="bonusPeriodUpcoming" class="text-center">
          <div class="caption text--text font-italic">
            <span class="font-weight-bold">{{ farm.bonus }}X</span>
            bonus starts in {{ bonusPeriodUpcomingInBlocks }} block{{ bonusPeriodUpcomingInBlocks === 1 ? '' : 's' }}
          </div>
          <v-progress-linear
          class=""
          color="green"
          height="3"
          buffer-value="0"
          :value="progressTillBonus"
          striped
          rounded
          ></v-progress-linear>

        </div>

        <template v-else>

          <div v-if="bonusPeriodActive" class="text-center">
            <div class="text--text font-italic">
              <span class="">{{ farm.bonus }}X ({{ currentAPY }}%)</span>
            </div>
          </div>

          <div v-else class=''>
            {{ farm.apy / 10 }}% APY
          </div>
        </template>
        -->

        <!--
        <v-btn small icon @click="mode = 1">
          <v-icon small>mdi-arrow-right</v-icon>
        </v-btn>
        -->

        <!--
        <v-btn v-if="pendingAmount != '0'" @click="harvest" small rounded depressed class="">
          Claim
        </v-btn>
        -->

      </v-row>

    </div>

    <!-- SIMPLE -->
    <v-row v-else-if="mode === 1" class="align-center">
      <v-btn small icon @click="mode = 0">
        <v-icon small>mdi-arrow-left</v-icon>
      </v-btn>

      <div v-if="startBlockUpcoming" class="text-center pa-2">
        <div class="caption text--text font-italic">
          Farming starts in {{ startBlockUpcomingInBlocks }} block{{ startBlockUpcomingInBlocks === 1 ? '' : 's' }}
        </div>
        
        <v-progress-linear
        class="mt-1 mb-1"
        color="text"
        height="6"
        buffer-value="0"
        :value="progressTillStart"
        striped
        rounded
        ></v-progress-linear>

      </div>

      <div v-else-if="bonusPeriodUpcoming" class="text-center pa-2">
        <div class="caption text--text font-italic">
          <span class="font-weight-bold">{{ farm.bonus }}X Bonus</span>
          farming starts in {{ bonusPeriodUpcomingInBlocks }} block{{ bonusPeriodUpcomingInBlocks === 1 ? '' : 's' }}
        </div>
        <v-progress-linear
        class="mt-1 mb-1"
        color="green"
        height="6"
        buffer-value="0"
        :value="progressTillBonus"
        striped
        rounded
        ></v-progress-linear>

      </div>
      <!--
      <v-col cols="6" class="d-flex align-center">
        <v-btn :to="`/farms/edit/${farmId}`" small text color="text">
          <v-icon small>mdi-tractor</v-icon>
          <span>{{ farmId }}</span>
        </v-btn>

        <v-btn small icon @click="mode = 0">
          <v-icon small>mdi-arrow-left</v-icon>
        </v-btn>

        <div v-if="farmingHasEnded" class="text--text caption font-italic">
          Farming has ended {{farm.endBlock}}
        </div>
        <div v-else class='caption text--text'>
          {{ farm.apy / 10 }}% APY
        </div>
      </v-col>
      <v-col cols="6" class="d-flex align-center">

        <v-spacer></v-spacer>

        <div class="font-weight-medium text-center mr-2 caption">
          {{ rewardBalanceHuman }} {{ farm.rewardToken.symbol }}
        </div>

        <img 
        src="@/assets/img/UNCX_fill.svg"
        height="20px"
        width="20px"
        @click="refresh"
        class="mr-3">

      </v-col>
      -->
    </v-row>

  </v-card>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
// import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  props: {
    farmId: {
      type: String,
      default: '0'
    },
    showPairName: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mode: 0,
    farm: {
      rewardToken: {},
      endBlock: '0',
      apy: '0'
    },
    uniswapPoolInfo: {},
    pendingAmount: '0'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    currentAPY () {
      if (this.bonusPeriodActive) {
        return this.farm.apy / 10 * this.farm.bonus
      }
      return this.farm.apy / 10
    },
    rewardBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.pendingAmount, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    startBlockUpcoming () {
      return this.farm.startBlock > this.blockNumber
    },
    startBlockUpcomingInBlocks () {
      return this.farm.startBlock - this.blockNumber
    },
    progressTillStart () {
      // using 1 day as max progress
      if (this.startBlockUpcomingInBlocks < 100) {
        return (this.startBlockUpcomingInBlocks / (100)) * 100
      }
      return (this.startBlockUpcomingInBlocks / (4 * 60 * 24)) * 100
    },
    bonusPeriodUpcoming () {
      return this.farm.bonusStart > this.blockNumber
    },
    bonusPeriodActive () {
      return (this.blockNumber >= this.farm.bonusStart) && (this.blockNumber < this.farm.bonusEnd)
    },
    bonusPeriodUpcomingInBlocks () {
      return this.farm.bonusStart - this.blockNumber
    },
    bonusPeriodEndingInBlocks () {
      return this.farm.bonusEnd - this.blockNumber
    },
    progressTillBonus () {
      // using 1 day as max progress
      if (this.bonusPeriodUpcomingInBlocks < 100) {
        return (this.bonusPeriodUpcomingInBlocks / (100)) * 100
      }
      return (this.bonusPeriodUpcomingInBlocks / (4 * 60 * 24)) * 100
    },
    farmingHasEnded () {
      if (this.farm.endBlock === '0') {
        return false
      }
      return ethers.BigNumber.from(this.blockNumber).gt(this.farm.endBlock)
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // lag just past a new block so balances are accurate
      setTimeout(() => {
        this.refresh()
      }, 1200)
    }
  },

  methods: {
    async getUniswapPoolInfo () {
      var farm = JSON.parse(JSON.stringify(this.$store.state.farms[this.farmId]))
      var rewardToken = JSON.parse(JSON.stringify(this.$store.state.rewardTokens[farm.rewardToken]))
      var uniswapPoolInfo = JSON.parse(JSON.stringify(this.$store.state.pairs[farm.lpToken]))
      farm.rewardToken = rewardToken
      this.uniswapPoolInfo = uniswapPoolInfo
      this.farm = farm
    },
    async getPendingReward () {
      if (!this.sEthers.coinbase) {
        return
      }
      var pendingAmount = await FarmContract.pendingFarmRewards(this.farmId, this.sEthers.coinbase)
      this.pendingAmount = pendingAmount
    },
    harvest () {
      TxWrapper.doTransaction(FarmContract.harvestFarmRewards(this.farmId))
        .then(() => { })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    },
    async refresh () {
      await this.getUniswapPoolInfo()
      this.getPendingReward()
    },
  },

  created () {
    this.refresh()
  }
}
</script>