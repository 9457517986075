<template>

  <v-card>

    <v-row class="pa-4 ma-0 font-weight-medium align-center">
  
      <v-btn icon @click="$emit('goBack')">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <div class="headline">
        Add a farm
      </div>
      <v-spacer></v-spacer>
      
      <v-btn large @click="$emit('onClose')" icon color="heading">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>

    <!-- Main content -->
    <v-card-text class="pa-0">

      <div class="pa-6">

        <div v-if="stage === 5" class="mt-4 heading--text text-center">
              
          <div class="display-1 mb-6 mt-6">
            Farm created!
          </div>
          <div class="d-flex">
            If you cannot see it in the app yet, please refresh the app.
          </div>
        </div>

        <template v-else>

          <div class="d-flex align-center justify-center textFaint--text mb-6 mt-6" style="max-width: 500px;">
            <v-btn text :class="{'primary--text': stage === 1}" @click="stage = 1">
              Step 1
            </v-btn>
            <v-btn text :class="{'primary--text': stage === 2}" @click="stage = 2">
              2
            </v-btn>
            <v-btn text :class="{'primary--text': stage === 3}" @click="stage = 3">
              3
            </v-btn>
            <v-btn text :class="{'primary--text': stage === 4}" @click="stage = 4">
              4
            </v-btn>
          </div>

          <div class="pr-3 mb-6 d-flex align-center">
            <!-- Farm <v-icon small class="mr-2 ml-2">mdi-arrow-right</v-icon> -->
            <span class="font-weight-bold">{{ poolInfo.univ2pair.token0.symbol }} / {{ poolInfo.univ2pair.token1.symbol }}</span>
            <v-icon small class="mr-2 ml-2">mdi-arrow-right</v-icon>
            <span class="font-weight-bold">{{ rewardToken.symbol }}</span>
          </div>

          <template v-if="stage === 1">

            <div>
              Peg token
            </div>

            <v-row dense>
              <v-col cols="6">
                <v-btn x-large depressed block :color="token0IsPeg ? 'primary' : ''" @click="token0IsPeg = true">
                  {{ poolInfo.univ2pair.token0.symbol }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn x-large depressed block :color="!token0IsPeg ? 'primary' : ''" @click="token0IsPeg = false">
                  {{ poolInfo.univ2pair.token1.symbol }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="pa-3 r-outlined">
              <div class="mb-2">
                Annual interest
              </div>
              <div class="d-flex align-baseline">
                <c-input :value.sync="apy" placeholder="0" class="display-3 font-weight-bold">
                </c-input>
                <div class="display-2">
                  %
                </div>
              </div>
            </div>

            <div class="mt-6">
              Yield per staked <span class="font-weight-bold">{{ pegToken.symbol }}</span>
            </div>

            <div class="mt-2 pr-3 d-flex">
              Annual yield 
              <v-spacer></v-spacer>
              <div class="font-weight-bold">
                {{ apy / 100 }} {{ rewardToken.symbol }}
              </div>
            </div>

            <div class="mt-2 pr-3 d-flex">
              Monthly yield 
              <v-spacer></v-spacer>
              <div class="font-weight-bold">
                {{ monthlyYield }} {{ rewardToken.symbol }}
              </div>
            </div>

            <div class="mt-2 pr-3 d-flex">
              Daily yield 
              <v-spacer></v-spacer>
              <div class="font-weight-bold">
                {{ dailyYield }} {{ rewardToken.symbol }}
              </div>
            </div>

          </template>

          <template v-if="stage === 2">

            <div class="">
              Define farming start and end blocks
            </div>

            <div class="mt-4 mb-4 pr-3">
              Current block: 
              <span class="font-weight-bold">
                {{ blockNumber }}
              </span>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                Start block: ( {{ dateStartBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="startBlock" placeholder="0" class="display-1">
                </c-input>
                <c-date @setDate="setStartDate">
                  <template v-slot:header>
                    {{ timeToStartBlock }}
                  </template>
                </c-date>
              </div>
            </div>

            <div class="d-flex align-center pt-8 pb-2">
              <div class="mr-2">
                Infinite farming
              </div>
              <v-checkbox
              class="ma-0 pa-0"
              v-model="infiniteFarming"
              color="primary"
              hide-details
              ></v-checkbox>
            </div>

            <div v-if="infiniteFarming" class="caption">
              Infinite farming means no ending block. The farm will keep minting yield rewards indefinitely until you disable it. This is the standard.
            </div>

            <div v-else class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                End block: ( {{ dateEndBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="endBlock" placeholder="0" class="display-1">
                </c-input>

                <c-date @setDate="setEndDate">
                  <template v-slot:header>
                    {{ timeToEndBlock }}
                  </template>
                </c-date>

              </div>
            </div>

            <div v-if="!infiniteMode" class="d-flex align-center pa-2 mt-5">
              Duration
              <v-spacer></v-spacer>
              <div class="">
                {{ durationInBlocks }} blocks
              </div>
            </div>

          </template>

          <template v-if="stage === 3">

            <div class="d-flex align-center pt-8 pb-2">
              <div class="mr-4">
                Bonus period
              </div>
              <v-checkbox
              class="ma-0 pa-0"
              v-model="bonusPeriod"
              color="primary"
              hide-details
              ></v-checkbox>
            </div>

            <div v-if="!bonusPeriod" class="caption">
              Enable bonus period to increase rewards for a certain period
            </div>

            <template v-else>
              <div class="pt-2">

                <div class="mt-3 pa-3 r-outlined">
                  <div class="">
                    Bonus multiplier
                  </div>
                  <div class="d-flex align-baseline">
                    <c-input :value.sync="bonus" placeholder="10" class="display-3 font-weight-bold">
                    </c-input>
                    <div class="display-2">
                      x
                    </div>
                  </div>
                </div>

                <div class="mt-2 pr-3 d-flex">
                  Bonus APY 
                  <v-spacer></v-spacer>
                  {{ bonus }} x {{ apy }}% = 
                  <div class="font-weight-bold">
                    {{ bonus * apy }}%
                  </div>
                </div>

                <div class="mt-6">
                  Yield per staked <span class="font-weight-bold">{{ pegToken.symbol }}</span>
                </div>

                <div class="mt-2 pr-3 d-flex">
                  Annual yield 
                  <v-spacer></v-spacer>
                  <div class="font-weight-bold">
                    {{ bonus * apy / 100 }} {{ rewardToken.symbol }}
                  </div>
                </div>

                <div class="mt-2 pr-3 d-flex">
                  Monthly yield 
                  <v-spacer></v-spacer>
                  <div class="font-weight-bold">
                    {{ monthlyYieldBonus }} {{ rewardToken.symbol }}
                  </div>
                </div>

                <div class="mt-2 pr-3 d-flex">
                  Daily yield 
                  <v-spacer></v-spacer>
                  <div class="font-weight-bold">
                    {{ dailyYieldBonus }} {{ rewardToken.symbol }}
                  </div>
                </div>

                <div class="mt-4 mb-4 pr-3">
                  Current block: 
                  <span class="font-weight-bold">
                    {{ blockNumber }}
                  </span>
                </div>

                <div class="mt-6 caption">
                  Bonus period must begin after block {{ startBlock }}
                </div>

                <div class="mt-3 pa-3 r-outlined">
                  <div class="caption mb-2">
                    Bonus Start block: ( {{ dateBonusStartBlock.format('D MMM YYYY, HH:mm') }} )
                  </div>
                  <div class="d-flex">
                    <c-input :value.sync="bonusStart" placeholder="0" class="display-1">
                    </c-input>
                    <c-date @setDate="setBonusStartDate">
                      <template v-slot:header>
                        {{ timeToBonusStartBlock }}
                      </template>
                    </c-date>
                  </div>
                </div>

                <div class="mt-3 pa-3 r-outlined">
                  <div class="caption mb-2">
                    Bonus end block: ( {{ dateBonusEndBlock.format('D MMM YYYY, HH:mm') }} )
                  </div>
                  <div class="d-flex">
                    <c-input :value.sync="bonusEnd" placeholder="0" class="display-1">
                    </c-input>
                    <c-date @setDate="setBonusEndDate">
                      <template v-slot:header>
                        {{ timeToBonusEndBlock }}
                      </template>
                    </c-date>
                  </div>
                </div>

                <div class="d-flex align-center mt-5">
                  Duration
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ bonusDurationInBlocks }} blocks
                  </div>
                </div>
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <div class="">
                    {{ bonusDurationAsTime }}
                  </div>
                </div>

              </div>

            </template>

          </template>

          <template v-if="stage === 4">

            <div>
              Final parameters
            </div>
            <div class="caption">
              This has been set up by the screens before. Dont tinker with them if you dont know what your doing :)
            </div>

            <div>
              Peg token
            </div>

            <v-row dense>
              <v-col cols="6">
                <v-btn x-large depressed block :color="token0IsPeg ? 'primary' : ''" @click="token0IsPeg = true">
                  {{ poolInfo.univ2pair.token0.symbol }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn x-large depressed block :color="!token0IsPeg ? 'primary' : ''" @click="token0IsPeg = false">
                  {{ poolInfo.univ2pair.token1.symbol }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                Start block: ( {{ dateStartBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="startBlock" placeholder="0" class="display-1">
                </c-input>
                <c-date @setDate="setStartDate">
                  <template v-slot:header>
                    {{ timeToStartBlock }}
                  </template>
                </c-date>
              </div>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                End block: ( {{ dateEndBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="endBlock" placeholder="0" class="display-1">
                </c-input>
                <c-date @setDate="setEndDate">
                  <template v-slot:header>
                    {{ timeToEndBlock }}
                  </template>
                </c-date>
              </div>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                APY
              </div>
              <div class="d-flex">
                <c-input :value.sync="apy" placeholder="0" class="display-1">
                </c-input>
              </div>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                Bonus multiplier: ( {{ bonus }} x {{ apy }}% = {{ bonus * apy }}% )
              </div>
              <div class="d-flex">
                <c-input :value.sync="bonus" placeholder="0" class="display-1">
                </c-input>
              </div>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                Bonus Start block: ( {{ dateBonusStartBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="bonusStart" placeholder="0" class="display-1">
                </c-input>
                <c-date @setDate="setBonusStartDate">
                  <template v-slot:header>
                    {{ timeToBonusStartBlock }}
                  </template>
                </c-date>
              </div>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption mb-2">
                Bonus end block: ( {{ dateBonusEndBlock.format('D MMM YYYY, HH:mm') }} )
              </div>
              <div class="d-flex">
                <c-input :value.sync="bonusEnd" placeholder="0" class="display-1">
                </c-input>
                <c-date @setDate="setBonusEndDate">
                  <template v-slot:header>
                    {{ timeToBonusEndBlock }}
                  </template>
                </c-date>
              </div>
            </div>

          </template>

        </template>

      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
      v-if="stage === 4"
      @click="addFarm"
      class="br-12 title mt-4" color="primary" x-large block depressed :loading="creatingFarm">
        Create Farm
      </v-btn>
      <v-btn
      v-else-if="stage < 4"
      @click="stage++"
      class="br-12 title mt-4" color="primary" x-large block depressed>
        Continue
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import moment from 'moment'
import CDate from '@/components/dialogs/date-picker'

export default {
  props: {
    rewardToken: {},
    poolInfo: {}
  },

  components: {
    CDate
  },

  data: () => ({
    datePicker: null,
    startBlock: '0',
    endBlock: '0',
    apy: '0',
    bonusStart: '0',
    bonusEnd: '0',
    bonus: '0',
    token0IsPeg: true,
    stage: 1,
    bonusPeriod: false,
    creatingFarm: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    dateStartBlock () {
      var seconds = this.distanceToStartBlock * 15
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateEndBlock () {
      var seconds = this.distanceToEndBlock * 15
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateBonusStartBlock () {
      var seconds = this.distanceToBonusStartBlock * 15
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateBonusEndBlock () {
      var seconds = this.distanceToBonusEndBlock * 15
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    timeToEndBlock () {
      if (this.endBlock === '0') {
        return 'Infinite'
      }
      return this.dateEndBlock.fromNow()
    },
    timeToBonusStartBlock () {
      return this.dateBonusStartBlock.fromNow()
    },
    timeToBonusEndBlock () {
      return this.dateBonusEndBlock.fromNow()
    },
    infiniteMode () {
      return Number(this.endBlock) === 0
    },
    distanceToStartBlock () {
      return this.startBlock - this.blockNumber
    },
    distanceToEndBlock () {
      return this.endBlock - this.blockNumber
    },
    distanceToBonusStartBlock () {
      return this.bonusStart - this.blockNumber
    },
    distanceToBonusEndBlock () {
      return this.bonusEnd - this.blockNumber
    },
    durationInBlocks () {
      return this.endBlock - this.startBlock
    },
    bonusDurationInBlocks () {
      return this.bonusEnd - this.bonusStart
    },
    bonusDurationAsTime () {
      var duration = this.bonusDurationInBlocks
      duration = duration / 4 // mins
      if (duration > 1440) {
        return parseInt(duration / 1440) + ' days'
      } else if (duration > 60) {
        return parseInt(duration / 60) + ' hours'
      }
      return duration + ' minites'
    },
    pegToken () {
      return this.token0IsPeg ? this.poolInfo.univ2pair.token0 : this.poolInfo.univ2pair.token1
    },
    monthlyYield () {
      var monthly = ethers.utils.parseUnits('1', this.rewardToken.decimals)
      monthly = monthly.mul(this.apy).div(100).div(12)
      var amount = ethers.utils.formatUnits(monthly, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    dailyYield () {
      var monthly = ethers.utils.parseUnits('1', this.rewardToken.decimals)
      monthly = monthly.mul(this.apy).div(100).div(365)
      var amount = ethers.utils.formatUnits(monthly, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    monthlyYieldBonus () {
      var monthly = ethers.utils.parseUnits('1', this.rewardToken.decimals)
      monthly = monthly.mul(this.apy).mul(this.bonus).div(100).div(12)
      var amount = ethers.utils.formatUnits(monthly, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    dailyYieldBonus () {
      var monthly = ethers.utils.parseUnits('1', this.rewardToken.decimals)
      monthly = monthly.mul(this.apy).mul(this.bonus).div(100).div(365)
      var amount = ethers.utils.formatUnits(monthly, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
    infiniteFarming: {
      get () {
        return this.endBlock === '0'
      },
      set (nv) {
        if (nv) {
          this.endBlock = '0'
        } else {
          this.endBlock = Number(this.startBlock) + 10000
        }
      }
    }
  },

  watch: {
    bonusPeriod (nv) {
      if (nv) {
        // this.bonus = '10'
      } else {
        this.bonusStart = '0'
        this.bonusEnd = '0'
        this.bonus = '0'
      }
    }
  },

  methods: {
    async addFarm () {
      this.creatingFarm = true
      var fee = await FarmContract.getGasFee()
      TxWrapper.doTransaction(FarmContract.addFarm(this.poolInfo.poolInfo.address, this.token0IsPeg, this.rewardToken.address, this.startBlock, this.endBlock, this.apy * 10, this.bonusStart, this.bonusEnd, this.bonus, fee))
        .then(() => {
          this.stage = 5
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.creatingFarm = false
        })
    },
    setStartDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var mins = duration.asMinutes();
      this.startBlock = parseInt(this.blockNumber + (mins * 4))
    },
    setEndDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var mins = duration.asMinutes();
      this.endBlock = parseInt(this.blockNumber + (mins * 4))
    },
    setBonusStartDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var mins = duration.asMinutes();
      this.bonusStart = parseInt(this.blockNumber + (mins * 4))
    },
    setBonusEndDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var mins = duration.asMinutes();
      this.bonusEnd = parseInt(this.blockNumber + (mins * 4))
    },
  },

  created () {
    this.startBlock = this.blockNumber
  }
}
</script>