<template>

  <v-container class="pa-4">
      
    <!--
    <div v-if="bonusPeriodActive" class="light-blue white--text text-center">
      <div class="font-weight-bold headline white--text">
        {{ uniswapPoolInfo.bonus }}X Bonus Yield!
      </div>
      <v-chip
      class="ma-2"
      color="white"
      label
      outlined
      >
        <v-icon left>mdi-twitter</v-icon>
        {{ uniswapPoolInfo.bonus * uniswapPoolInfo.numerator }}% APY
      </v-chip>
    </div>
    -->

    <div v-if="firstLoad" class="text-center pa-8 text--text">
      Loading...
    </div>

    <v-scroll-x-transition>
      <v-card v-if="!firstLoad" class="br-20 pa-6">

        <v-row class="align-center pb-4">

          <v-btn @click="$router.push('/pairs')" text color="">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="orbitron t-large font-weight-bold">
            {{ pairName }}
          </div>
          <v-spacer></v-spacer>
          <div style="width: 64px;">

          </div>
        </v-row>

        <div v-if="false">
          <v-row class="align-center">
            <!--
            <v-col cols="4" class="text-center">
              <v-progress-circular
              :size="80"
              width="3"
              color="heading"
              :rotate="270"
              :value="percentOfTotalStaked"
              >
                <div class="font-weight-bold">
                  {{ percentOfPoolTotal }}%
                </div>
              </v-progress-circular>
            </v-col>
            -->
            <v-col cols="12" class="">

              <!--
              <div class="d-flex align-center mr-3">
                <img 
                src="@/assets/img/UNCX_fill.svg"
                height="16px"
                width="16px"
                class="mr-2">
                {{ reserve0BalanceHuman }}
                {{ uniswapPoolInfo.token0.symbol }}
              </div>

              <div class="d-flex align-center">
                <img 
                src="@/assets/img/UNCX_fill.svg"
                height="16px"
                width="16px"
                class="mr-2">
                {{ reserve1BalanceHuman }}
                {{ uniswapPoolInfo.token1.symbol }}
              </div>
              -->
            </v-col>

          </v-row>
        </div>

        <!--
        <div class="d-flex caption font-italic text--text mr-3 ml-3">
          <div>
            {{ uniswapPoolInfo.token0.symbol }} {{ reserve0BalanceHuman }}
          </div>
          <v-spacer></v-spacer>
          <div>
            {{ uniswapPoolInfo.token1.symbol }} {{ reserve1BalanceHuman }}
          </div>
          <v-spacer></v-spacer>
          <div>
            Total Supply: {{ totalSupplyHuman }}
          </div>
        </div>
        -->

        <div class="pt-2 pb-4">

          <!--
          <v-card v-if="bonusPeriodUpcoming" class="background">
            <v-row class="ma-0 pa-0 align-center">
              <v-col cols="6">
                {{ uniswapPoolInfo.bonus }}X Bonus Yield Farming starts in
                {{ bonusPeriodUpcomingInBlocks }} block{{ bonusPeriodUpcomingInBlocks === 1 ? '' : 's' }}
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                color="light-blue"
                height="10"
                :value="progressTillBonus"
                striped
                rounded
                ></v-progress-linear>
              </v-col>
            </v-row>

          </v-card>
          -->

          <div v-if="amountWithdrawable == '0'" class="pa-8 text-center">
            <v-icon size="80" color="textFaint">mdi-sprout-outline</v-icon>
            <div class="font-weight-bold textFaint--text">
              Not farming
            </div>
          </div>

          <div v-if="amountWithdrawable !== '0'" class="pa-8 text-center">
            <v-icon size="80" color="" class="">mdi-sprout-outline</v-icon>
            <div class="font-weight-bold">
              Your farming this pool
            </div>
          </div>

          <expansion-panel v-if="amountWithdrawable !== '0'">
            <template v-slot:header="{ expand, toggle }">
              <v-row @click="toggle" class="r-outlined align-center pa-4 pl-6 br-20 ma-0">
                <span class="font-weight-bold title">{{ amountWithdrawableHuman }}</span>
                <v-spacer></v-spacer>
                Total staking
                <v-spacer></v-spacer>
                <v-icon small :class="['ml-4', {'rotate-180': expand}]">mdi-chevron-down</v-icon>
              </v-row>
            </template>
            <template v-slot:body>
              <!-- MAIN CONTENT -->
              <div class="pt-2 pb-1">
                <v-card outlined v-if="sEthers.coinbase && amountWithdrawable !== '0'" class="br-20 pa-2 pl-6">
                  <div class="caption">
                    Staking
                  </div>
                  <div class="align-center text-center d-flex justify-start">
                    <div class="font-weight-bold title">
                      {{ amountWithdrawableHuman }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn v-if="amountWithdrawable !== '0'" @click="unstake" outlined rounded class="ma-1 font-weight-bold">
                      Withdraw
                    </v-btn>
                  </div>
                </v-card>
            
              </div>
              <!-- MAIN CONTENT -->
            </template>
          </expansion-panel>

          <!--
          <div class="d-flex">
            Your farming balance
            <v-spacer></v-spacer>
            <span class="font-weight-bold">{{ amountTotalHuman }}</span>
          </div>
          -->

          <v-card v-if="sEthers.coinbase && LPBalance !== '0'" class="mt-4 mb-6 br-20">
            <div>
              <div class="pa-2 pl-6">
                <div class="caption">
                  Available to stake
                </div>
                <div class="align-center text-center d-flex justify-start">
                  <div class="font-weight-bold title">
                    {{ LPBalanceHuman }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn v-if="LPBalance !== '0'" @click="stake" color="primary" depressed rounded class="ma-1 white--text purple-button font-weight-bold">
                    Stake
                  </v-btn>
                </div>

              </div>

            </div>
          </v-card>

          <div class="mt-2 text-end">
            <v-btn outlined large block rounded :href="`https://v2.info.uniswap.org/pair/${address}`" target="_blank" class="">
              <img 
              src="@/assets/img/uniswap_logo.svg" 
              height="20px"
              width="20px"
              class="mr-3">
                Get liquidity
            </v-btn>
          </div>

          <div class="d-flex mt-6 textFaint--text">
            Pair Address
            <v-spacer></v-spacer>
            <v-btn color="textFaint" text v-clipboard:copy="address" style="height: unset;" class="pa-0">
              {{ condensedAddress }}
              <v-icon small class="ml-1">mdi-content-copy</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center textFaint--text">
            Your pool share
            <v-spacer></v-spacer>
            <v-progress-linear
            color="primary"
            height="4"
            style="max-width: 60px;"
            class="mr-3"
            :value="percentOfTotalStaked"
            striped
            rounded
            ></v-progress-linear>
            <span class="font-weight-bold">{{ percentOfPoolTotal }}%</span>
          </div>
          <div class="d-flex textFaint--text">
            Number of farmers
            <v-spacer></v-spacer>
            <span class="font-weight-bold">{{ uniswapPoolInfo.farmersLength }}</span>
          </div>

          <v-bottom-navigation
            v-model="tab"
            color="primary"
            grow
            class="align-center mt-10 mb-3"
            style="box-shadow: none;"
          >
            <v-btn>
              <span>Farms</span>
              <v-icon>mdi-barley</v-icon>
            </v-btn>

            <v-btn>
              <span>Deposits</span>
              <v-icon>mdi-arrow-down-circle-outline</v-icon>
            </v-btn>

            <v-btn>
              <span>Farmers</span>
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
          </v-bottom-navigation>

          <!--
          <v-tabs grow v-model="tab" class="mt-4" color="text">
            <v-tab>
              Farms
            </v-tab>
            <v-tab>
              Deposits
            </v-tab>
            <v-tab>
              Farmers
            </v-tab>
          </v-tabs>
          -->

          <v-tabs-items v-model="tab">
            <v-tab-item :key="0">
              <farms-for-pair :address="address"></farms-for-pair>
            </v-tab-item>
            <v-tab-item :key="1">
            </v-tab-item>
            <v-tab-item :key="2">
            </v-tab-item>
          </v-tabs-items>

          <!-- FARMS -->
          <!--
          <template v-if="uniswapPoolInfo.farmLength > 0">
            <div class="mt-8 mb-2 d-flex align-center">
              <div v-if="amountTotal !== '0'">
                Farming {{ uniswapPoolInfo.farmLength }} farm{{ uniswapPoolInfo.farmLength === '1' ? '' : 's' }}
              </div>
              <div v-else>
                Farms
              </div>
              <v-spacer></v-spacer>
              <v-btn to="/account" depressed rounded color="" class="font-weight-bold">
                Harvest
              </v-btn>
            </div>
            
            <div v-for="farm of farms" :key="farm.id + 'farm'" class="">
              <farm-for-pool :farmId="String(farm.id)"></farm-for-pool>
            </div>
          </template>
          <div v-else class="pa-6 text-center">
            No farms for this pool
          </div>
          -->
          <!-- FARMS -->

        </div>

      </v-card>
    </v-scroll-x-transition>

    <!--
    <v-card class="pa-3">
      <v-btn icon @click="getUniswapPoolInfo">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      {{ uniswapPoolInfo }}
    </v-card>
    -->

    <!-- DIALOGS -->
    <stake-lp-dialog ref="stakeDialog"></stake-lp-dialog>
    <unstake-lp-dialog ref="unstakeDialog"></unstake-lp-dialog>
    <!-- DIALOGS -->

  </v-container>
</template>

<script>
import FarmContract from '@/smart-contracts/farm'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'
// import AddressABI from '@/smart-contracts/address-abis'
import { ethers } from 'ethers'
import StakeLpDialog from '@/components/dialogs/uni-lp-staking/stake-lp'
import UnstakeLpDialog from '@/components/dialogs/uni-lp-staking/unstake-lp'
// import FarmForPool from '@/views/farm-for-pool'
import FarmsForPair from './farms-for-pair'

export default {
  components: {
    StakeLpDialog,
    UnstakeLpDialog,
    // FarmForPool,
    FarmsForPair,
  },
  
  props: {
    address: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    tab: 0,
    LPBalance: '0',
    amountWithdrawable: '0',
    maximumFractionDigits: 4,
    uniswapPoolInfo: {
      token0: {},
      token1: {},
      farmLength: 0,
      reserve0: '0',
      reserve1: '0',
      totalSupply: '0'
    },
    farms: [],
    firstLoad: true
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    condensedAddress () {
      var address = this.address
      if (address.length === 42) {
        return address.slice(0, 6) + '...' + address.slice(address.length - 4)
      }
      return address
    },
    balanceHuman () {
      return ethers.utils.formatUnits(this.balance, 18)
    },
    LPBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.LPBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    amountWithdrawableHuman () {
      var amount = ethers.utils.formatUnits(this.amountWithdrawable, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    reserve0BalanceHuman () {
      var amount = ethers.utils.formatUnits(this.uniswapPoolInfo.reserve0, this.uniswapPoolInfo.token0.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    reserve1BalanceHuman () {
      var amount = ethers.utils.formatUnits(this.uniswapPoolInfo.reserve1, this.uniswapPoolInfo.token1.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.uniswapPoolInfo.totalSupply, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    pairName () {
      return this.uniswapPoolInfo.token0.symbol + ' / ' + this.uniswapPoolInfo.token1.symbol
    },
    percentOfTotalStaked () {
      if (this.amountWithdrawable === '0') {
        return 0
      }
      var stakedBalance = ethers.utils.formatUnits(this.amountWithdrawable, 18)
      var totalSupply = ethers.utils.formatUnits(this.uniswapPoolInfo.totalSupply, 18)
      return (stakedBalance / totalSupply) * 100
    },
    percentOfPoolTotal () {
      return this.percentOfTotalStaked.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // lag just past a new block so balances are accurate
      setTimeout(() => {
        this.refresh()
      }, 1200)
    }
  },

  methods: {
    toggleDecimals () {
      if (this.maximumFractionDigits === 4) {
        this.maximumFractionDigits = 18
        return
      }
      this.maximumFractionDigits = 4
    },
    stake () {
      this.$refs.stakeDialog.open(this.address)
        .then()
        .catch(e => {})
    },
    unstake () {
      this.$refs.unstakeDialog.open(this.address)
        .then()
        .catch(e => {})
    },
    deposit () {
      var pid = 0
      var amount = '1000000000000000000'
      TxWrapper.doTransaction(FarmContract.deposit(pid, amount))
        .then(() => { })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    },
    async getLPBalance () {
      if (!this.sEthers.coinbase) {
        this.LPBalance = '0'
        return
      }
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.address)
      this.LPBalance = balance
    },
    async getUserInfo () {
      if (!this.sEthers.coinbase) {
        this.amountWithdrawable = '0'
        return
      }
      var userInfo = await FarmContract.userInfo(this.address, this.sEthers.coinbase)
      this.amountWithdrawable = userInfo.amountWithdrawable
    },
    async getUniswapPoolInfo () {
      var uniswapPoolInfo = JSON.parse(JSON.stringify(this.$store.state.pairs[this.address]))
      var pairInfo = await FarmContract.getV2PairInfo(this.address)
      uniswapPoolInfo.token0 = pairInfo.token0
      uniswapPoolInfo.token1 = pairInfo.token1
      uniswapPoolInfo.reserve0 = pairInfo.reserve0
      uniswapPoolInfo.reserve1 = pairInfo.reserve1
      uniswapPoolInfo.totalSupply = pairInfo.totalSupply
      this.uniswapPoolInfo = uniswapPoolInfo
    },
    async refresh () {
      await this.getUniswapPoolInfo()
      this.getLPBalance()
      await this.getUserInfo()

      var farms = JSON.parse(JSON.stringify(Object.values(this.$store.state.farms)))
      this.farms = farms.filter(farm => farm.lpToken === this.address)
      this.firstLoad = false
    },
  },

  created () {
    this.refresh()
  }
}
</script>