<template>
  <v-card>

    <v-row class="pa-4 ma-0 font-weight-medium align-center">
  
      <v-btn icon @click="$emit('goBack')">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <div class="headline">
        Add a farm
      </div>
      <v-spacer></v-spacer>
      
      <v-btn large @click="$emit('onClose')" icon color="heading">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>

    <!-- Main content -->
    <v-card-text class="pa-0">

    <div class="pa-4">
      
      <!--
      <v-btn outlined class="font-weight-medium">
        <img 
        src="@/assets/img/eth.png" 
        height="20px"
        width="20px"
        class="mr-3">
        WETH
      </v-btn>
      <v-btn outlined class="font-weight-medium">
        <img 
        src="@/assets/img/eth.png" 
        height="20px"
        width="20px"
        class="mr-3">
        AXM
      </v-btn>
      <v-btn outlined class="font-weight-medium">
        <img 
        src="@/assets/img/eth.png" 
        height="20px"
        width="20px"
        class="mr-3">
        USDT
      </v-btn>

      <div class="mt-6 mb-2">
        Trading pair
      </div>
      -->
      <div class="headline mb-2">
        Farm <span class="font-weight-bold">{{ rewardToken.symbol }}</span>
      </div>
      <div>
        On which uniswap pool?
      </div>

      <div class="mt-3 d-flex align-center pa-3 r-outlined br-20">
        <c-input :value.sync="searchToken" placeholder="Paste uniswap pair address" class="heading--text">
        </c-input>
      </div>

      <!-- PAIR HAS BEEN LOADED -->

      <div v-if="loadingPairInfo" class="text-center pa-4">
        <v-progress-circular
        :size="50"
        width="4"
        color="primary"
        indeterminate
        ></v-progress-circular>
      </div>
      <div v-else-if="univ2pair.token0.name" class="mt-4 mb-4">

        <div class="d-flex">
          Pair 
          <v-spacer></v-spacer>
          <div class="text-center font-weight-bold">
            {{ univ2pair.token0.symbol }}
            /
            {{ univ2pair.token1.symbol }}
          </div>
        </div>
        <div class="d-flex">
          Status 
          <v-spacer></v-spacer>
          <div v-if="!poolHasBeenAdded" class="font-weight-bold">
            Not added
          </div>
          <div v-else class="primary--text font-weight-bold">
            Added
          </div>
        </div>

        <!--
        <div class="text-center headline font-weight-bold">
          {{ univ2pair.token0.symbol }}
          /
          {{ univ2pair.token1.symbol }}
        </div>
        -->
        
      </div>

    </div>

    </v-card-text>

    <v-card-actions>
      <v-btn v-if="!poolHasBeenAdded"  @click="addPair" :loading="addingPair"
      class="br-12 title" color="primary" x-large block depressed>
        Add pair
      </v-btn>

      <v-btn v-if="poolHasBeenAdded" @click="onContinue"
      class="br-12 title" color="primary" x-large block depressed>
        Continue
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
// import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  props: {
    rewardToken: {}
  },

  data: () => ({
    searchToken: '',
    univ2pair: {
      token0: {},
      token1: {}
    },
    poolInfo: {},
    loadingPairInfo: false,
    addingPair: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    searchTextIsAddress () {
      try {
        ethers.utils.getAddress(this.searchToken)
        return true
      } catch (e) {
        return false
      }
    },
    poolHasBeenAdded () {
      return this.poolInfo.created === true
    }
  },

  watch: {
    searchToken (nv) {
      if (this.searchTextIsAddress) {
        this.onAddressPasted(nv)
      } else {
        this.univ2pair = {
          token0: {},
          token1: {}
        }
        this.poolInfo = {}
      }
    }
  },

  methods: {
    async onAddressPasted (address) {
      this.loadingPairInfo = true
      this.fetchPairInfo(address)
        .then(() => {})
        .catch(() => {})
        .then(() => {
          this.loadingPairInfo = false
        })
    },
    async fetchPairInfo (address) {
      var pair = await FarmContract.getV2PairInfo(address)
      var poolInfo = await FarmContract.uniswapPoolInfo(address)
      this.univ2pair = pair
      this.poolInfo = poolInfo
      return true
    },
    async refresh () {
    },
    async addPair () {
      this.addingPair = true
      var fee = await FarmContract.getGasFee()
      TxWrapper.doTransaction(FarmContract.addUniswapPool(this.searchToken, fee))
        .then(() => {
          setTimeout(() => {
            this.onAddressPasted(this.searchToken)
          }, 2000)
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.addingPair = false
        })
    },
    onContinue () {
      var combined = {
        univ2pair: this.univ2pair,
        poolInfo: this.poolInfo
      }
      this.$emit('onContinue', combined)
    }
  },

  created () {
    this.searchToken = ''
    this.univ2pair = {
      token0: {},
      token1: {}
    }
    this.poolInfo = {}
    this.refresh()
  }
}
</script>