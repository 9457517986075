<template>
  <v-container>

    <!-- MAIN -->
    <v-card class="pa-8 br-20">
      <div class="d-flex align-center mb-5 font-weight-medium">
        <v-btn @click="$router.push('/tokens')" text color="" class="pa-0">
          <v-icon>mdi-arrow-left</v-icon>
          Tokens
        </v-btn>
        <v-spacer></v-spacer>
      </div>
      <div class="d-flex align-center">
        <img 
        src="@/assets/img/eth.png" 
        height="50px"
        width="50px">
        <div class="font-weight-bold ml-3 t-large orbitron">
          {{ token.symbol }}
        </div>
        <div class="ml-3 orbitron t-large text--text">
          {{ token.name }}
        </div>
      </div>

      <div v-if="tokenMeta.tokenType === '1'">
        {{ tokenMeta.redemptionToken.symbol }} {{ tokenMeta.redemptionToken.name }}
        {{ tokenMeta.vaultBalance }}
      </div>
      
      <div class="mt-4">
        Circulating Supply: {{ totalSupplyHuman }}
      </div>
      <div v-if="premint !== '0'">
        Pre mint: {{ premintHuman }}
      </div>
      <div class="text--text font-italic caption mt-1">
        Address: {{ condensedAddress }}
        <v-btn icon small v-clipboard:copy="token.address">
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <div class="mt-4">
        <v-btn depressed rounded :href="`https://etherscan.io/address/${address}`" target="_blank" class="mr-4">
          Etherscan
        </v-btn>
        <v-btn depressed rounded :href="`https://v2.info.uniswap.org/token/${address}`" target="_blank" class="mr-4">
          Uniswap
        </v-btn>
        <v-btn depressed rounded :href="`https://twitter.com/search?q=%24${token.symbol}`" target="_blank" class="mr-4">
          Twitter
        </v-btn>
      </div>

      <template v-if="farms.length > 0">
        <div class="mt-8">
          Farm <span class="font-weight-bold">{{ token.symbol }}</span> on the following pools
        </div>
        <div class="mt-4">

          <v-card v-for="farm of farms" :key="farm.id" outlined class="pa-2 pl-4 pr-4 mb-1 d-flex align-center br-20">
            <g-farm-row :farm="farm"></g-farm-row>
          </v-card>

        </div>
      </template>

      <div v-else class="pa-6">
        No farms for this token yet
      </div>

    </v-card>

    <!-- END MAIN -->
    
  </v-container>
</template>

<script>
import ERC20 from '@/smart-contracts/erc20'
// import AddressABI from '@/smart-contracts/address-abis'
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import GFarmRow from '@/views/token/gfarm-row'

export default {
  props: {
    address: {
      type: String,
      default: ''
    }
  },
  components: {
    GFarmRow
  },

  data: () => ({
    token: {
      address: '',
      totalSupply: '0'
    },
    tokenMeta: {
      tokenType: '0',
      redemptionToken: {},
      vaultBalance: '0'

    },
    premint: '0',
    farms: []
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    condensedAddress () {
      var address = this.token.address
      if (address.length === 42) {
        return address.slice(0, 6) + '...' + address.slice(address.length - 4)
      }
      return address
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.token.totalSupply, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    premintHuman () {
      if (this.premint === '0') {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.premint, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      this.refresh()
    }
  },

  methods: {
    async refresh () {
      var premint = await FarmContract.getPremint(this.address)
      this.premint = premint

      var tokenType = await FarmContract.getRewardTokenType(this.address)
      if (tokenType === '1') {
        var redemptionToken = await FarmContract.getRedemptionToken(this.address)
        var vaultBalance = await ERC20.getBalance(this.address, redemptionToken)
        this.tokenMeta.vaultBalance = vaultBalance
        this.tokenMeta.tokenType = tokenType
        this.tokenMeta.redemptionToken = await ERC20.getERC20(redemptionToken)
      }

      var farms = JSON.parse(JSON.stringify(Object.values(this.$store.state.farms)))
      this.farms = farms.filter(farm => farm.rewardToken === this.address)

      // this.farms = farms
      this.token = await ERC20.getERC20(this.address)
    }
  },
  created () {
    var token = this.$store.state.rewardTokens[this.address]
    this.token = token
    this.refresh()
  }
}
</script>