<template>

  <v-container>
    <div :class="['br-20', {'pa-2': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">

      <div v-if="firstLoad" class="text-center pa-8 text--text">
        <v-progress-circular
        indeterminate
        size="80"
        width="2"
        color="primary"
        >
        <img 
        src="@/assets/img/UNCL_svg.svg" 
        height="68px"
        width="68px">

        </v-progress-circular>
      </div>
      <v-scroll-x-transition>
        <div v-if="!firstLoad">

          <div class="pl-4 d-flex align-center mt-6 mb-4 headline font-weight-medium">
            Account
            <v-spacer></v-spacer>
          </div>

          <template v-if="sEthers.coinbase">
            
            <template v-if="farms.length > 0">
              <div class="ml-4 pb-2 caption textFaint--text">
                Your farming the following pools
              </div>
              <farm-row v-for="farm of farms" :key="farm.farm_address"
              :farm="farm"
              class="mb-4 br-20">
              </farm-row>

              <c-pagination :filters="filters" @next="goNext" @previous="goPrevious"></c-pagination>
            </template>

            <v-card v-else class="pa-6 br-8 textFaint--text text-center">
              <v-icon color="textFaint" size="100">mdi-sprout-outline</v-icon>
              <div class="mitr mt-6">
                Your not farming any tokens yet!
              </div>
              <v-btn x-large to="/farms" text color="primary">
                View farms
              </v-btn>
            </v-card>

          </template>

          <v-card v-else class="pa-6 br-8">
            Connect your wallet to view this page
          </v-card>

        </div>
      </v-scroll-x-transition>

    </div>
    
  </v-container>
</template>

<script>
import FarmContract from '@/smart-contracts/farm'
import FarmRow from '@/views/farms/row'

export default {
  components: {
    FarmRow
  },

  data: () => ({
    loading: false,
    firstLoad: true,
    farms: [],
    filters: {
      search: '',
      // mysql
      mysql_offset: 0,
      mysql_limit: 5,
      mysql_row_count: 0
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // this.refresh()
    }
  },

  methods: {
    goNext () {
      this.filters.mysql_offset += this.filters.mysql_limit
      this.fetchFarms()
    },
    goPrevious () {
      this.filters.mysql_offset -= this.filters.mysql_limit
      this.fetchFarms()
    },
    async fetchFarms () {
      if (!this.sEthers.coinbase) {
        this.firstLoad = false
        return
      }
      var length = await FarmContract.userFarmsLength(this.sEthers.coinbase)
      var farmArray = []
      for (var i = length - 1; i > -1; i--) {
        var address = await FarmContract.userFarmAtIndex(this.sEthers.coinbase, i)
        farmArray.push(address)
      }
      var data = {farms: farmArray, filters: this.filters}
      this.$axios.post('/farms/user', data)
        .then(response => {
          this.farms = response.data.rows
          this.filters.mysql_row_count = response.data.count
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.firstLoad = false
        })
    },
    refresh () {
      this.fetchFarms()
    },
  },
  created () {
    this.refresh()
  }
}
</script>