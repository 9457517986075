<template>
  <v-card class="ma-3 pa-3">
    
    <div class="d-flex align-center">
      Uniswap Pairs

      <v-spacer></v-spacer>

      <v-btn icon @click="addMode = !addMode">
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-btn icon @click="sync">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>

    <div v-if="addMode" class="pa-3">
      <div class="d-flex r-outlined align-center">

      <c-input :value.sync="newPairToAdd" placeholder="address" class="pa-2">
      </c-input>

      <v-btn small @click="addNewPair" color="primary" class="mr-2">
        Add
      </v-btn>

      </div>

    </div>

    <pair-row v-for="address of pairs" :key="address" :address="address"></pair-row>

  </v-card>
</template>

<script>
import SMC from './smc'
import PairRow from './pair-row'
import CInput from '@/components/ui/input'

export default {
  components: {
    PairRow,
    CInput
  },

  data: () => ({
    addMode: false,
    newPairToAdd: '',
    allPairsLength: '0',
    pairs: []
  }),

  methods: {
    addNewPair () {
      this.pairs.push(this.newPairToAdd)
    },
    async sync () {
      this.pairs = []
      var all_pairs_length = await SMC.allPairsLength()

      var pairs = []
      for (var i = 0; i < 2; i++) {
        var pair = await SMC.getPairAtIndex(all_pairs_length - 1 - i)
        pairs.push(pair)
      }

      this.allPairsLength = all_pairs_length
      this.pairs = pairs
      // this.pairs = ['0x5e64cd6f84d0ee2ad2a84cadc464184e36274e0c']
    }
  },

  created () {
    this.sync()
  }
}
</script>