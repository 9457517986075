<template>
  <v-container class="">
    <v-card class="pa-6 br-20">
      <div class="text-center mt-6 mb-2 orbitron t-large font-weight-bold mitr">
        UniCrypt
      </div>

      <div class="text-center pa-8">
        <img 
        src="@/assets/img/black_wings.svg"
        height="160px"
        width="160px"
        class="ma-8">
      </div>

      <div class="pa-6">
        <div class="display-1 mb-6">
          Yield farming for any token
        </div>

        <p>
          This platform allows yield farming virtually any ERC20 token. It provides safe vault contracts for other tokens
          to deposit the farm rewards into, and a dApp thats targeted for mobile and desktop use with connections to all major wallets
          for users to farm their favourite tokens on.
        </p>
        <p>
          Currently we do not support fee on transaction tokens although this will be added in future. And we do not support
          rebasing tokens. Any other token is legible to list on the platform and supported at the moment.
          We charge a 1% fee to create a farm and use this service.
        </p>

        <div class="mt-4">
          Documentation coming... soon
        </div>
      </div>
    </v-card>
    
  </v-container>
</template>

<script>

export default {
  components: {
  },

  data: () => ({
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },

  methods: {

  },
  created () {

  }
}
</script>