<template>
  <v-dialog v-model="dialog" persistent max-width="450" scrollable content-class="br-20">
    <v-card class="br-20">

      <v-row class="pa-4 ma-0 font-weight-medium align-center border-b">
        
        <v-btn icon v-if="stage > 0 && stage < 3" @click="stage--">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <div class="headline text-center">
          Launch a Farm
          <div class="caption">
            Current block: {{ blockNumber }}
          </div>
        </div>
        <v-spacer></v-spacer>
        
        <!--
        <v-btn @click="runFunction" icon color="text">
          <v-icon>mdi-clock</v-icon>
        </v-btn>
        -->
        
        <v-btn @click="close" icon color="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <div v-if="gasInfo.useGasToken && !userHasSufficientGas" class="pa-8">
        Initially only tokens who reach out to us can launch farms. We will open this up in future but this ensures no spam
        on our first days live. Contact us at hello@unicrypt.network to get a MINTER token to launch a farm.
      </div>

      <template v-else>

        <!-- Main content -->
        <v-card-text class="pa-6">

          <div v-if="stage === 0">
            <div class="text--text pa-4">
              Launching a farm on a uniswap pair is now incredibly simple. You deposit the reward token into a vault contract,
              set the start block, endblock, and bonus period - And your done!

              <v-card outlined class="pa-4 text-center mt-8 mb-8">
                <v-icon color="pink">mdi-alert-outline</v-icon>
                <div class="pink--text">
                  Use at own risk
                </div>
                <div class="t-small">
                  Please test your token on Kovan network first. If your token does not adhere to the ERC20 spec and 
                  there is a non standard transfer function in your contract a farm may fail to transfer rewards to farmers.
                  TEST HARVESTING on Kovan before launching a farm on mainnet.
                </div>
              </v-card>

              <a href="https://docs.unicrypt.network/farming/create" class="deadlink" target="_blank">
                <v-card outlined class="pa-4 text-center c-list mt-8 mb-8">
                  <v-icon color="primary">mdi-file-document-outline</v-icon>
                  <div class="primary--text">
                    Read the documentation
                    <v-icon small color="primary">mdi-arrow-top-right</v-icon>
                  </div>
                  <div class="t-small">
                    This is a serious contract and will lock a large amount of tokens. Please read them before proceeding.
                  </div>
                </v-card>
              </a>

              <div class="caption mt-3">
                A farming contract is final. You cannot change <span class="font-weight-bold">any</span> paramaters once initialised. There is no way to remove tokens other
                than farm them out over the set period. You cannot increase rewards either although you may create additional farms.
                <div class="font-weight-bold mt-3">
                  Do not use this with
                </div>
                <div class="d-flex align-start">
                  <v-icon x-small class="mt-1 mr-2">mdi-circle</v-icon>
                  <div>
                    Rebasing tokens or any 'hack' on how a ERC20 token
                    should function.
                  </div>
                </div>
                <div class="d-flex align-start">
                  <v-icon x-small class="mt-1 mr-2">mdi-circle</v-icon>
                  <div>
                    Fee to sender tokens
                  </div>
                </div>
                <div class="d-flex align-start">
                  <v-icon x-small class="mt-1 mr-2">mdi-circle</v-icon>
                  <div>
                    We reserve the right to delist your farm from our front end app if you dont adhere to these token specifications as it will affect farmers.
                  </div>
                </div>
                <div class="mt-3">
                  Essentially 1 token must always equal 1 token. If you send someone one token and they recieve less
                  than one token you wont be able to generate a farm. For the rest of the 99% of normal ERC20 tokens with no weird transfer functions or magically changing balances (rebasing), this platform is for you.
                </div>
                <div class="mt-3 primary--text">
                  Feel free to email Mark at hello@unicrypt.network for help setting up your farm.
                </div>
              </div>
            </div>

            <v-expand-transition>
              <div v-show="gasInfo.hasLoaded && gasInfo.useGasToken" class="pa-1">
                <div class="mt-3 text-center text--text">
                  <img 
                  src="@/assets/img/UNCL_svg.svg" 
                  height="80px"
                  width="80px"
                  class="mr-3 coin-flip-once">
                  <div class="mt-3 text-center caption text--text">
                    Launching a farm requires a gas token
                  </div>
                </div>
                <v-row>
                  <v-col cols="6">
                    <div class="text-center r-outlined pa-1">
                      <div class="caption text--text">
                        Fee
                      </div>
                      <div class="font-weight-bold heading--text">
                        {{ gasFee }} {{ gasInfo.token.symbol }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="text-center r-outlined pa-1">
                      <div class="caption text--text">
                        Your balance
                      </div>
                      <div class="font-weight-bold heading--text">
                        {{ userGasBalanceHuman }} {{ gasInfo.token.symbol }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>

          </div>

          <!--
          <div v-if="stage > 0 && stage < 3" class="d-flex align-center mx-auto textFaint--text" style="max-width: 220px;">
            <div @click="stage = 1" :class="{'font-weight-medium heading--text': stage === 1}">
              Step 1
            </div>
            <v-divider class="ml-4 mr-4"></v-divider>
            <div @click="stage = 2" :class="{'font-weight-medium heading--text': stage === 2}">
              Step 2
            </div>
          </div>
          -->

          <div v-if="stage === 1" class="mt-4 heading--text">
            
            <!--
            <div class="display-1 mb-6 mt-6">
              Confirm
            </div>
            <div class="d-flex">
              Start Block <v-spacer></v-spacer><span class="font-weight-bold">{{ startBlock }}</span>
            </div>
            <div class="d-flex">
              Bonus Multiplier<v-spacer></v-spacer><span class="font-weight-bold">{{ bonus }}x</span>
            </div>
            <div class="d-flex">
              Bonus END <v-spacer></v-spacer><span class="font-weight-bold">{{ bonusEnd }}</span>
            </div>
            <div class="d-flex">
              Reward token <v-spacer></v-spacer><span class="font-weight-bold">{{ vaultTokenErc20.name }}</span>
            </div>
            <div class="d-flex">
              Vault amount <v-spacer></v-spacer><span class="font-weight-bold">{{ vaultDisplayAmount }}</span>
            </div>
            <div class="d-flex">
              Allowance <v-spacer></v-spacer><span class="font-weight-bold">{{ vaultTokenAllowance }}</span>
            </div>

            <div class="caption text--text mb-3 mt-3 text-center">
              This will deploy a new token to ethereum. Expect a gas cost of up to 1 ETH
            </div>
            -->

            <v-stepper
            v-model="e6"
            vertical
            non-linear
            class="text-start"
            >

              <!-- STEP 1 -->
              <v-stepper-step
              :complete="e6 > 1"
              :step="1"
              editable
              :rules="[() => farmTokenErrors]"
              > 
                <div v-if="vaultTokenErc20.name" class="d-flex align-center">
                  Farm <span class="font-weight-bold ml-1">{{ vaultDisplayAmount }} {{ vaultTokenErc20.symbol }}</span>
                  <coin-icon :address="vaultTokenErc20.address" :size="20" class="ml-2"></coin-icon>
                </div>
                <div v-else>
                  Farm which token?
                </div>
                <div class="textFaint--text caption">
                  {{ vaultTokenErc20.name }}
                </div>
              </v-stepper-step>

              <v-stepper-content step="1">
                <div
                class="mb-12 caption"
                >

                  <template v-if="!vaultTokenErc20.name">
                    <div class="ml-4 textFaint--text caption">
                      Paste token address
                    </div>
                    <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                      <c-input :value.sync="vaultToken" placeholder="0x..." class="">
                      </c-input>
                    </div>
                  </template>

                  <div v-if="loadingVaultToken" class="text-center mt-3">
                    <v-progress-circular
                    indeterminate
                    size="60"
                    color="primary">
                      <v-icon>mdi-ethereum</v-icon>
                    </v-progress-circular>
                    <div class="text--text">
                      Loading
                    </div>
                  </div>

                  <template v-if="vaultTokenErc20.name">

                    <v-card outlined class="pa-4 br-8">
                      <div class="d-flex align-center mb-3 font-weight-bold">
                        <coin-icon :address="vaultTokenErc20.address" class="mr-2"></coin-icon>
                        {{ vaultTokenErc20.name }}
                        <v-spacer></v-spacer>
                        <v-icon @click="vaultTokenErc20 = {}">mdi-close</v-icon>
                      </div>
                      <copy-address :address="vaultTokenErc20.address"></copy-address>
                      <div class="">
                        Symbol: 
                        <span>
                          {{ vaultTokenErc20.symbol }}
                        </span>
                      </div>
                      <div>
                        Decimals: {{ vaultTokenErc20.decimals }}
                      </div>
                      <div class="caption">
                        Your raw balance: {{ vaultTokenBalance }}
                      </div>
                    </v-card>

                    <div class="mt-6 textFaint--text">
                      Total amount to share amoung farmers over the entire farming period
                    </div>
                    <div class="r-outlined br-8">
                      <div class="d-flex align-baseline pt-2 pr-2">
                        <v-spacer></v-spacer>
                        <div class="caption textFaint--text">
                          Balance: {{ vaultTokenBalanceHuman }} {{ vaultTokenErc20.symbol }}
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <c-input :value.sync="vaultDisplayAmount" placeholder="0.0" @updateWhileFocussed="onVaultDisplayAmountChange" :class="['pa-2 title font-weight-bold']">
                        </c-input>

                        <v-btn small outlined rounded color="primary" @click="setMax" class="mr-2">
                          MAX
                        </v-btn>
                      </div>
                    </div>
                    <div v-if="vaultAmountGreaterThanBalance" class="pink--text">
                      insufficient balance
                    </div>

                  </template>
                
                </div>
                <v-btn
                  color="primary"
                  @click="e6 = 2"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <!-- STEP 1 -->

              <!-- STEP 2 -->
              <v-stepper-step
              :complete="e6 > 2"
              :step="2"
              editable
              > 
                <div v-if="lpTokenErc20.address">
                  on {{ lpTokenErc20.token0.symbol }} / {{ lpTokenErc20.token1.symbol }}
                </div>
                <div v-else>
                  Select uniswap pair
                </div>
                <div v-if="lpTokenErc20.address" class="textFaint--text caption">
                  {{ lpTokenErc20.token0.symbol }} / {{ lpTokenErc20.token1.symbol }}
                </div>
              </v-stepper-step>

              <v-stepper-content step="2">
                <div
                class="mb-12 caption"
                >

                <template v-if="!lpTokenErc20.address">
                  <div class="textFaint--text caption">
                    Paste uniswap v2 pair address that farmers can farm the yield token on
                  </div>
                  <div class="pa-2 br-20 r-outlined flex-grow-1">
                    <c-input :value.sync="lpToken" placeholder="0x..." class="">
                    </c-input>
                  </div>
                </template>

                <div v-if="loadingLPToken" class="text-center mt-3">
                  <v-progress-circular
                  indeterminate
                  size="60"
                  color="primary">
                    <v-icon>mdi-ethereum</v-icon>
                  </v-progress-circular>
                  <div class="text--text">
                    Loading
                  </div>
                </div>

                <div v-if="invalidLpTokenWarning" class="ml-2 pink--text">
                  *Invalid uniswap pair
                </div>

                <v-card outlined v-if="lpTokenErc20.address" class="pa-4 br-20 mt-3">
                  <div class="d-flex align-center font-weight-bold">
                    <coin-icon :address="lpTokenErc20.token0.address" :size="22"></coin-icon>
                    <coin-icon :address="lpTokenErc20.token1.address" :size="22" class="mr-1"></coin-icon>
                    {{ lpTokenErc20.token0.symbol }} / 
                    {{ lpTokenErc20.token1.symbol }}
                    <v-spacer></v-spacer>
                    <v-icon @click="lpTokenErc20 = {}">mdi-close</v-icon>
                  </div>
                  <copy-address :address="lpTokenErc20.address"></copy-address>
                </v-card>

                <div class="mt-6 textFaint--text">
                  This MUST be a valid uniswap v2 pair. The contract checks this is a uniswap pair on farm creation.
                  If it is not the script will revert.
                </div>
                
                </div>
                <v-btn
                  color="primary"
                  @click="e6 = 3"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <!-- STEP 2 -->

              <!-- STEP 3 -->
              <v-stepper-step
              :complete="e6 > 3"
              :step="3"
              editable
              :rules="[() => startErrors]"
              >
                <div>
                  Start Block
                </div>
                <div class="textFaint--text caption mt-2">
                  <v-icon size="20" color="textFaint">mdi-cube-outline</v-icon>
                  {{ startBlock }}
                </div>
                <div class="textFaint--text caption">
                  {{ timeToStartBlock }} - {{ dateStartBlock.format('DD.MM.YYYY HH:mm') }}
                </div>
              </v-stepper-step>

              <v-stepper-content step="3">
                <div
                class="mb-12 caption"
                >
                  <div class="textFaint--text caption mb-6">
                    We reccommend a start block at least 24 hours in advance to give people time to get ready to farm.
                  </div>
                  <div class="ml-4 textFaint--text caption">
                    Date
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-date @setDate="setStartDate">
                      <template v-slot:header>
                        {{ dateStartBlock.format('D MMM YYYY, HH:mm') }}
                      </template>
                    </c-date>
                  </div>
                  <div class="ml-4 mt-2 textFaint--text caption">
                    Block
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-input :value.sync="startBlock" placeholder="0" class="">
                    </c-input>
                  </div>
                  <div :class="['ml-4 caption', startBlock <= blockNumber ? 'pink--text' : 'textFaint--text']">
                    * must be above {{ blockNumber }}
                  </div>
                
                </div>
                <v-btn
                  color="primary"
                  @click="e6 = 4"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <!-- STEP 3 -->

              <!-- STEP 4 -->
              <v-stepper-step
              :complete="e6 > 4"
              :step="4"
              :rules="[() => bonusErrors]"
              editable
              >
                <div>
                  {{ bonus }}x Bonus
                </div>
                <div class="textFaint--text caption mt-2">
                  <v-icon size="20" color="textFaint">mdi-cube-outline</v-icon>
                  {{ bonusEnd }}
                </div>
                <div class="textFaint--text caption">
                  ends {{ timeToBonusEndBlock }} - {{ dateBonusEndBlock.format('DD.MM.YYYY HH:mm') }}
                </div>
              </v-stepper-step>

              <v-stepper-content step="4">
                <div
                class="mb-12 caption"
                >
                  <div class="ml-4 textFaint--text caption">
                    Multiplier ({{ bonus }}x)
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-input :value.sync="bonus" placeholder="0" class="">
                    </c-input>
                  </div>
                  <div v-if="Number(bonus) < 1" :class="['ml-4 caption pink--text']">
                    * must be a whole number above 1
                  </div>

                  <div class="ml-4 mt-3 textFaint--text caption">
                    Bonus periods start at the start block and end at the below specified block. For no bonus period set the multiplier to '1' and the bonus end block to {{ startBlock }}
                  </div>

                  <div class="ml-4 mt-2 textFaint--text caption">
                    Bonus end date
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-date @setDate="setBonusEndDate">
                      <template v-slot:header>
                        {{ dateBonusEndBlock.format('D MMM YYYY, HH:mm') }}
                      </template>
                    </c-date>
                  </div>
                  <div class="ml-4 mt-2 textFaint--text caption">
                    Bonus end block
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-input :value.sync="bonusEnd" placeholder="0" class="">
                    </c-input>
                  </div>
                  <div :class="['ml-4 caption', Number(bonusEnd) < Number(startBlock) ? 'pink--text' : 'textFaint--text']">
                    * must be >= {{ startBlock }}
                  </div>
                
                </div>
                <v-btn
                  color="primary"
                  @click="e6 = 5"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <!-- STEP 4 -->

              <!-- STEP 5 -->
              <v-stepper-step
              :complete="e6 > 5"
              :step="5"
              editable
              :rules="[() => endErrors]"
              >
                End Block
                <div class="textFaint--text caption mt-2">
                  <v-icon size="20" color="textFaint">mdi-cube-outline</v-icon>
                  {{ endBlock }}
                </div>
                <div class="textFaint--text caption">
                  {{ timeToEndBlock }} - {{ dateEndBlock.format('DD.MM.YYYY HH:mm') }}
                </div>
              </v-stepper-step>

              <v-stepper-content step="5">
                <div
                class="mb-12 caption"
                >
                  <div class="ml-4 textFaint--text caption">
                    Date
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-date @setDate="setEndDate">
                      <template v-slot:header>
                        {{ dateEndBlock.format('D MMM YYYY, HH:mm') }}
                      </template>
                    </c-date>
                  </div>
                  <div class="ml-4 mt-2 textFaint--text caption">
                    Block number
                  </div>
                  <div class="ml-2 pa-2 br-20 r-outlined flex-grow-1">
                    <c-input :value.sync="endBlock" placeholder="0" class="">
                    </c-input>
                  </div>
                  <div :class="['ml-4 caption', Number(endBlock) < Number(bonusEnd) ? 'pink--text' : 'textFaint--text']">
                    * must be >= {{ bonusEnd }}
                  </div>
                
                </div>
                <v-btn
                  color="primary"
                  @click="e6 = 6"
                >
                  Continue
                </v-btn>
              </v-stepper-content>
              <!-- STEP 5 -->

            </v-stepper>

            <div v-if="false" class="mt-10">
              <v-btn
              @click="determineEndBlock"
              class="br-12" depressed color="">
                Determine End block
              </v-btn>

              <v-btn
              @click="determineBlockReward"
              large
              class="br-12" depressed color="">
                Determine Block reward (continue)
              </v-btn>
            </div>

            <v-card outlined class="mt-4 pa-2">
              <div class="text-center mt-2">
                APY calculator
              </div>
              <div class="text-center textFaint--text caption mb-2">
                *Complete above steps first
              </div>

              <v-row dense class="ma-0 pa-0 caption">
                <v-col cols="6">
                  <div class="mt-2 textFaint--text caption">
                    Expected token price
                  </div>
                  <div class="pa-2 d-flex br-20 r-outlined flex-grow-1">
                    <div class="textFaint--text mr-1">$</div>
                    <c-input :value.sync="expectedPrice" placeholder="0" class="">
                    </c-input>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mt-2 textFaint--text caption">
                    Expected liquidity
                  </div>
                  <div class="pa-2 d-flex br-20 r-outlined flex-grow-1">
                    <div class="textFaint--text mr-1">$</div>
                    <c-input :value.sync="expectedLiquidity" placeholder="0" class="">
                    </c-input>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mt-2 textFaint--text caption">
                    Block Reward
                  </div>
                  <div class="text--text">
                    {{ expectedBlockReward }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mt-2 textFaint--text caption">
                    Expected APY
                  </div>
                  <div class="primary--text font-weight-bold">
                    {{ expectedAPY }}%
                  </div>
                </v-col>
              </v-row>

            </v-card>

          </div>

          <div v-if="stage === 2">
            <div class="caption mt-4">
              Please ensure these parameters are correct. Once you create a farm, your tokens will be locked in a vault and
              the ONLY way to get them out will be to farm them out until the end block with the given uniswap pair lp tokens. NO EXCEPTIONS. Use at your own risk.
              If there is a period during farming where there are 0 farmers, the block reward for those blocks will be stuck in the contract forever. It would
              be advisable to farm this farm yourself with literally 1 dollar of lp tokens to ensure there is always 1 farmer and the full block reward is farmed each block.
            </div>
            <v-card class="br-8 mt-6">

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-checkbox-blank-circle-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Uniswap pair
                  </div>
                  <div v-if="lpTokenErc20.address" class="mt-1">
                    <div class="d-flex align-center font-weight-bold">
                      <coin-icon :address="lpTokenErc20.token0.address" :size="22"></coin-icon>
                      <coin-icon :address="lpTokenErc20.token1.address" :size="22" class="mr-1"></coin-icon>
                      {{ lpTokenErc20.token0.symbol }} / 
                      {{ lpTokenErc20.token1.symbol }}
                      <v-spacer></v-spacer>
                    </div>
                    <copy-address :address="lpTokenErc20.address"></copy-address>
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-cube-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Block reward
                  </div>
                  <div class="mitr">
                    <template v-if="showRawData">
                      {{ blockReward }}
                    </template>
                    <template v-else>
                      {{ blockRewardDisplay }}
                    </template>
                    {{ vaultTokenErc20.symbol }}
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-clock-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Total Duration
                  </div>
                  <div class="mitr">
                    {{ farmDuration }} | {{ blockDuration }} blocks
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint">mdi-cube-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Start Block
                  </div>
                  <div class="mitr">
                    {{ startBlock }}
                  </div>
                  <div class="caption">
                    {{ dateStartBlock.format('DD.MM.YYYY HH:mm') }}
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint">mdi-cube-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    End Block
                  </div>
                  <div class="mitr">
                    {{ endBlock }}
                  </div>
                  <div class="caption">
                    {{ dateEndBlock.format('DD.MM.YYYY HH:mm') }}
                  </div>
                </div>
              </div>

            </v-card>

            <v-card class="br-8 mt-6">

              <div class="pa-4 border-b">
                Bonus period
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-gift-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Multiplier
                  </div>
                  <div class="mitr">
                    {{ bonus }}x
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-cube-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Block reward
                  </div>
                  <div class="mitr">
                    {{ bonusBlockReward }} {{ vaultTokenErc20.symbol }}
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-clock-outline</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Duration
                  </div>
                  <div class="mitr">
                    {{ bonusDuration }} blocks | {{ bonusDurationInTime }}
                  </div>
                  <div class="caption mitr">
                    block # {{ startBlock }} - {{ bonusEnd }}
                  </div>
                  <div class="caption">
                    {{ dateStartBlock.format('DD.MM.YYYY HH:mm') }} | {{ dateBonusEndBlock.format('DD.MM.YYYY HH:mm') }}
                  </div>
                </div>
              </div>

            </v-card>

            <v-card class="br-8 mt-6">

              <div class="pa-4 border-b">
                Service fees
              </div>

              <div class="d-flex border-b pa-4 align-center">
                <coin-icon :address="vaultTokenErc20.address" :size="40"></coin-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    {{ vaultTokenErc20.symbol }} Fee
                  </div>
                  <div class="mitr">
                    <template v-if="showRawData">
                      {{ tokenFee }}
                    </template>
                    <template v-else>
                      {{ tokenFeeHuman }}
                    </template>
                    {{ vaultTokenErc20.symbol }}
                  </div>
                </div>
              </div>

              <div v-if="gasInfo.ethFee !== '0'" class="d-flex border-b pa-4">
                <v-icon size="40" color="textFaint" @click="showRawData = !showRawData">mdi-ethereum</v-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    ETH Fee
                  </div>
                  <div class="mitr">
                    {{ ethFeeHuman }} ETH
                  </div>
                </div>
              </div>

            </v-card>

            <v-card class="br-8 mt-6">

              <div class="d-flex border-b pa-4 align-center">
                <coin-icon :address="vaultTokenErc20.address" :size="40"></coin-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Vault amount
                  </div>
                  <div class="mitr">
                    <template v-if="showRawData">
                      {{ requiredAmount }}
                    </template>
                    <template v-else>
                      {{ requiredAmountHuman }}
                    </template>
                    {{ vaultTokenErc20.symbol }}
                  </div>
                </div>
              </div>

              <div class="d-flex border-b pa-4 align-center">
                <coin-icon :address="vaultTokenErc20.address" :size="40"></coin-icon>
                <div class="ml-4">
                  <div class="caption textFaint--text">
                    Total Debit
                  </div>
                  <div class="mitr">
                    <template v-if="showRawData">
                      {{ totalDebit }}
                    </template>
                    <template v-else>
                      {{ totalDebitHuman }}
                    </template>
                    {{ vaultTokenErc20.symbol }}
                  </div>
                </div>
              </div>
            </v-card>

          </div>

          <div v-if="stage === 3" class="mt-4 heading--text text-center">
            
            <div class="display-1 mb-6 mt-6">
              Farm created!
            </div>
            <div class="d-flex">
              Allow a few minites for the api to sync with the blockchain before it is visible within the app.
            </div>
          </div>

          <!--
          {{ gasInfo }}
          <div>
            {{ allowance }}
          </div>
          <div>
            {{ allowanceIncreaseRequired }}
          </div>
          -->

        </v-card-text>

        <v-card-actions class="border-t">

          <template v-if="stage === 0">
            <div v-if="!gasInfo.hasLoaded" class="text-center" style="width: 100%;">
              <v-progress-circular
              indeterminate
              size="60"
              color="primary">
                <v-icon>mdi-ethereum</v-icon>
              </v-progress-circular>
            </div>
            <template v-else>
              <template v-if="gasInfo.useGasToken">
                <v-row dense class="ma-1">
                  <v-col cols="6">
                    <v-btn @click="approveGasToken" color="primary" x-large block depressed :disabled="!gasAllowanceIncreaseRequired" :loading="approvalLoading">
                      Approve
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn @click="stage = 1" color="primary" x-large block depressed :disabled="disabledStage1Continue">
                      Continue
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <v-btn v-else @click="stage = 1" color="primary" x-large block rounded depressed>
                Continue
              </v-btn>
            </template>
          </template>

          <v-btn v-if="stage === 1"
          @click="proceedToFinalStep"
          x-large block
          class="br-12" depressed color="primary" :loading="determiningBlockReward">
            Continue
          </v-btn>

          <template v-if="stage === 2">

            <v-row dense class="ma-1">
              <v-col cols="6">
                <v-btn @click="approveVaultToken" color="primary" x-large block depressed :disabled="!vaultAllowanceIncreaseRequired" :loading="approvalLoading">
                  Approve
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="mintToken" color="primary" x-large block depressed :disabled="disabledMintButton" :loading="minting">
                  Create
                </v-btn>
              </v-col>
            </v-row>
            
            <!--
            <v-btn v-if="allowanceIncreaseRequired" 
            @click="approve" :loading="approvalLoading"
            class="br-12 title" x-large block depressed color="primary">
              Approve {{ gasInfo.token.symbol }}
            </v-btn>
            <v-btn v-if="vaultAllowanceIncreaseRequired"
            x-large block rounded depressed @click="approveVaultToken" :loading="approvalLoading" color="primary">
              APPROVE
            </v-btn>
            <v-btn v-else
            @click="mintToken" :loading="minting"
            class="br-12 title" color="primary" x-large block depressed :disabled="disabledMintButton">
              Mint new token
            </v-btn>
            -->
          </template>

          <div v-if="stage === 3" style="flex: 1;">
            <v-btn @click="closeMintSuccessful"
            class="br-12 title" color="primary" x-large block depressed>
              Close
            </v-btn>
          </div>
        
        </v-card-actions>

      </template>

    </v-card>
  </v-dialog>
</template>

<script>
import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import farmABI from '@/smart-contracts/farm-abis'
import moment from 'moment'
import CDate from '@/components/dialogs/date-picker'

export default {
  components: {
    CDate
  },
  data: () => ({
    e6: 1,
    showRawData: false,
    dialog: false,
    resolve: null,
    reject: null,
    minting: false,
    approvalLoading: false,
    startBlock: '0',
    endBlock: '0',
    blockReward: '0',
    bonusEnd: '0',
    bonus: '0',
    vaultToken: '',
    vaultAmount: '0',
    tokenFee: '0',
    lpToken: '',
    vaultDisplayAmount: '0',
    vaultTokenAllowance: '0',
    vaultTokenBalance: '0',
    vaultTokenErc20: {},
    lpTokenErc20: {},
    gasInfo: {
      token: {}, // can be empty if there is no gas token
      gasFee: '0',
      useGasToken: false,
      ethFee: '0',
      hasLoaded: false,
    },
    userGasBalance: '0',
    allowance: '0',
    stage: 0,
    loadingVaultToken: false,
    loadingLPToken: false,
    determiningBlockReward: false,
    requiredAmount: '0',
    totalDebit: '0',
    blocksPerSecond: 13,
    invalidLpTokenWarning: false,
    expectedPrice: 0,
    expectedLiquidity: 200000,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    bonusErrors () {
      return this.bonus > 0 && this.bonusEnd >= this.startBlock
    },
    startErrors () {
      return Number(this.startBlock) > Number(this.blockNumber)
    },
    farmTokenErrors () {
      if (this.e6 === 1) {
        if (!this.vaultTokenErc20.name) {
          return true
        }
        return !this.vaultAmountGreaterThanBalance
      }
      // step greater than 1
      return this.vaultTokenErc20.name && !this.vaultAmountGreaterThanBalance
    },
    vaultAmountGreaterThanBalance () {
      return ethers.BigNumber.from(this.vaultAmount).gt(this.vaultTokenBalance)
    },
    endErrors () {
      return Number(this.endBlock) >= Number(this.blockNumber) && Number(this.endBlock) > Number(this.startBlock)
    },
    disabledMintButton () {
      return this.vaultAllowanceIncreaseRequired
    },
    disabledStage1Continue () {
      return this.gasAllowanceIncreaseRequired
    },
    expectedBlockReward () {
      var duration = this.endBlock - this.startBlock
      var blockReward = this.vaultDisplayAmount / duration
      if (isNaN(blockReward)) {
        blockReward = '?'
      }
      return blockReward
    },
    expectedAPY () {
      var yearly = this.expectedPrice * this.expectedBlockReward * 60 * 60 * 24 * 365 / this.blocksPerSecond
      var apy = (yearly / this.expectedLiquidity) * 100
      if (isNaN(apy)) {
        apy = 0
      }
      return Number(apy).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    vaultTokenIsAddress () {
      try {
        ethers.utils.getAddress(this.vaultToken)
        return true
      } catch (e) {
        return false
      }
    },
    lpTokenIsAddress () {
      try {
        ethers.utils.getAddress(this.lpToken)
        return true
      } catch (e) {
        return false
      }
    },
    // Start block
    distanceToStartBlock () {
      return this.startBlock - this.blockNumber
    },
    dateStartBlock () {
      var seconds = this.distanceToStartBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    // End block
    dateEndBlock () {
      var seconds = this.distanceToEndBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    distanceToEndBlock () {
      return this.endBlock - this.blockNumber
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    // bonus end Block
    dateBonusEndBlock () {
      var seconds = this.distanceToBonusEndBlock * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    distanceToBonusEndBlock () {
      return this.bonusEnd - this.blockNumber
    },
    timeToBonusEndBlock () {
      return this.dateBonusEndBlock.fromNow()
    },
    blockDuration () {
      var duration = this.endBlock - this.startBlock
      return duration
    },
    bonusDuration () {
      var duration = this.bonusEnd - this.startBlock
      return duration
    },
    farmDuration () {
      var seconds = this.blockDuration * this.blocksPerSecond
      var mins = seconds / 60
      var hours = mins / 60;
      var days = hours / 24;
      var rounded
      if (days > 1) {
        return `${parseInt(days)} days`
      }
      if (hours > 1) {
        rounded = Number(hours).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})
        return `${rounded} hours`
      }
      if (mins > 1) {
        rounded = Number(mins).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})
        return `${rounded} mins`
      }
      return `${seconds} seconds`
    },
    bonusBlockReward () {
      var bonusAmount = ethers.BigNumber.from(this.blockReward).mul(this.bonus)
      var amount = ethers.utils.formatUnits(bonusAmount, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    bonusDurationInTime () {
      var seconds = this.bonusDuration * this.blocksPerSecond
      var mins = seconds / 60
      var hours = mins / 60;
      var days = hours / 24;
      var rounded
      if (days > 1) {
        return `${parseInt(days)} days`
      }
      if (hours > 1) {
        rounded = Number(hours).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})
        return `${rounded} hours`
      }
      if (mins > 1) {
        rounded = Number(mins).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 1})
        return `${rounded} mins`
      }
      return `${seconds} seconds`
    },
    // continue
    gasFee () {
      if (!this.gasInfo.useGasToken) {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.gasInfo.gasFee, this.gasInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    ethFeeHuman () {
      var amount = ethers.utils.formatUnits(this.gasInfo.ethFee, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userGasBalanceHuman () {
      if (!this.gasInfo.useGasToken) {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.userGasBalance, this.gasInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    vaultTokenBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.vaultTokenBalance, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    tokenFeeHuman () {
      var amount = ethers.utils.formatUnits(this.tokenFee, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    totalDebitHuman () {
      var amount = ethers.utils.formatUnits(this.totalDebit, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    blockRewardDisplay () {
      var amount = ethers.utils.formatUnits(this.blockReward, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    requiredAmountHuman () {
      var amount = ethers.utils.formatUnits(this.requiredAmount, this.vaultTokenErc20.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userHasSufficientGas () {
      if (!this.gasInfo.hasLoaded) {
        return false
      }
      if (!this.gasInfo.useGasToken) {
        return true
      }
      return this.gasInfo.gasFee <= this.userGasBalance
    },
    gasAllowanceIncreaseRequired () {
      if (!this.gasInfo.useGasToken) {
        return false
      }
      if (ethers.BigNumber.from(this.gasInfo.gasFee).gt(this.allowance)) {
        return true
      }
      return false
    },
    vaultAllowanceIncreaseRequired () {
      var vaultAmount = this.totalDebit
      if (ethers.BigNumber.from(vaultAmount).gt(this.vaultTokenAllowance)) {
        return true
      }
      return false
    },
    tokenSpecsAreGood () {
      /* var nameIsGood = false
      var symbolIsGood = false
      if (this.name.length >= 1) {
        nameIsGood = true
      }
      if (this.symbol.length >= 1) {
        symbolIsGood = true
      }
      return nameIsGood && symbolIsGood */
      return true
    }
  },

  watch: {
    sEthersWatcher () {
      if (this.vaultTokenIsAddress) {
        this.loadVaultToken(this.vaultToken)
      } else {
        this.vaultTokenErc20 = {}
      }
    },
    vaultToken (nv) {
      if (this.vaultTokenIsAddress) {
        this.loadVaultToken(nv)
      } else {
        this.vaultTokenErc20 = {}
      }
    },
    lpToken (nv) {
      if (this.lpTokenIsAddress) {
        this.loadLPToken(nv)
      } else {
        this.lpTokenErc20 = {}
      }
    }
  },

  methods: {
    open () {
      this.dialog = true
      this.vaultToken = ''
      this.vaultTokenErc20 = {}
      this.lpToken = ''
      this.lpTokenErc20 = {}
      this.startBlock = this.blockNumber + 5760
      this.bonus = 1
      this.bonusEnd = this.blockNumber + 5760
      this.endBlock = this.blockNumber + 5760
      this.blockReward = '0'
      this.tokenFee = '0'
      this.requiredAmount = '0'
      this.totalDebit = '0'
      this.minting = false
      this.stage = 0
      this.e6 = 1
      this.refresh()
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    onVaultDisplayAmountChange (val) {
      this.vaultAmount = ethers.utils.parseUnits(val, this.vaultTokenErc20.decimals).toString()
    },
    onBlockRewardDisplayAmountChange (val) {
      this.blockReward = ethers.utils.parseUnits(val, this.vaultTokenErc20.decimals).toString()
    },
    setStartDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var secs = duration.asSeconds();
      this.startBlock = parseInt(this.blockNumber + (secs / this.blocksPerSecond))
    },
    setEndDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var secs = duration.asSeconds();
      this.endBlock = parseInt(this.blockNumber + (secs / this.blocksPerSecond))
    },
    setBonusEndDate (date) {
      var duration = moment.duration(moment(date).diff(moment()));
      var secs = duration.asSeconds();
      this.bonusEnd = parseInt(this.blockNumber + (secs / this.blocksPerSecond))
    },
    setMax () {
      this.vaultAmount = this.vaultTokenBalance
      this.vaultDisplayAmount = ethers.utils.formatUnits(this.vaultTokenBalance, this.vaultTokenErc20.decimals)
    },
    async getRewardTokenAllowance () {
      ERC20.getAllowance(this.vaultTokenErc20.address, this.sEthers.coinbase, farmABI.farm_generator_01_address)
        .then(allowance => {
          this.vaultTokenAllowance = allowance
          console.log('vault allowance')
          console.log(allowance)
        })
        .catch(() => {
          this.vaultTokenAllowance = '0'
        })
        .then(() => {
          // this.loadingPairInfo = false
        })
    },
    async getGasTokenAllowance () {
      ERC20.getAllowance(this.gasInfo.token.address, this.sEthers.coinbase, farmABI.farm_generator_01_address)
        .then(allowance => {
          this.allowance = allowance 
        })
        .catch(() => {
          this.allowance = '0'
        })
        .then(() => {
          // this.loadingPairInfo = false
        })
    },
    async getRewardTokenBalance () {
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.vaultTokenErc20.address)
      this.vaultTokenBalance = balance
    },
    async loadVaultToken (address) {
      this.loadingVaultToken = true
      
      ERC20.getERC20(address)
        .then(token => {
          this.vaultTokenErc20 = token
          this.getRewardTokenAllowance()
          this.getRewardTokenBalance()
        })
        .catch(() => {})
        .then(() => {
          this.loadingVaultToken = false
        })
    },
    async loadLPToken (address) {
      this.loadingLPToken = true

      FarmContract.getV2PairInfo(address)
        .then(token => {
          this.lpTokenErc20 = token
          this.invalidLpTokenWarning = false
        })
        .catch(() => {
          this.invalidLpTokenWarning = true
        })
        .then(() => {
          this.loadingLPToken = false
        })
    },
    closeMintSuccessful () {
      this.dialog = false
      this.resolve()
    },
    close () {
      this.dialog = false
      this.reject()
    },
    mintToken () {
      this.minting = true
      console.log(this.totalDebit)
      TxWrapper.doTransaction(FarmContract.createFarm(this.vaultToken, this.vaultAmount, this.lpToken, this.blockReward, this.startBlock, this.bonusEnd, this.bonus, this.gasInfo.ethFee), this.$root.$dialog.confirmTx)
        .then(() => {
          this.stage = 3
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.minting = false
        })
    },
    approveVaultToken () {
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20.setAllowance(this.vaultTokenErc20.address, this.totalDebit, farmABI.farm_generator_01_address), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.getRewardTokenAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    approveGasToken () {
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20.setAllowance(this.gasInfo.token.address, this.gasInfo.token.address, farmABI.farm_generator_01_address), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.getGasTokenAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    async determineEndBlock () {
      var endBlock = await FarmContract.determineEndBlock(this.vaultAmount, this.blockReward, this.startBlock, this.bonusEnd, this.bonus)
      console.log(endBlock)
    },
    proceedToFinalStep () {
      this.determiningBlockReward = true
      this.determineBlockReward()
        .then(() => { 
          this.stage = 2
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.determiningBlockReward = false
        })
    },
    async determineBlockReward () {
      var info = await FarmContract.determineBlockReward(this.vaultAmount, this.startBlock, this.bonusEnd, this.bonus, this.endBlock)
      console.log(info)
      this.blockReward = info.blockReward
      this.tokenFee = info.fee
      this.requiredAmount = info.requiredAmount
      this.totalDebit = ethers.BigNumber.from(info.requiredAmount).add(info.fee).toString()
    },
    async refresh () {
      var gasInfo = await FarmContract.getGasInfo()
      var balance = '0'
      if (gasInfo.useGasToken && gasInfo.token.address) {
        balance = await ERC20.getBalance(this.sEthers.coinbase, gasInfo.token.address)
      }
      this.gasInfo = gasInfo
      this.userGasBalance = balance
      this.getGasTokenAllowance()
      gasInfo.hasLoaded = true
    },
  }
}
</script>