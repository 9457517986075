<template>
  <div v-if="pages === 1">
  </div>
  <div v-else class="d-flex align-center justify-center pt-3 pb-3">
    <v-btn text :disabled="disableLeft" @click="goPrevious">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="ml-2 mr-2 textFaint--text">
      {{ currentPage }} / {{ pages }}
    </div>
    <v-btn text :disabled="disableRight" @click="goNext">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        mysql_offset: 0,
        mysql_limit: 5,
        mysql_row_count: 0
      })
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.filters.mysql_row_count / this.filters.mysql_limit)
    },
    currentPage () {
      return (this.filters.mysql_offset / this.filters.mysql_limit) + 1
    },
    disableLeft () {
      return this.currentPage === 1
    },
    disableRight () {
      return this.currentPage === this.pages
    }
  },
  methods: {
    goNext () {
      if (!this.disableRight) {
        this.$emit('next')
      }
    },
    goPrevious () {
      if (!this.disableLeft) {
        this.$emit('previous')
      }
    }
  }
}
</script>