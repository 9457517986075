<template>
  <v-menu bottom transition="scale-transition" max-height="80vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        icon
        v-bind="attrs"
        v-on="on"
        :color="color"
      >
        <v-progress-circular
        v-if="pendingTx.length > 0"
        indeterminate
        color="primary"
        >
          {{ pendingTxLengthHuman }}
        </v-progress-circular>

        <div v-else>
          <v-icon>mdi-ethereum</v-icon>
        </div>

      </v-btn>
    </template>
    <v-list>
      <div v-if="txnArray.length !== 0" class="pa-2 pl-6 border-b">
        Transactions
      </div>
      <div class="caption border-b pa-2 pl-6 textFaint--text">
        block # {{ blockNumber }}
      </div>
      <v-list-item v-for="tx of txnArray" :key="tx" :href="`https://etherscan.io/tx/${tx}`" target="_blank">

        <v-list-item-icon>        
          <v-progress-circular
          v-if="txns[tx].state === 'pending'"
          indeterminate
          size="24"
          width="2"
          color="primary"
          >
          </v-progress-circular>

          <v-icon v-else-if="txns[tx].state === 'completed'" color="primary">mdi-check</v-icon>
          <v-icon v-else color="pink">mdi-cancel</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          {{ condense(tx) }}
        </v-list-item-content>

        <v-list-item-avatar>
          <v-icon small>mdi-open-in-new</v-icon>
        </v-list-item-avatar>
      </v-list-item>

      <div v-if="txnArray.length === 0" class="pa-6">
        No transactions to show
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending
    },
    pendingTxLengthHuman () {
      if (this.pendingTx.length > 9) {
        return '>9'
      }
      return this.pendingTx.length
    },
    txns () {
      return this.$store.state.pendingTx.tx
    },
    txnArray () {
      return Object.keys(this.$store.state.pendingTx.tx)
    },
    walletConnected () {
      return this.sEthers.coinbase
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  methods: {
    condense (address) {
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    }
  }
}
</script>