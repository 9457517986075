<template>
  <v-card class="ma-3 pa-3">
    
    <expansion-panel>
      <template v-slot:header="{ expand, toggle }">
        <v-row @click="toggle" class="align-center">
          <div>
            Uniswap T
          </div>
          <v-spacer></v-spacer>
          <v-icon large :class="['ml-2', {'rotate-180': expand}]">mdi-chevron-down</v-icon>
        </v-row>
      </template>
      <template v-slot:body>
        <!-- MAIN CONTENT -->
        <div class="pt-2 pb-4">

          <div>
            Steps
            <v-btn small icon @click="showSteps = !showSteps">
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </div>

          <v-card v-if="showSteps" class="pa-3">
            <div>
              Step 1: JEST open dry sea fork ganache
            </div>
            <div>
              Step 2: Remix deploy AXIOM and MintFactory
            </div>
            <div>
              Step 3: JEST add axiom and 1st account and run axiom.js
            </div>
          </v-card>

          <fake-uniswap-pair></fake-uniswap-pair>

          <uniswap-factory></uniswap-factory>

          <uniswap-router></uniswap-router>
        
        </div>
      </template>
    </expansion-panel>

  </v-card>
</template>

<script>
import UniswapFactory from './uniswap-factory/uniswap-factory'
import UniswapRouter from './uniswap-router/uniswap-router'
import ExpansionPanel from '@/components/ui/expansion-panel'
import FakeUniswapPair from './fake-uniswap-pair/main'

export default {
  components: {
    UniswapFactory,
    UniswapRouter,
    ExpansionPanel,
    FakeUniswapPair
  },

  data: () => ({
    showSteps: false
  })
}
</script>