<template>

  <v-card>

    <v-row class="pa-4 ma-0 font-weight-medium align-center">
      <!--
      <v-btn icon v-if="stage > 0" @click="stage--">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      -->

      <v-spacer></v-spacer>
      <div class="headline">
        Add a farm
      </div>
      <v-spacer></v-spacer>
      
      <!--
      <v-btn @click="runFunction" icon color="text">
        <v-icon>mdi-clock</v-icon>
      </v-btn>
      -->
      
      <v-btn large @click="$emit('onClose')" icon color="heading">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>

    <!-- Main content -->
    <v-card-text class="pa-0">

      <div class="pa-4 pl-8">
        <div>
          Select a token that you control that will be issued as a reward to farmers
        </div>
        <div class="caption text--text">
          If you have an existing token please contact the team so we can manually connect your token.
        </div>

      </div>

      <div v-if="loadingTokens" class="text-center pa-8 text--text">
        <v-progress-circular
        :size="16"
        width="2"
        color="primary"
        indeterminate
        ></v-progress-circular>
        Loading...
      </div>

      <template>
        <div 
        v-for="token of ownedTokens" :key="token.address" 
        @click="selectToken(token)"
        class="d-flex align-center c-list pa-4 pl-8">
          <img 
          src="@/assets/img/eth.png" 
          height="20px"
          width="20px"
          class="mr-3">
  
          {{ token.symbol }}
          <!--
          <div class="text--text caption">
            {{ token.name }}
          </div>
          -->
          <v-spacer></v-spacer>
          <v-btn color="primary" small text @click.stop="copyText(token.address)">
            (copy address)
          </v-btn>

        </div>
        <div ref="copyTextContainer">

        </div>
      </template>

      <!--
      <v-btn @click="addPair"
      class="br-12 pink--text" color="pink lighten-4" x-large block depressed :disabled="disableAddPairButton">
        Add farm
      </v-btn>
      -->

      <!-- DIALOGS -->
      <mint-new-token-dialog ref="mintNewTokenDialog"></mint-new-token-dialog>
      <!-- DIALOGS -->

    </v-card-text>

    <v-card-actions>
      <v-btn @click="mintNewToken"
      class="br-12 title" color="primary" x-large block depressed>
        Mint a new token
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
// import { ethers } from 'ethers'
// import ERC20 from '@/smart-contracts/erc20'
// import TxWrapper from '@/smart-contracts/tx-wrapper'
import MintNewTokenDialog from '@/views/mint-new-token/dialog'

export default {
  components: {
    MintNewTokenDialog
  },
  data: () => ({
    loadingTokens: true,
    ownedTokens: []
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    disableAddPairButton () {
      return false
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    async refresh () {
      this.loadingTokens = true
      this.fetchOwnedTokens()
        .then(tokenArray => {
          this.ownedTokens = tokenArray
        })
        .catch(() => {})
        .then(() => {
          this.loadingTokens = false
        })
    },
    mintNewToken () {
      this.$refs.mintNewTokenDialog.open()
        .then(() => {
          this.refresh()
        })
        .catch(() => {})
    },
    copyText (text) {
      var container = this.$refs.copyTextContainer
      this.$copyText(text, container)
    },
    async fetchOwnedTokens () {
      var rewardTokens = JSON.parse(JSON.stringify(Object.values(this.$store.state.rewardTokens)))
      rewardTokens = rewardTokens.filter(token => token.owner === this.sEthers.coinbase)
      return rewardTokens
    },
    selectToken (token) {
      this.$emit('onSelectToken', token)
    }
  },

  created () {
    this.refresh()
  }
}
</script>