<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="4">
      {{ condensed }}
    </v-col>
    <v-col cols="4">
      {{ claimedBalanceHuman }}
    </v-col>
    <v-col cols="4">
      {{ pendingBalanceHuman }}
    </v-col>
  </v-row>

</template>

<script>
import { ethers } from 'ethers'

export default {
  props: {
    staker: {
      type: Object,
      default: function () {
        return {
          address: '',
          pending: '0',
          claimed: '0'
        }
      }
    },
    rewardToken: {}
  },

  computed: {
    condensed () {
      var address = this.staker.address
      if (address.length === 42) {
        return address.slice(0, 6) + '...' + address.slice(address.length - 4)
      }
      return address
    },
    pendingBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.staker.pending, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 6})
    },
    claimedBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.staker.claimed, this.rewardToken.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 6})
    },
  }
}
</script>