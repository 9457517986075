<template>
  <v-container class="">

    <!-- INFO -->

    <div v-if="firstLoad" class="text-center pa-8 text--text">
      Loading...
    </div>

    <v-scroll-x-transition>
      <v-card v-if="!loading" class="br-20 pa-6">

        <v-row class="align-center pb-4">

          <v-btn @click="$router.push('/farms')" text color="">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div class="headline ml-3">
            Farm {{ id }}
          </div>
          <v-spacer></v-spacer>
          <div style="width: 64px;">

          </div>
        </v-row>

        <div v-if="bonusPeriodActive" class="text-center pa-2">
          <div class="">
            <span class="font-weight-bold">{{ farm.bonus }}X Bonus</span> active
          </div>
        </div>

        <div class='br-20 pa-2 mt-4'>
          <v-row class="align-center">
            <v-col cols="12" sm="12" class="text-center">
              <v-progress-circular
              :size="120"
              width="6"
              color="heading"
              :rotate="270"
              :value="percentOfTotalStaked"
              >
                <div class="text-center">
                  <div class="caption text--text font-italic" style="margin-top: -20px;">
                    TVS
                  </div>
                  <div class="font-weight-bold">
                    {{ percentOfPoolTotal }}%
                  </div>
                </div>
              </v-progress-circular>
            </v-col>
            <v-col cols="12" sm="12" class="text-start">
              <div class='headline'>
                {{ currentAPY }}% APY
              </div>
              <div class="text--text font-italic mt-1">
                Pair:
                <v-btn :to="`/pairs/${univ2pair.address}`" text color="primary" class="pa-0" style="height: unset; min-width: unset;">
                  {{ univ2pair.token0.symbol }} / {{ univ2pair.token1.symbol }}
                </v-btn>
              </div>
              <div class="font-italic mt-1">
                Farm:
                <v-btn :to="`/tokens/${farm.rewardToken.address}`" text color="primary" class="pa-0" style="height: unset; min-width: unset;">
                  {{ farm.rewardToken.symbol }}
                </v-btn>
              </div>
              <div class="font-italic caption mt-1">
                Total Supply: {{ totalUniSupplyHuman }} UNIV2
              </div>
              <div class="text--text font-italic caption mt-1">
                TVal Staking: {{ tvsHuman }} UNIV2
              </div>
            </v-col>
          </v-row>
        </div>

        <v-card outlined class='br-20 pa-4 mt-4'>
          <div class='mb-2 text--text'>
            Farm information
          </div>
          <div class=''>
            Status: 
            <template v-if="farmingHasEnded">
              Ended
            </template>
            <template v-else>
              Active
            </template>
          </div>

          <div>
            Start block: {{ farm.startBlock }}
          </div>

          <div v-if="farm.endBlock !== '0'">
            End block: {{ farm.endBlock }}
          </div>
          <div v-if="farm.endBlock === '0'">
            End block: Infinite <span class="textFaint--text font-italic caption">(can be stopped by token owner)</span>
          </div>

          <div v-if="hasBonusPeriod" class=''>
            <div>
              Bonus: 
              <template v-if="bonusPeriodUpcoming">
                Upcoming
              </template>
              <template v-else-if="bonusPeriodActive">
                Active ({{ farm.bonus }}x)
              </template>
              <template v-else-if="bonusPeriodHasPassed">
                Passed
              </template>
            </div>
            <div>
              Bonus period: block {{ farm.bonusStart }} - {{ farm.bonusEnd }}
            </div>
          </div>

          <div v-else class="">
            Bonus period: None
          </div>

          <div v-if="startBlockUpcoming" class="text-center pa-2">
            <div class="caption text--text font-italic">
              Farming starts in {{ startBlockUpcomingInBlocks }} block{{ startBlockUpcomingInBlocks === 1 ? '' : 's' }}
            </div>
            
            <v-progress-linear
            class="mt-1 mb-1"
            color="text"
            height="6"
            stream
            buffer-value="0"
            :value="progressTillStart"
            striped
            rounded
            ></v-progress-linear>

          </div>

          <div v-if="bonusPeriodUpcoming" class="text-center pa-2">
            <div class="caption text--text font-italic">
              <span class="font-weight-bold">{{ farm.bonus }}X Bonus</span>
              farming starts in {{ bonusPeriodUpcomingInBlocks }} block{{ bonusPeriodUpcomingInBlocks === 1 ? '' : 's' }}
            </div>
            <v-progress-linear
            class="mt-1 mb-1"
            color="green"
            height="8"
            buffer-value="0"
            :value="progressTillBonus"
            stream
            striped
            rounded
            ></v-progress-linear>

          </div>

        </v-card>

        <!-- INFO -->
        <stakers v-if="false" :id="id"></stakers>

        <template v-if="farm.rewardToken.owner === sEthers.coinbase">
          <edit-params :id="id"></edit-params>
        </template>

        <!--
        <div v-if="loading" class="text-center pa-8 text--text">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="80px"
          width="80px"
          class="mr-3 coin-flip">
        </div>
        <v-scroll-x-transition>
          <div v-if="!loading">

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                Start block
              </div>
              <c-input :value.sync="startBlock" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                End block
              </div>
              <c-input :value.sync="endBlock" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                APY
              </div>
              <c-input :value.sync="apy" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                Bonus start
              </div>
              <c-input :value.sync="bonusStart" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                Bonus end
              </div>
              <c-input :value.sync="bonusEnd" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <div class="mt-3 pa-3 r-outlined">
              <div class="caption">
                Bonus
              </div>
              <c-input :value.sync="bonus" placeholder="0" class="headline heading--text">
              </c-input>
            </div>

            <v-btn
            @click="updateFarm"
            class="br-12 title mt-4" x-large block depressed color="primary">
              Update
            </v-btn>

          </div>
        </v-scroll-x-transition>
        -->

      </v-card>
    </v-scroll-x-transition>
    
  </v-container>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import ERC20 from '@/smart-contracts/erc20'
import AddressABI from '@/smart-contracts/address-abis'
import EditParams from './edit-params'
import Stakers from './stakers/stakers'

export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },

  components: {
    EditParams,
    Stakers
  },

  data: () => ({
    farm: {
      rewardToken: {},
    },
    poolInfo: {},
    univ2pair: {
      token0: {},
      token1: {},
      totalSupply: '0'
    },
    firstLoad: true,
    tvs: '0'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    totalUniSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.univ2pair.totalSupply, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    tvsHuman () {
      var amount = ethers.utils.formatUnits(this.tvs, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    currentAPY () {
      if (this.bonusPeriodActive) {
        return this.farm.apy / 10 * this.farm.bonus
      }
      return this.farm.apy / 10
    },
    percentOfTotalStaked () {
      if (this.tvs === '0') {
        return 0
      }
      var stakedBalance = ethers.utils.formatUnits(this.tvs, 18)
      var totalSupply = ethers.utils.formatUnits(this.univ2pair.totalSupply, 18)
      return (stakedBalance / totalSupply) * 100
    },
    percentOfPoolTotal () {
      return this.percentOfTotalStaked.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    startBlockUpcoming () {
      return this.farm.startBlock > this.blockNumber
    },
    startBlockUpcomingInBlocks () {
      return this.farm.startBlock - this.blockNumber
    },
    progressTillStart () {
      // using 1 day as max progress
      if (this.startBlockUpcomingInBlocks < 100) {
        return (this.startBlockUpcomingInBlocks / (100)) * 100
      }
      return (this.startBlockUpcomingInBlocks / (4 * 60 * 24)) * 100
    },
    hasBonusPeriod () {
      return this.farm.bonusStart !== '0'
    },
    bonusPeriodUpcoming () {
      return this.farm.bonusStart > this.blockNumber
    },
    bonusPeriodActive () {
      return (this.blockNumber >= this.farm.bonusStart) && (this.blockNumber < this.farm.bonusEnd)
    },
    bonusPeriodHasPassed () {
      return this.farm.bonusEnd < this.blockNumber
    },
    bonusPeriodUpcomingInBlocks () {
      return this.farm.bonusStart - this.blockNumber
    },
    bonusPeriodEndingInBlocks () {
      return this.farm.bonusEnd - this.blockNumber
    },
    progressTillBonus () {
      // using 1 day as max progress
      if (this.bonusPeriodUpcomingInBlocks < 100) {
        return (this.bonusPeriodUpcomingInBlocks / (100)) * 100
      }
      return (this.bonusPeriodUpcomingInBlocks / (4 * 60 * 24)) * 100
    },
    farmingHasEnded () {
      if (this.farm.endBlock === '0') {
        return false
      }
      return ethers.BigNumber.from(this.blockNumber).gt(this.farm.endBlock)
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // this.refresh()
    }
  },

  methods: {
    async getFarm () {
      var farm = JSON.parse(JSON.stringify(this.$store.state.farms[this.id]))
      var univ2pair = await FarmContract.getV2PairInfo(farm.lpToken)
      var rewardToken = await ERC20.getERC20(farm.rewardToken)
      var owner = await FarmContract.getRewardTokenOwner(farm.rewardToken)
      var poolInfo = await FarmContract.uniswapPoolInfo(farm.lpToken)
      rewardToken.owner = owner
      farm.rewardToken = rewardToken
      this.univ2pair = univ2pair
      this.poolInfo = poolInfo
      this.farm = farm

      var tvs = await ERC20.getBalance(AddressABI.mint_factory_address, farm.lpToken)
      this.tvs = tvs
    },
    refresh () {
      this.loading = true
      this.getFarm()
        .then(() => {
        })
        .catch(() => {})
        .then(() => {
          this.firstLoad = false
          this.loading = false
        })
    }
  },
  created () {
    this.refresh()
  }
}
</script>