export default {
  theme1: {
    light: {
      // primary: '#00D776', // green
      primary: '#5B39FE', // green
      // primary: '#D7148D', // pink
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      text: '#0F3256',
      icon: '#0F3256',
      textFaint: '#C3C5CB',
      heading: '#0F3256',
      outline: "#E7E8EA",
      background: "#F5F7F9",
      midground: "#ffffff",
      foreground: "#ffffff",
      inputcolor: "#F5F7F9",
    }
  },
  // black theme
  theme2: {
    light: {
      // primary: '#00D776', // green
      primary: '#16A3FC', // green
      // primary: '#D7148D', // pink
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      text: '#fafafa',
      icon: '#aaaaaa',
      textFaint: '#666666',
      heading: '#0F3256',
      outline: "#444444",
      background: "#1A1A1A",
      midground: "#202020",
      foreground: "#232323",
      inputcolor: "#141414",
    }
  }
}
