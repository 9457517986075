<template>
  <div>
    
    <div class="d-flex align-center">
      Uniswap Factory

      <v-spacer></v-spacer>

      <v-btn href="https://etherscan.io/address/0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f#code" target="blank">
        etherscan
      </v-btn>
    </div>

    <div>
      Length: {{ allPairsLength }}
    </div>

    <uniswap-pairs></uniswap-pairs>
    
  </div>
</template>

<script>
import SMC from './smc'
import UniswapPairs from './uniswap-pairs'

export default {
  components: {
    UniswapPairs
  },

  data: () => ({
    allPairsLength: '0'
  }),

  methods: {
    async sync () {
      var all_pairs_length = await SMC.allPairsLength()
      this.allPairsLength = all_pairs_length
    }
  },

  created () {
    this.sync()
  }
}
</script>