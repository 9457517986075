import Vue from 'vue'
import VueRouter from 'vue-router'
import Pairs from '@/views/pairs/Page.vue'
import Pair from '@/views/pair/Page.vue'
import Tokens from '@/views/tokens/Page.vue'
import Token from '@/views/token/Page.vue'
import Farms from '@/views/farms/Page.vue'
import EditFarm from '@/views/edit-farm/Page.vue'
import Account from '@/views/account/Page.vue'
import About from '@/views/About.vue'
import Cron from '@/views/cron/Page.vue'
import ErrorPage from '@/views/404/Page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Farms',
    component: Farms
  },
  {
    path: '/pairs',
    name: 'Pairs',
    component: Pairs
  },
  {
    path: '/farms',
    name: 'Farms',
    component: Farms
  },
  {
    path: '/farms/edit/:id',
    name: 'Edit Farm',
    props: true,
    component: EditFarm
  },
  {
    path: '/pairs/:address',
    name: 'Pair',
    props: true,
    component: Pair
  },
  {
    path: '/tokens',
    name: 'Tokens',
    component: Tokens
  },
  {
    path: '/tokens/:address',
    name: 'Token',
    props: true,
    component: Token
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/asdaefsfsdbvfhdfgdfgdgasrtert',
    name: 'Cron',
    component: Cron
  },
  {
    path: "*",
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
