<template>
  <v-container class="pa-0">
    <div :class="['br-20', {'': $vuetify.breakpoint.xs}, {'': !$vuetify.breakpoint.xs}]">

      <div v-if="false" class="mt-3 d-flex align-center white pa-3 r-outlined ml-2 br-20 mr-2">
        <c-input :value.sync="filters.search" placeholder="Search farm id" class="heading--text">
        </c-input>
        <v-icon>
          mdi-magnify
        </v-icon>
      </div>

      <div v-if="firstLoad" class="text-center pa-8 text--text">
        <v-progress-circular
        indeterminate
        size="80"
        width="2"
        color="primary"
        >
        <img 
        src="@/assets/img/UNCL_svg.svg" 
        height="68px"
        width="68px">

        </v-progress-circular>
      </div>
      <v-scroll-x-transition>
        <div v-if="!firstLoad">

          <div v-if="$vuetify.breakpoint.xs" class="pa-6 pb-1 white--text blue-button">
            <div v-if="total_farming_liquidity" class="text-center caption mitr">
              <div class="display-1 white--text">
                ${{ totalFLHuman }}
              </div>
              Total farming liquidity
            </div>

            <div class="d-flex white--text align-center mt-6 mb-4 font-weight-medium">
              <v-spacer></v-spacer>
              <v-btn @click="mintVault" rounded outlined color="white" class="mr-2">
                Create farm
              </v-btn>

              <v-menu
              offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    rounded outlined color="white" class=""
                    v-bind="attrs"
                    v-on="on"
                    style="min-width: 74px;"
                  >

                    <div v-if="filters.sort === 'apy'" class="mitr">%</div>
                    <v-icon v-else-if="filters.sort === 'farming_lp_value'" color="">mdi-water-outline</v-icon>

                    <v-icon small color="" :class="[{'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list dark style="min-width: 200px;">
                  <div class="pa-4 caption font-italic">
                    Filter by
                  </div>
                  <v-list-item @click="sortFarms('apy')">
                    <v-list-item-title>
                      APY
                    </v-list-item-title>
                    <v-icon v-if="filters.sort === 'apy'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-list-item>
                  <v-list-item @click="sortFarms('farming_lp_value')">
                    <v-list-item-title>
                      Liquidity value
                    </v-list-item-title>
                    <v-icon v-if="filters.sort === 'farming_lp_value'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>

            </div>

            <!--
            <div v-if="singleTokenView" class="white--text">
              <div class="d-flex align-center br-20 mb-6">
                <coin-icon :address="singleToken.address" :size="32"></coin-icon>
                <div class="ml-3">
                  <div class="font-weight-bold"> {{ singleToken.symbol }} </div>
                  <div class="t-small"> {{ singleToken.name }} </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn small rounded depressed color="white" class="primary--text" @click="showAllFarms">
                  Show all
                </v-btn>
              </div>
            </div>
            -->
          </div>

          <v-card v-else class="ma-2 br-20 mb-6 pa-6 pb-1 blue-button">
            <div v-if="total_farming_liquidity" class="white--text text-center caption mitr">
              <div class="display-1">
                ${{ totalFLHuman }}
              </div>
              Total farming liquidity
            </div>

            <div class="d-flex align-center mt-6 mb-4 font-weight-medium">
              <v-spacer></v-spacer>
              <v-btn @click="mintVault" rounded outlined color="white" class="mr-2">
                Create farm
              </v-btn>

              <v-menu
              offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    rounded outlined color="white" class=""
                    v-bind="attrs"
                    v-on="on"
                    style="min-width: 74px;"
                  >

                    <div v-if="filters.sort === 'apy'" class="mitr white--text">%</div>
                    <v-icon v-else-if="filters.sort === 'farming_lp_value'" color="white">mdi-water-outline</v-icon>

                    <v-icon small color="white" :class="[{'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list style="min-width: 200px;">
                  <div class="pa-4 caption font-italic">
                    Filter by
                  </div>
                  <v-list-item @click="sortFarms('apy')">
                    <v-list-item-title>
                      APY
                    </v-list-item-title>
                    <v-icon v-if="filters.sort === 'apy'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-list-item>
                  <v-list-item @click="sortFarms('farming_lp_value')">
                    <v-list-item-title>
                      Liquidity value
                    </v-list-item-title>
                    <v-icon v-if="filters.sort === 'farming_lp_value'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
                  </v-list-item>
                </v-list>
              </v-menu>

            </div>

            <!--
            <div v-if="singleTokenView" class="white--text">
              <div class="d-flex align-center br-20 mb-6">
                <coin-icon :address="singleToken.address" :size="32"></coin-icon>
                <div class="ml-3">
                  <div class="font-weight-bold"> {{ singleToken.symbol }} </div>
                  <div class="t-small"> {{ singleToken.name }} </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn small rounded depressed color="white" class="primary--text" @click="showAllFarms">
                  Show all
                </v-btn>
              </div>
            </div>
            -->
          </v-card>

          <div class="pa-2">

            <div style="margin-top: -20px;">

              <v-card v-if="singleTokenView" class="pa-4 white--text br-20 mb-3">
                <div class="d-flex align-center">
                  <!-- <coin-icon :address="singleToken.address" :size="24"></coin-icon> -->
                  <div class="ml-1 title">
                    <div class="font-weight-bold"> {{ singleToken.symbol }} Farms</div>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn small rounded outlined color="white" class="primary--text" @click="showAllFarms">
                    Show all
                  </v-btn>
                </div>
              </v-card>

              <div v-if="loading" class="text-center pa-8 text--text">
                <v-progress-circular
                indeterminate
                size="80"
                width="2"
                color="primary"
                >
                <img 
                src="@/assets/img/UNCL_svg.svg" 
                height="68px"
                width="68px">

                </v-progress-circular>
              </div>
              
              <farm-row v-for="farm of farms" :key="farm.farm_index"
              :farm="farm"
              class="mb-3 br-20">
              </farm-row>

              <c-pagination v-if="farms.length > 0" :filters="filters" @next="goNext" @previous="goPrevious"></c-pagination>

              <div v-else-if="!loading" class="pa-4 textFaint--text text-center">
                <v-icon color="textFaint" size="100">mdi-magnify</v-icon>
                <div class="mitr">
                  No farms found
                </div>
              </div>

            </div>

          </div>

        </div>
      </v-scroll-x-transition>

    </div>

    <!-- DIALOGS -->
    <vault-dialog ref="vaultDialog"></vault-dialog>
    <!-- DIALOGS -->
    
  </v-container>
</template>

<script>
import FarmRow from './row'
import VaultDialog from '@/views/create-farm/dialog'
import ERC20 from '@/smart-contracts/erc20'

export default {
  components: {
    FarmRow,
    VaultDialog
  },

  data: () => ({
    farms: [],
    loading: false,
    firstLoad: true,
    singleTokenAddress: '',
    singleToken: {},
    singleTokenView: false,
    filters: {
      search: '',
      sort: 'farming_lp_value',
      sortAscending: false,
      // mysql
      mysql_offset: 0,
      mysql_limit: 5,
      mysql_row_count: 0
    },
    total_farming_liquidity: null
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    totalFLHuman () {
      var amount = parseInt(this.total_farming_liquidity)
      // amount = '1230000' // for testing
      if (amount >= 1000000) {
        var deci = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return deci + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return amount
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // this.refresh()
    }
  },

  methods: {
    sortFarms (sorting) {
      if (this.filters.sort === sorting) {
        this.filters.sortAscending = !this.filters.sortAscending
      } else {
        this.filters.sort = sorting
        this.filters.sortAscending = false
      }
      this.filters.mysql_offset = 0
      this.refresh()
    },
    goNext () {
      this.filters.mysql_offset += this.filters.mysql_limit
      this.fetchFarms()
    },
    goPrevious () {
      this.filters.mysql_offset -= this.filters.mysql_limit
      this.fetchFarms()
    },
    showAllFarms () {
      this.singleToken = {}
      this.singleTokenView = false
      this.refresh()
    },
    fetchTotalFarmingLiquidity () {
      this.$axios.get('/farms/total-farming-liquidity')
        .then(response => {
          this.total_farming_liquidity = response.data.total_farming_liquidity
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          // this.firstLoad = false
        })
    },
    async fetchFarms () {
      this.farms = []
      this.loading = true
      if (this.singleTokenView) {
        this.singleToken = await ERC20.getERC20(this.singleTokenAddress)
      }
      var url = this.singleTokenView ? `/farms/token/${this.singleTokenAddress}` : '/farms'
      this.$axios.post(url, {filters: this.filters})
        .then(response => {
          this.farms = response.data.rows
          this.filters.mysql_row_count = response.data.count
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.firstLoad = false
          this.loading = false
        })
    },
    refresh () {
      this.fetchFarms()
      this.fetchTotalFarmingLiquidity()
    },
    mintVault () {
      this.$root.$dialog.connectWalletDialog.requireConnectionThen(this.openVaultDialog)
    },
    openVaultDialog () {
      this.$refs.vaultDialog.open()
        .then(() => {
          this.refresh()
        })
        .catch(() => {})
    },
  },
  created () {
    var token = this.$route.query.token
    if (token) {
      this.singleTokenAddress = token
      this.singleTokenView = true
    }
    this.refresh()
  }
}
</script>