import Vue from 'vue'

import App from './App.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'

import http from './plugins/axios'

import '@/components/globals'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/css/main.scss'

Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$axios = http

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
