<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card>
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium background align-center">
          Add a pair
          <v-spacer></v-spacer>
          
          <!--
          <v-btn @click="runFunction" icon color="text">
            <v-icon>mdi-clock</v-icon>
          </v-btn>
          -->
          
          <v-btn @click="close" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <!-- Main content -->
        <div class="pa-4">
          
          <!--
          <div class="mb-2">
            Select a peg token
          </div>
          <v-btn outlined class="font-weight-medium">
            <img 
            src="@/assets/img/eth.png" 
            height="20px"
            width="20px"
            class="mr-3">
            WETH
          </v-btn>
          <v-btn outlined class="font-weight-medium">
            <img 
            src="@/assets/img/eth.png" 
            height="20px"
            width="20px"
            class="mr-3">
            AXM
          </v-btn>
          <v-btn outlined class="font-weight-medium">
            <img 
            src="@/assets/img/eth.png" 
            height="20px"
            width="20px"
            class="mr-3">
            USDT
          </v-btn>

          <div class="mt-6 mb-2">
            Trading pair
          </div>
          -->

          <div>
            Choose an exisiting uniswap pair that contains one of the following peg tokens
          </div>
          <div class="text--text caption">
            <span v-for="peg of pegs" :key="peg.address">
              {{ peg.symbol }}
            </span>
          </div>

          <div class="mt-3 d-flex align-center pa-3 r-outlined">
            <c-input :value.sync="searchToken" placeholder="Paste uniswap pair address" class="heading--text">
            </c-input>
            <v-icon v-if="false">
              mdi-magnify
            </v-icon>
          </div>
          <div class="text-right caption text--text mb-3">
            *paste the pair and not a token address
          </div>

          <!-- PAIR HAS BEEN LOADED -->
          <template v-if="univ2pair.token0.name">

            <div v-if="poolInfo.created" class="pa-6 pink--text text-center">
              This pool has already been added
            </div>

            <div v-else>

              <div class="text-center title font-weight-bold">
                {{ univ2pair.token0.symbol }} / {{ univ2pair.token1.symbol }}
              </div>

              <div v-if="!pairContainsPeg" class="pa-6 pink--text text-center">
                This pair does not contain one of the allowed pegs
                <div class="text--text caption">
                  <span v-for="peg of pegs" :key="peg.address">
                    {{ peg.symbol }}
                  </span>
                </div>
              </div>
     
            </div>

          </template>

          <v-btn @click="addPair"
          class="br-12 pink--text" color="pink lighten-4" x-large block depressed :disabled="disableAddPairButton">
            Add pair
          </v-btn>

        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    searchToken: '',
    univ2pair: {
      token0: {},
      token1: {}
    },
    poolInfo: {},
    pegs: []
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    searchTextIsAddress () {
      try {
        ethers.utils.getAddress(this.searchToken)
        return true
      } catch (e) {
        return false
      }
    },
    pairContainsPeg () {
      var pegArray = this.pegs.map(item => item.address)
      if (pegArray.includes(this.univ2pair.token0.address)) {
        return true
      }
      if (pegArray.includes(this.univ2pair.token1.address)) {
        return true
      }
      return false
    },
    disableAddPairButton () {
      return false
    }
  },

  watch: {
    searchToken (nv) {
      if (this.searchTextIsAddress) {
        this.onAddressPasted(nv)
      } else {
        this.univ2pair = {
          token0: {},
          token1: {}
        }
        this.poolInfo = {}
      }
    }
  },

  methods: {
    open () {
      this.searchToken = ''
      this.univ2pair = {
        token0: {},
        token1: {}
      }
      this.poolInfo = {}
      this.dialog = true
      this.refresh()
    },
    close () {
      this.dialog = false
    },
    async onAddressPasted (address) {
      var pair = await FarmContract.getV2PairInfo(address)
      var poolInfo = await FarmContract.uniswapPoolInfo(address)
      this.univ2pair = pair
      this.poolInfo = poolInfo
    },
    async refresh () {
    },
    async addPair () {
      var savePairAddress = this.searchToken
      var fee = await FarmContract.getGasFee()
      TxWrapper.doTransaction(FarmContract.addUniswapPool(this.searchToken, true, fee))
        .then(() => {
          console.log(savePairAddress)
          console.log('reached')
          this.onAddressPasted(savePairAddress)
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    }
  },

  created () {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
.c-list {
  cursor: pointer;
  &:hover {
    background: rgb(247, 248, 250);
    // border-color: var(--v-primary-base);
  }
}
/* .orange-gradient:before {
  content: ""; 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/img/waves/wave1.svg);
  background-size: cover; 
  background-repeat: no-repeat; 
} */
</style>