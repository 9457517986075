<template>
  <v-card class="pa-3 ma-2">
    <div>
      Fake Uniswap pair
    </div>

    <div class="caption">
      {{ reserve0Human }} {{ pairInfo.token0.symbol }} / {{ reserve1Human }} {{ pairInfo.token1.symbol }}
    </div>

    <div>
      <v-btn @click="approve(pairInfo.token0.address)">
        approve0
      </v-btn>
      <v-btn @click="approve(pairInfo.token1.address)">
        approve1
      </v-btn>
    </div>

    <div class="d-flex align-center pr-3">
      <c-input :value.sync="pair_address" placeholder="pair address" class="pa-2 r-outlined heading--text">
      </c-input>
      <v-btn @click="getPairInfo">
        load info
      </v-btn>
    </div>

    <div class="d-flex align-center pr-3">
      <c-input :value.sync="amount0" placeholder="amoun0" class="pa-2 r-outlined heading--text">
      </c-input>
      <c-input :value.sync="amount1" placeholder="amount1" class="pa-2 r-outlined heading--text">
      </c-input>
      <v-btn @click="doDeposit">
        Do deposit
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import { ethers } from 'ethers'
import SMC from '@/views/testing/uniswap-router/smc'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'
// import AddressABI from '@/smart-contracts/address-abis'

export default {
  components: {
  },

  data: () => ({
    amount0: '0',
    amount1: '0',
    pairInfo: {
      token0: {
        decimals: '0'
      },
      token1: {
        decimals: '0'
      },
      reserve0: '0',
      reserve1: '0'
    },
    pair_address: ''
  }),

  computed: {
    reserve0Human () {
      var amount = ethers.utils.formatUnits(this.pairInfo.reserve0, this.pairInfo.token0.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.pairInfo.token0.decimals})
    },
    reserve1Human () {
      var amount = ethers.utils.formatUnits(this.pairInfo.reserve1, this.pairInfo.token1.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.pairInfo.token1.decimals})
    },
  },

  methods: {
    async doDeposit () {
      await SMC.doDeposit(this.pair_address, this.amount0, this.amount1)
    },
    approve (address) {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      TxWrapper.doTransaction(ERC20.setAllowance(address, amount, this.pair_address))
        .then(() => { })
        .catch(e => { 
          console.log(e)
          this.$root.$dialog.web3Error.open(e.message) 
        })
    },
    async getPairInfo () {
      var pairInfo = await SMC.getPairInfo(this.pair_address)
      var token0 = await ERC20.getERC20(pairInfo.token0)
      var token1 = await ERC20.getERC20(pairInfo.token1)
      pairInfo.token0 = token0
      pairInfo.token1 = token1
      this.pairInfo = pairInfo
    }
  }
}
</script>