<template>
  <v-card class="ma-3 pa-3">
    
    <div class="d-flex align-center">
      Uniswap Router

      <v-spacer></v-spacer>

      <v-btn href="https://etherscan.io/address/0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D#code" target="blank">
        etherscan
      </v-btn>
    </div>

    <v-card class="pa-3">
      <div>
        Add liquidity ETH
      </div>
      <div class="d-flex align-center pr-3">
        <c-input :value.sync="approveToken" placeholder="address" class="pa-2 r-outlined heading--text">
        </c-input>

        <v-btn color="primary" outlined rounded small @click="addLiquidityETH">
          Set
        </v-btn>
      </div>
    </v-card>

    <v-card class="pa-3">
      <div>
        Add liquidity
      </div>
      <div class="d-flex align-center pr-3">
        <c-input :value.sync="tokenA" placeholder="tokenA" class="pa-2 r-outlined heading--text">
        </c-input>
        <c-input :value.sync="tokenB" placeholder="tokenB" class="pa-2 r-outlined heading--text">
        </c-input>
      </div>
      <div class="d-flex align-center pr-3">
        <c-input :value.sync="amountADesired" placeholder="amountADesired" class="pa-2 r-outlined heading--text">
        </c-input>
        <c-input :value.sync="amountBDesired" placeholder="amountBDesired" class="pa-2 r-outlined heading--text">
        </c-input>
        <c-input :value.sync="amountAMin" placeholder="amountAMin" class="pa-2 r-outlined heading--text">
        </c-input>
        <c-input :value.sync="amountBMin" placeholder="amountBMin" class="pa-2 r-outlined heading--text">
        </c-input>

        <v-btn color="primary" outlined rounded small @click="addLiquidity">
          Set
        </v-btn>
      </div>
    </v-card>

    <v-card class="pa-3">
      <div>
        Set allowance
      </div>
      <div class="d-flex align-center pr-3">
        <c-input :value.sync="approveToken" placeholder="address" class="pa-2 r-outlined heading--text">
        </c-input>

        <v-btn color="primary" outlined rounded small @click="approve">
          Set
        </v-btn>
      </div>
    </v-card>
    
  </v-card>
</template>

<script>
import SMC from './smc'
import AddressABI from '@/smart-contracts/address-abis'
import moment from 'moment'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import CInput from '@/components/ui/input'

export default {
  components: {
    CInput
  },

  data: () => ({
    tokenA: '',
    tokenB: '',
    amountADesired: '3000000000000000000',
    amountBDesired: '3000000000000000000',
    amountAMin: '3000000000000000000',
    amountBMin: '3000000000000000000',
    approveToken: ''
  }),

  methods: {
    async addLiquidity () {
      var to = '0x6D9a8766d8D1CE814FfC8871EA54B1d9DDaBFd3e'
      var deadline = moment().add(1, 'hours').unix()
      await SMC.addLiquidity(this.tokenA, this.tokenB, this.amountADesired, this.amountBDesired, this.amountAMin, this.amountBMin, to, deadline)
    },
    async addLiquidityETH () {
      var token = AddressABI.axiom_token_address
      var amountTokenDesired = '10000000000000000000'
      var amountTokenMin = '1000000000000000000'
      var amountETH = '1000000000000000000'
      var amountEthMin = '1000000000000000000'
      var to = '0x6D9a8766d8D1CE814FfC8871EA54B1d9DDaBFd3e'
      var deadline = moment().add(1, 'hours').unix()
      await SMC.addLiquidityETH(token, amountTokenDesired, amountTokenMin, amountEthMin, to, deadline, amountETH)
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      TxWrapper.doTransaction(ERC20.setAllowance(this.approveToken, amount, AddressABI.router_address))
        .then(() => { })
        .catch(e => { 
          console.log(e)
          this.$root.$dialog.web3Error.open(e.message) 
        })
    },
  },

  created () {
    this.approveToken = AddressABI.axiom_token_address
    this.tokenA = AddressABI.axiom_token_address
    this.tokenB = AddressABI.axml_token_address
  }
}
</script>