<template>
  <v-container>

    <div :class="['br-20', {'pa-2': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">

      <div class="d-flex align-center mt-6 mb-4 headline font-weight-medium">
        Cron
        <v-spacer></v-spacer>
      </div>

      <div class="mt-3 d-flex align-center white pa-3 r-outlined ml-2 br-20 mr-2">
        <c-input :value.sync="apiKey" placeholder="API key" class="heading--text">
        </c-input>

        <v-icon v-if="apiKey === ''">
          mdi-magnify
        </v-icon>
        <v-icon v-else @click="apiKey = ''">
          mdi-close
        </v-icon>

      </div>

      <v-card class="br-20 pa-4 mt-6">
        <v-btn @click="doTask">
          Task
        </v-btn>
        <v-btn @click="testFunc">
          Test func
        </v-btn>
      </v-card>

    </div>
    
  </v-container>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
    loading: false,
    apiKey: ''
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  watch: {
  },

  methods: {
    async doTaskk () {
      this.$axios.post(`/cron/sync-farms`, {apiKey: this.apiKey})
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async testFunc () {
      this.$axios.post(`/cron/test`, {apiKey: this.apiKey})
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async doTask () {
      var data = {
        query: `
        query tokenDayDatas ($allPairs: [String!]) {
          tokenDayDatas(first: 2, orderBy: date, orderDirection: desc,
            where: {
              token_in: $allPairs
            }
          ) {
              id
              date
              priceUSD
          }
        }
        `,
        variables: {
          allPairs: ["0xadb2437e6f65682b85f814fbc12fec0508a7b1d0", "0x2f4eb47a1b1f4488c71fc10e39a4aa56af33dd49"]
        }
      }
      var response = await this.$axios.post(`https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2`, data)
      var dayData = response.data.data.tokenDayDatas
      console.log(dayData)
    },
  },
  created () {
    // this.refresh()
  }
}
</script>