<template>
  <v-dialog v-model="dialog" persistent max-width="450" scrollable content-class="br-20">
    
      <one-select-token v-if="stage === 0" @onSelectToken="onSelectToken" @onClose="close" :key="nonce"></one-select-token>

      <two-select-pair v-if="stage === 1" :rewardToken="rewardToken" @onContinue="onContinue" @onClose="close" @goBack="stage--"></two-select-pair>

      <three-farm-parameters v-if="stage === 2" :rewardToken="rewardToken" :poolInfo="poolInfo" @onClose="close" @goBack="stage--"></three-farm-parameters>

        <!--
        <v-btn @click="addPair"
        class="br-12 pink--text" color="pink lighten-4" x-large block depressed :disabled="disableAddPairButton">
          Add farm
        </v-btn>
        -->

  </v-dialog>
</template>

<script>
import OneSelectToken from './one-select-token'
import TwoSelectPair from './two-select-pair'
import ThreeFarmParameters from './three-farm-parameters'

export default {
  components: {
    OneSelectToken,
    TwoSelectPair,
    ThreeFarmParameters
  },
  data: () => ({
    dialog: false,
    stage: 0,
    rewardToken: {},
    poolInfo: {},
    nonce: 0
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    }
  },

  watch: {
  },

  methods: {
    open () {
      this.stage = 0
      this.nonce++
      this.dialog = true
      this.refresh()
    },
    close () {
      this.dialog = false
    },
    refresh () {

    },
    onSelectToken (token) {
      this.rewardToken = token
      this.stage = 1
    },
    onContinue (pool_info) {
      this.poolInfo = pool_info
      this.stage = 2
    }
  },

  created () {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
.c-list {
  cursor: pointer;
  &:hover {
    background: rgb(247, 248, 250);
    // border-color: var(--v-primary-base);
  }
}
/* .orange-gradient:before {
  content: ""; 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/img/waves/wave1.svg);
  background-size: cover; 
  background-repeat: no-repeat; 
} */
</style>