<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-8">
    <v-card>

      <v-card-text>
        
        <v-row class="ma-0 align-center">
          <div class="pt-3 pb-3">
            Withdraw UNIV2 tokens
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <v-expand-transition mode="out-in">
          <div v-if="loading" class="text--text text-center">
            Loading...
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-show="!loading" class="pa-1">

            <template v-if="!hacked">
              <div class="pa-1 align-center flex-nowrap r-outlined">
                <div class="caption text-end mr-3 pt-2">
                  Withdrawable: {{ stakedLPBalanceHuman }}
                </div>
                <div class="d-flex align-center">
                  <c-input :value.sync="amountHuman" placeholder="0.0" @updateWhileFocussed="onHumanAmountChange" class="pa-2 title font-weight-bold heading--text">
                  </c-input>
                  <v-btn small outlined rounded color="text" @click="setMax" class="mr-2">
                    MAX
                  </v-btn>
                </div>
              </div>
            </template>

            <div v-else>
              Somethings not right. Please contact the UNCX team
            </div>

          </div>
        </v-expand-transition>

        <!--
        <div>
          {{ amount }}
        </div>
        -->

      </v-card-text>

      <v-row v-if="!hacked" dense class="ma-2">
        <v-btn x-large block depressed @click="withdraw" color="primary" class="white--text" :loading="withdrawLoading">
          Accept
        </v-btn>
      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
// import AddressABI from '@/smart-contracts/address-abis'
import CInput from '@/components/ui/input'
// import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  components: {
    CInput
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    amount: '0',
    amountHuman: '0',
    lpTokenAddress: '',
    farmAddress: '',
    farmIndex: '',
    stakedBalance: '0',
    loading: true,
    withdrawLoading: false,
    hacked: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    stakedLPBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.stakedBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
  },

  watch: {
    
  },

  methods: {
    open (lpTokenAddress, farmAddress, farmIndex) {
      this.farmIndex = farmIndex
      this.farmAddress = farmAddress
      this.lpTokenAddress = lpTokenAddress
      this.amount = '0'
      this.amountHuman = '0'
      this.loading = true
      this.withdrawLoading = false
      this.loadData()
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    setMax () {
      this.amount = this.stakedBalance
      this.amountHuman = ethers.utils.formatUnits(this.stakedBalance, 18)
    },
    cancel () {
      this.reject()
      this.dialog = false
    },
    async loadData () {
      // ensure farm exists -> incase API is hacked
      var farm = await FarmContract.getFarmById(this.farmIndex)
      // console.log(this.farmAddress)
      // console.log(farm)
      if (farm.farmAddress !== this.farmAddress) {
        this.hacked = true
        this.loading = false
        return
      }

      await this.getStakedLPBalance()
      this.loading = false
    },
    async getStakedLPBalance () {
      var stakedLPBalance = await FarmContract.userInfo(this.farmAddress, this.sEthers.coinbase)
      this.stakedBalance = stakedLPBalance.amountWithdrawable
    },
    onHumanAmountChange (val) {
      this.amount = ethers.utils.parseUnits(val, 18)
    },
    withdraw () {
      this.withdrawLoading = true
      TxWrapper.doTransaction(FarmContract.withdrawUniswapPool(this.farmAddress, this.amount), this.$root.$dialog.confirmTx)
        .then(() => {
          this.resolve()
          this.dialog = false
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.withdrawLoading = false
        })
    }
  },

  created () {
    this.filteredTokens = this.savedTokens
  }
}
</script>