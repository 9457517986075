<template>
  <v-container class="pa-0">
    <div :class="['br-20 v-card ma-2']">
      
      <div :class="[{'pa-2 ma-2': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">

        <div class="pl-4 d-flex align-center mt-6 mb-4 headline font-weight-medium">
          Farmable tokens
        </div>

        <div class="mt-3 d-flex align-center pa-3 r-outlined ml-2 br-20 mr-2">
          <c-input :value.sync="filters.search" placeholder="Search tokens" class="">
          </c-input>

          <v-progress-circular
          v-if="showSearchLoader"
          indeterminate
          width="2"
          class="mr-2"
          size="24"
          color="primary"
          ></v-progress-circular>

          <v-icon v-if="filters.search === ''">
            mdi-magnify
          </v-icon>
          <v-icon v-else @click="filters.search = ''">
            mdi-close
          </v-icon>

        </div>

      </div>

      <div v-if="firstLoad" class="text-center pa-8 text--text">
        <v-progress-circular
        indeterminate
        size="80"
        width="2"
        color="primary"
        >
        <img 
        src="@/assets/img/UNCL_svg.svg" 
        height="68px"
        width="68px">

        </v-progress-circular>
      </div>
      <v-scroll-x-transition>
        <div v-if="!firstLoad" class="">

          <div v-if="sEthers.coinbase" class="textFaint--text text-end caption pr-10 mb-1 font-italic">
            Your Balance
          </div>
          
          <token-row v-for="token of tokens" :key="token.address"
          showPairName
          :token="token"
          class="">
          </token-row>

          <c-pagination v-if="tokens.length > 0" :filters="filters" @next="goNext" @previous="goPrevious"></c-pagination>

          <div v-else class="pa-4 textFaint--text text-center">
            <v-icon color="textFaint" size="100">mdi-magnify</v-icon>
            <div class="mitr">
              No tokens found
            </div>
          </div>

        </div>
      </v-scroll-x-transition>

    </div>
    
  </v-container>
</template>

<script>
import _ from 'lodash'
import TokenRow from './row'

export default {
  components: {
    TokenRow,
  },

  data: () => ({
    tokens: [],
    loading: false,
    firstLoad: true,
    showSearchLoader: false,
    filters: {
      search: '',
      // mysql
      mysql_offset: 0,
      mysql_limit: 5,
      mysql_row_count: 0
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      // this.refresh()
    },
    'filters.search' (nv) {
      this.showSearchLoader = true
      this.delaySearch()
    }
  },

  methods: {
    delaySearch: _.debounce(function () {
      // console.log(this.filters.search)
      this.filters.mysql_offset = 0
      this.fetchTokens()
    }, 500, {trailing: true}),

    async fetchTokens () {
      this.loading = true
      this.$axios.post(`/farms/tokens`, {filters: this.filters})
        .then(response => {
          this.tokens = response.data.rows
          this.filters.mysql_row_count = response.data.count
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.firstLoad = false
          this.showSearchLoader = false
        })
    },
    goNext () {
      this.filters.mysql_offset += this.filters.mysql_limit
      this.fetchTokens()
    },
    goPrevious () {
      this.filters.mysql_offset -= this.filters.mysql_limit
      this.fetchTokens()
    },
    refresh () {
      this.fetchTokens()
    },
  },
  created () {
    this.refresh()
  }
}
</script>