<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium background align-center">
          Connect to a wallet
          <v-spacer></v-spacer>
          
          <v-btn @click="close" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <div v-if="sEthers.coinbase" class="text-center d-flex justify-center pt-6 pb-3">
          <copy-address :address="sEthers.coinbase" color=""></copy-address>
        </div>
        <!-- Main content -->
        <div>

          <div class="">
            <div
            v-if="sEthers.coinbase"
            @click="disconnect" 
            class="c-list border-b border-t pa-4 d-flex align-center pink--text">
              <v-icon size="30px" color="pink" class="mr-3">mdi-logout-variant</v-icon>
              Disconnect
            </div>

            <div 
            @click="connectToMetamask()"
            class="c-list border-b pa-4 d-flex align-center">
              <img 
              src="@/assets/img/metamask.svg" 
              width="30px"
              class="mr-3">
              <div>
                Metamask - <span class="font-weight-bold">Desktop</span>
                <div v-if="$vuetify.breakpoint.smAndDown" class="t-small">
                  If you are on a mobile device use Wallet Connect
                </div>
              </div>
            </div>

            <div
            @click="connectToWalletLink" 
            class="c-list border-b pa-4 d-flex align-center">
              <img 
              src="@/assets/img/coinbase-wallet.svg" 
              width="30px"
              class="mr-3">
              Coinbase Wallet
            </div>
            
            <div
            @click="connectWalletConnect" 
            class="c-list pa-4 d-flex align-center">
              <img 
              src="@/assets/img/wallet-connect.svg" 
              width="30px"
              class="mr-3">
              <div>
                Wallet Connect - <span class="font-weight-bold">Mobile</span>
                <div class="t-small">
                  Metamask, TrustWallet, ... + 30 more
                </div>
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ethers } from "ethers";
// import SmartContract from '@/views/liquidity/components/lock-liquidity/smart-contract'
import ConnectProvider from './connect-provider'
// https://getwaves.io/

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    ethBalance: '0',
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    addressCondensed () {
      var address = this.sEthers.coinbase
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    }
  },

  methods: {
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close () {
      this.dialog = false
      this.reject()
    },
    closeAndResolve () {
      this.dialog = false
      this.resolve()
    },
    requireConnectionThen (callback) {
      if (!this.sEthers.coinbase) {
        this.open()
          .then(() => {
            callback()
          })
          .catch(e => {})
      } else {
        callback()
      }
    },
    /*
    removeMetamaskListeners () {
      window.ethereum.removeAllListeners('accountsChanged')
      window.ethereum.removeAllListeners('chainChanged')
    },
    addMetamaskListeners () {
      window.ethereum.on('accountsChanged', accounts => {
        this.$store.commit('updateEthers', {
          coinbase: accounts[0]
        })
      })
      window.ethereum.on('chainChanged', () => {
        this.connectToMetamask()
      })
    },
    */
    /*
    async getCoinbase (provider) {
      var accounts = await provider.send('eth_requestAccounts')
      return accounts[0]
    },
    /*
    async connectToWalletLink () {
      await wlprovider.enable()
      const provider = new ethers.providers.Web3Provider(wlprovider)
      const signer = provider.getSigner()
      var coinbase = await this.getCoinbase(provider)
      var homesteadProvider = ethers.getDefaultProvider('homestead')
      this.$store.commit('updateEthers', {
        coinbase: coinbase,
        providerName: 'Coinbase',
        provider: homesteadProvider,
        signer: signer,
        network: 1
      })
      this.removeMetamaskListeners()
      // this.close()
    },
    /*
    async connectToMetamask () {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      var coinbase = await this.getCoinbase(provider)
      this.$store.commit('updateEthers', {
        coinbase: coinbase,
        providerName: 'Metamask',
        provider: provider,
        signer: signer,
        network: StaticMap.networkHexToInt[window.ethereum.chainId]
      })
      this.removeMetamaskListeners()
      this.addMetamaskListeners()
    },
    */
    async connectToWalletLink () {
      await ConnectProvider.connectToWalletLink()
      this.closeAndResolve()
    },
    async connectWalletConnect () {
      await ConnectProvider.connectWalletConnect()
      this.closeAndResolve()
    },
    async connectToMetamask () {
      await ConnectProvider.connectToMetamask()
      this.closeAndResolve()
    },
    disconnect () {
      ConnectProvider.disconnect()
    },
  }
}
</script>