<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="br-20">

      <v-card-text>
        
        <v-row class="ma-0 align-center">
          <div class="pt-4 pb-4">
            Stake LP tokens
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <v-expand-transition mode="out-in">
          <div v-if="loading" class="text--text text-center">
            Loading...
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-show="!loading" class="pa-1">

            <div v-if="!hacked" class="pa-1 align-center flex-nowrap r-outlined br-8">

              <div class="caption text-end mr-3 pt-2">
                Balance: {{ unstakedLPBalanceHuman }}
              </div>

              <div class="d-flex align-center">
                <c-input :value.sync="amountHuman" placeholder="0.0" @updateWhileFocussed="onHumanAmountChange" class="pa-2 title font-weight-bold heading--text">

                </c-input>

                <v-btn small outlined rounded color="text" @click="setMax" class="mr-2">
                  MAX
                </v-btn>
              </div>

            </div>

            <div v-else>
              Somethings not right. Please contact the UNCX team
            </div>

          </div>
        </v-expand-transition>

      </v-card-text>

      <v-row v-if="!hacked" dense class="ma-4">
        <v-col cols="6">
          <v-btn @click="approve" color="primary" class="white--text" x-large block depressed :disabled="!allowanceIncreaseRequired" :loading="approvalLoading">
            Approve
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn @click="acceptStake" color="primary" class="white--text" x-large block depressed :disabled="allowanceIncreaseRequired || amount === '0'" :loading="stakeLoading">
            Stake
          </v-btn>
        </v-col>
      </v-row>

      <!--
      <v-row class="ma-0" v-else>
        <v-col cols="6">
          <v-btn x-large block depressed rounded @click="cancel">
            CANCEL
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn v-if="allowanceIncreaseRequired"
          x-large block rounded depressed @click="approve" :loading="approvalLoading" color="primary">
            APPROVE
          </v-btn>
          <v-btn v-else x-large block rounded depressed @click="acceptStake" color="primary" class="purple-button">
            STAKE
          </v-btn>
        </v-col>
      </v-row>
      -->

    </v-card>
  </v-dialog>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import AddressABI from '@/smart-contracts/address-abis'
import CInput from '@/components/ui/input'
import ERC20 from '@/smart-contracts/erc20'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  components: {
    CInput
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    amount: '0',
    amountHuman: '0',
    lpTokenAddress: '',
    farmAddress: '',
    farmIndex: '',
    uniswapPoolInfo: {},
    // poolInfo: {},
    LPBalance: '0',
    allowance: '0',
    txnLength: '0',
    loading: true,
    approvalLoading: false,
    stakeLoading: false,
    hacked: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    unstakedLPBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.LPBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    allowanceIncreaseRequired () {
      if (ethers.BigNumber.from(this.amount).gt(this.allowance)) {
        return true
      }
      return false
    }
  },

  watch: {
    
  },

  methods: {
    open (lpTokenAddress, farmAddress, farmIndex) {
      this.farmIndex = farmIndex
      this.farmAddress = farmAddress
      this.lpTokenAddress = lpTokenAddress
      this.amount = '0'
      this.amountHuman = '0'
      this.loading = true
      this.approvalLoading = false
      this.stakeLoading = false
      this.loadData()
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    acceptStake () {
      this.deposit()
      // this.resolve()
      // this.dialog = false
    },
    setMax () {
      this.amount = this.LPBalance
      this.amountHuman = ethers.utils.formatUnits(this.LPBalance, 18)
    },
    cancel () {
      this.reject()
      this.dialog = false
    },
    async loadData () {
      // ensure farm exists -> incase API is hacked
      var farm = await FarmContract.getFarmById(this.farmIndex)
      // console.log(this.farmAddress)
      // console.log(farm)
      if (farm.farmAddress !== this.farmAddress) {
        this.hacked = true
        this.loading = false
        return
      }
      this.getAllowance()
      await this.getUnstakedBalance()
      this.loading = false
    },
    async getUnstakedBalance () {
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.lpTokenAddress)
      this.LPBalance = balance
    },
    async getAllowance () {
      var allowance = await ERC20.getAllowance(this.lpTokenAddress, this.sEthers.coinbase, this.farmAddress)
      this.allowance = allowance
    },
    onHumanAmountChange (val) {
      try {
        this.amount = ethers.utils.parseUnits(val, 18).toString()
      } catch {
        this.amount = '0'
      }
    },
    deposit () {
      this.stakeLoading = true
      TxWrapper.doTransaction(FarmContract.depositUniswapPool(this.farmAddress, this.amount), this.$root.$dialog.confirmTx)
        .then(() => {
          /* this.amount = '0'
          this.amountHuman = '0'
          this.loadData() */
          this.resolve()
          this.dialog = false
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.stakeLoading = false
        })
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true

      TxWrapper.doTransaction(ERC20.setAllowance(this.lpTokenAddress, amount, this.farmAddress), this.$root.$dialog.confirmTx)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    revoke () {
      var amount = '0'
      TxWrapper.doTransaction(ERC20.setAllowance(this.lpTokenAddress, amount, AddressABI.mint_factory_address))
        .then(() => { })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    },
  },
}
</script>