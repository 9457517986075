<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card>
      <v-row class="pa-4 ma-0 font-weight-medium background align-center">
        Error
        <v-spacer></v-spacer>
        
        <!--
        <v-btn @click="runFunction" icon color="text">
          <v-icon>mdi-clock</v-icon>
        </v-btn>
        -->
        
        <v-btn @click="close" icon color="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="pa-6">
        <div class="text-center">
          {{ error_message }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    error_message: ''
  }),
  methods: {
    open (error_message) {
      this.error_message = error_message
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>