<template>
  <v-card outlined class="ma-2 br-20">
   
    <expansion-panel>
      <template v-slot:header="{ expand, toggle }">
        
        <div @click.stop="toggle" class="d-flex pa-4 align-center">
          <!--
          <v-btn color="primary" text class="font-weight-bold pa-0" @click="$router.push(`/pairs/${pool.address}`)">
            {{ pool.token0.symbol }} / {{ pool.token1.symbol }}
          </v-btn>
          -->
          <v-btn color="primary" text class="t-large font-weight-bold pa-0" @click="$router.push(`/pairs/${pool.address}`)">
            {{ pool.token0.symbol }} / {{ pool.token1.symbol }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="balance !== '0'" @click="$router.push(`/pairs/${pool.address}`)" small color="primary" depressed rounded class="ma-1 font-weight-bold purple-button">
            Stake
          </v-btn>
          <!--
          <v-btn v-if="balance !== '0'" @click="$router.push(`/pairs/${pool.address}`)" small text color="primary" class="ma-1">
            Stake
          </v-btn>
          -->
          <div v-if="pool.stakingInfo.stakedBalance !== '0'" class="ml-2 font-weight-bold">
            <v-icon color="">mdi-sprout-outline</v-icon> {{ pool.farmLength }}
          </div>
          <div v-else class="ml-2 font-weight-bold textFaint--text">
            <v-icon color="textFaint">mdi-sprout-outline</v-icon> {{ pool.farmLength }}
          </div>
          <v-icon :class="['ml-2', {'rotate-180': expand}]">mdi-chevron-down</v-icon>
        </div>
      </template>
      <template v-slot:body>
        <!-- MAIN CONTENT -->
        <div class="pa-4">
          <template v-if="farms.length > 0">
            <div class="pa-2 textFaint--text">
              Farm the following rewards
            </div>
            <farm-for-pool v-for="farm of farms" :key="farm.id + 'farm'"
            :farmId="String(farm.id)" class="mb-4 br-20">
            </farm-for-pool>
          </template>
          <div v-else class="textFaint--text">
            No farms for this pair
          </div>
        </div>
        <!-- MAIN CONTENT -->
      </template>
    </expansion-panel>

    <div v-if="false" class="text--text caption">
      {{ pool.address }}
    </div>

    <div v-if="false" class="d-flex align-center">
      <v-btn v-if="pool.stakingInfo.stakedBalance !== '0'" @click="$router.push(`/pairs/${pool.address}`)" small rounded depressed class="ma-1">
        Staking {{ stakedBalanceHuman }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="balance !== '0'" @click="$router.push(`/pairs/${pool.address}`)" small rounded color="primary" depressed class="ma-1 white--text purple-button">
        Stake {{ balanceHuman }}
      </v-btn>
    </div>

  </v-card>
</template>

<script>
import ERC20 from '@/smart-contracts/erc20'
import { ethers } from 'ethers'
import FarmForPool from '@/views/farm-for-pool'

export default {
  props: {
    pool: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  components: {
    FarmForPool
  },

  data: () => ({
    balance: '0',
    farms: []
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    balanceHuman () {
      var amount = ethers.utils.formatUnits(this.balance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    stakedBalanceHuman () {
      var amount = ethers.utils.formatUnits(this.pool.stakingInfo.stakedBalance, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
  },

  methods: {
    async refresh () {
      this.balance = '0'
      if (this.sEthers.coinbase) {
        this.balance = await ERC20.getBalance(this.sEthers.coinbase, this.pool.address)
      }

      var farms = JSON.parse(JSON.stringify(Object.values(this.$store.state.farms)))
      this.farms = farms.filter(farm => farm.lpToken === this.pool.address)
    }
  },

  created () {
    this.refresh()
  }
}
</script>