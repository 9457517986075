<template>
  <div>

    <div class="d-flex align-center">
      <img 
      src="@/assets/img/eth.png" 
      height="20px"
      width="20px"
      class="mr-2">
      {{ reserveHuman }}
      &nbsp;
      {{ token.symbol }}
    </div>
    
    <div v-if="debug" class="caption grey--text">
      {{ token }}
    </div>
  </div>
</template>

<script>
import ERC20 from '@/smart-contracts/erc20'
import { ethers } from 'ethers'

export default {
  props: {
    address: {
      type: String,
      default: ''
    },
    reserve: {
      type: String,
      default: '0'
    },
    debug: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    token: {}
  }),

  computed: {
    reserveHuman () {
      var amount = ethers.utils.formatUnits(this.reserve, this.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.token.decimals})
    },
  },

  methods: {
    async sync () {
      var token = await ERC20.getERC20(this.address)
      this.token = token
    }
  },

  created () {
    this.sync()
  }
}
</script>