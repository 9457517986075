<template>
  <div>
    <div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          Start block
        </div>
        <c-input :value.sync="startBlock" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          End block
        </div>
        <c-input :value.sync="endBlock" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          APY
        </div>
        <c-input :value.sync="apy" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          Bonus start
        </div>
        <c-input :value.sync="bonusStart" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          Bonus end
        </div>
        <c-input :value.sync="bonusEnd" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <div class="mt-3 pa-3 r-outlined">
        <div class="caption">
          Bonus
        </div>
        <c-input :value.sync="bonus" placeholder="0" class="headline heading--text">
        </c-input>
      </div>

      <v-btn
      @click="updateFarm"
      class="br-12 title mt-4" x-large block depressed color="primary">
        Update
      </v-btn>

    </div>
  </div>
</template>

<script>
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'

export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },

  data: () => ({
    rewardTokenOwner: '',
    startBlock: '0',
    endBlock: '0',
    apy: '0',
    bonusStart: '0',
    bonusEnd: '0',
    bonus: '0',
  }),

  methods: {
    async getFarm () {
      var farm = JSON.parse(JSON.stringify(this.$store.state.farms[this.id]))
      var owner = await FarmContract.getRewardTokenOwner(farm.rewardToken)
      this.rewardTokenOwner = owner
      // update vars
      this.startBlock = farm.startBlock
      this.endBlock = farm.endBlock
      this.apy = farm.apy
      this.bonusStart = farm.bonusStart
      this.bonusEnd = farm.bonusEnd
      this.bonus = farm.bonus
    },
    async updateStoreFarm () {
      var farm = await FarmContract.getFarmById(this.id)
      farm.id = this.id
      this.$store.commit('updateFarm', farm)
    },
    updateFarm () {
      TxWrapper.doTransaction(FarmContract.updateFarm(this.id, this.startBlock, this.endBlock, this.apy, this.bonusStart, this.bonusEnd, this.bonus))
        .then(() => {
          this.updateStoreFarm()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    },
  },

  created () {
    this.getFarm()
  }
}
</script>