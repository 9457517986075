<template>
  <div>

    <div class="mt-6">
      <v-btn v-if="userIsTokenOwner" @click="distributeRewards" text color="primary">
        Distribute rewards
      </v-btn>
      <v-btn v-if="userIsTokenOwner" @click="tallyRewards" text color="primary">
        Tally
      </v-btn>
    </div>
    <div>
      <v-row class="ma-0 pa-0 caption font-italic">
        <v-col cols="4">
          Account
        </v-col>
        <v-col cols="4">
          Claimed
        </v-col>
        <v-col cols="4">
          Claimable
        </v-col>
      </v-row>
      <staker-row v-for="staker of stakers" :key="staker.address" :staker="staker" :rewardToken="farm.rewardToken"></staker-row>
    </div>
       
  </div>
</template>

<script>
import { ethers } from 'ethers'
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import StakerRow from './staker-row'

export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },

  components: {
    StakerRow
  },

  data: () => ({
    rewardTokenOwner: '',
    farm: {},
    stakers: [],
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    userIsTokenOwner () {
      return this.sEthers.coinbase === this.rewardTokenOwner
    }
  },

  methods: {
    async getStakerInfo () {
      var farm = JSON.parse(JSON.stringify(this.$store.state.farms[this.id]))
      this.farm = farm
      var owner = await FarmContract.getRewardTokenOwner(farm.rewardToken)
      this.rewardTokenOwner = owner
      var poolInfo = await FarmContract.uniswapPoolInfo(farm.lpToken)

      var numFarmers = poolInfo.farmersLength
      var stakers = []
      for (var i = 0; i < numFarmers; i++) {
        var address = await FarmContract.getPoolFarmerAtIndex(farm.lpToken, i)
        var pending = await FarmContract.pendingFarmRewards(this.id, address)
        var claimed = await FarmContract.claimedFarmRewards(this.id, address)
        stakers.push({
          address: address,
          pending: pending,
          claimed: claimed
        })
      }
      this.stakers = stakers
    },
    tallyRewards () {
      var total = ethers.BigNumber.from('0')
      this.stakers.forEach(staker => {
        total = total.add(staker.pending).add(staker.claimed)
      })
      console.log(total.toString())
    },
    distributeRewards () {
      var stakerArray = this.stakers
      // stakerArray = stakerArray.slice(0, 1)
      var stakers = stakerArray.map(staker => staker.address)
      // console.log(stakers)
      TxWrapper.doTransaction(FarmContract.adminDistributeRewardsForFarm(this.id, stakers))
        .then(() => {
          this.getStakerInfo()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
    },
  },

  created () {
    this.getStakerInfo()
  }
}
</script>