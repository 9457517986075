<template>
  <v-dialog v-model="dialog" persistent max-width="450" scrollable content-class="br-20">
    <v-card>

      <v-row class="pa-4 ma-0 font-weight-medium align-center border-b">
        
        <v-btn icon v-if="stage > 0 && stage < 3" @click="stage--">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <div class="headline">
          Mint a token
        </div>
        <v-spacer></v-spacer>
        
        <!--
        <v-btn @click="runFunction" icon color="text">
          <v-icon>mdi-clock</v-icon>
        </v-btn>
        -->
        
        <v-btn @click="close" icon color="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <!-- Main content -->
      <v-card-text class="pa-6">

        <div v-if="stage === 0">
          <div class="text--text pa-4">
            If you have an exisiting token we can connect it to the dApp, in which case you dont need to mint a new token.
            We only offer this service to credible tokens that have been on the market for a few months minimum. Please contact our team to set this up.
          </div>

          <v-expand-transition mode="out-in">
            <div v-if="!gasInfo.hasLoaded" class="text--text text-center">
              Loading...
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div v-show="gasInfo.hasLoaded && gasInfo.useGasToken" class="pa-1">
              <div class="mt-3 text-center text--text">
                <img 
                src="@/assets/img/UNCX_fill.svg" 
                height="80px"
                width="80px"
                class="mr-3 coin-flip-once">
                <div class="mt-3 text-center caption text--text">
                  Minting a new token requires
                </div>
              </div>
              <v-row>
                <v-col cols="6">
                  <div class="text-center r-outlined pa-1">
                    <div class="caption text--text">
                      Fee
                    </div>
                    <div class="font-weight-bold heading--text">
                      {{ gasFee }} {{ gasInfo.token.symbol }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="text-center r-outlined pa-1">
                    <div class="caption text--text">
                      Your balance
                    </div>
                    <div class="font-weight-bold heading--text">
                      {{ userGasBalanceHuman }} {{ gasInfo.token.symbol }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>

        </div>

        <div v-if="stage > 0 && stage < 3" class="d-flex align-center mx-auto textFaint--text" style="max-width: 220px;">
          <div :class="{'font-weight-medium heading--text': stage === 1}">
            Step 1
          </div>
          <v-divider class="ml-4 mr-4"></v-divider>
          <div :class="{'font-weight-medium heading--text': stage === 2}">
            Step 2
          </div>
        </div>

        <div v-if="stage === 1">
          <div class="mt-3 pa-3 r-outlined">
            <div class="">
              Token name
            </div>
            <c-input :value.sync="name" placeholder="UniCrypt" class="mt-2 headline heading--text">
            </c-input>
          </div>
          <div class="caption text--text mb-8">
            *type as you would like the name displayed. Pay attention to upper and lowercase
          </div>

          <div class="mt-3 pa-3 r-outlined">
            <div class="">
              Token symbol
            </div>
            <c-input :value.sync="symbol" placeholder="UNCX" class="mt-2 headline heading--text">
            </c-input>
          </div>

          <div class="mt-8 pa-3 r-outlined">
            <div class="">
              Premint
            </div>
            <c-input :value.sync="premint" placeholder="0" class="mt-2 headline heading--text">
            </c-input>
          </div>
          <div class="caption text--text mt-2 mb-3">
            Enter the whole number e.g. 100 for 100 tokens (we will add 18 decimal places). Preminted tokens are recorded and displayed for everyone to see
          </div>
        </div>

        <div v-if="stage === 2" class="mt-4 heading--text text-center">
          
          <div class="display-1 mb-6 mt-6">
            Confirm
          </div>
          <div class="d-flex">
            Token name <v-spacer></v-spacer><span class="font-weight-bold">{{ name }}</span>
          </div>
          <div class="d-flex">
            Token symbol <v-spacer></v-spacer><span class="font-weight-bold">{{ symbol }}</span>
          </div>
          <div class="d-flex">
            Premint <v-spacer></v-spacer><span class="font-weight-bold">{{ premintAsText }}</span>
          </div>

          <div class="caption text--text mb-3 mt-3 text-center">
            This will deploy a new token to ethereum. Expect a gas cost of up to 1 ETH
          </div>

          <template v-if="gasInfo.useGasToken">
            <div class="mt-3 text-center text--text">
              <div class="mt-3 text-center caption text--text">
                Minting fee
              </div>
            </div>
            <v-row>
              <v-col cols="6">
                <div class="text-center r-outlined pa-1">
                  <div class="caption text--text">
                    Fee
                  </div>
                  <div class="font-weight-bold heading--text">
                    {{ gasFee }} {{ gasInfo.token.symbol }}
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="text-center r-outlined pa-1">
                  <div class="caption text--text">
                    Your balance
                  </div>
                  {{ userGasBalanceHuman }} {{ gasInfo.token.symbol }}
                </div>
              </v-col>
            </v-row>
          </template>
        </div>

        <div v-if="stage === 3" class="mt-4 heading--text text-center">
          
          <div class="display-1 mb-6 mt-6">
            Token minted!
          </div>
          <div class="d-flex">
            If you cannot see it in the app yet, please refresh the app.
          </div>
        </div>

        <!--
        {{ gasInfo }}
        <div>
          {{ allowance }}
        </div>
        <div>
          {{ allowanceIncreaseRequired }}
        </div>
        -->

      </v-card-text>

      <v-card-actions>
        <v-btn v-if="stage === 0" 
        @click="stage++"
        class="br-12 title" x-large block depressed color="primary" :disabled="!userHasSufficientGas">
          Continue
        </v-btn>

        <v-btn v-if="stage === 1" 
        @click="stage++"
        class="br-12 title" x-large block depressed color="primary" :disabled="!tokenSpecsAreGood">
          Continue
        </v-btn>

        <template v-if="stage === 2">
          <v-btn v-if="allowanceIncreaseRequired" 
          @click="approve" :loading="approvalLoading"
          class="br-12 title" x-large block depressed color="primary">
            Approve {{ gasInfo.token.symbol }}
          </v-btn>
          <v-btn v-else
          @click="mintToken" :loading="minting"
          class="br-12 title" color="primary" x-large block depressed :disabled="disabledMintButton">
            Mint new token
          </v-btn>
        </template>

        <div v-if="stage === 3" style="flex: 1;">
          <v-btn @click="closeMintSuccessful"
          class="br-12 title" color="primary" x-large block depressed>
            Close
          </v-btn>
        </div>
       
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import FarmContract from '@/smart-contracts/farm'
import TxWrapper from '@/smart-contracts/tx-wrapper'
import AddressABI from '@/smart-contracts/address-abis'

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    minting: false,
    approvalLoading: false,
    name: '',
    symbol: '',
    premint: '',
    gasInfo: {
      token: {}, // can be empty if there is no gas token
      fee: '0',
      useGasToken: false,
      hasLoaded: false,
    },
    userGasBalance: '0',
    allowance: '0',
    stage: 0
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    disabledMintButton () {
      return false
    },
    gasFee () {
      if (!this.gasInfo.useGasToken) {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.gasInfo.fee, this.gasInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userGasBalanceHuman () {
      if (!this.gasInfo.useGasToken) {
        return '0'
      }
      var amount = ethers.utils.formatUnits(this.userGasBalance, this.gasInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userHasSufficientGas () {
      if (!this.gasInfo.hasLoaded) {
        return false
      }
      if (!this.gasInfo.useGasToken) {
        return true
      }
      return this.gasInfo.fee <= this.userGasBalance
    },
    allowanceIncreaseRequired () {
      if (!this.gasInfo.useGasToken) {
        return false
      }
      if (ethers.BigNumber.from(this.gasInfo.fee).gt(this.allowance)) {
        return true
      }
      return false
    },
    premintAsText () {
      try {
        return ethers.BigNumber.from(this.premint).toString()
      } catch {
        return '0'
      }
    },
    tokenSpecsAreGood () {
      var nameIsGood = false
      var symbolIsGood = false
      var premintIsGood = false
      if (this.name.length >= 1) {
        nameIsGood = true
      }
      if (this.symbol.length >= 1) {
        symbolIsGood = true
      }
      if (!isNaN(this.premint)) {
        premintIsGood = true
      }
      return nameIsGood && symbolIsGood && premintIsGood
    }
  },

  watch: {
  },

  methods: {
    open () {
      this.name = ''
      this.symbol = ''
      this.premint = ''
      this.dialog = true
      this.minting = false
      this.stage = 0
      this.refresh()
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    closeMintSuccessful () {
      this.dialog = false
      this.resolve()
    },
    close () {
      this.dialog = false
      this.reject()
    },
    mintToken () {
      this.minting = true
      var premint = ethers.utils.parseUnits(this.premintAsText, 9)
      TxWrapper.doTransaction(FarmContract.mintStandardToken(this.name, this.symbol.toUpperCase(), premint))
        .then(() => {
          this.stage = 3
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.minting = false
        })
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20.setAllowance(this.gasInfo.token.address, amount, AddressABI.minter_address))
        .then(() => { 
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    async refresh () {
      var gasInfo = await FarmContract.getGasInfo()
      var balance = '0'
      var allowance = '0'
      if (gasInfo.useGasToken && gasInfo.token.address) {
        balance = await ERC20.getBalance(this.sEthers.coinbase, gasInfo.token.address)
        allowance = await ERC20.getAllowance(gasInfo.token.address, this.sEthers.coinbase, AddressABI.minter_address)
      }
      this.gasInfo = gasInfo
      this.userGasBalance = balance
      this.allowance = allowance
      gasInfo.hasLoaded = true
    },
  }
}
</script>