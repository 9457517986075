import Vue from 'vue'
import Vuex from 'vuex'
import themes from '@/plugins/themes.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    theme: 'theme1',
    blockNumber: -1,
    checker: {
      lastBlockSync: -1,
      lastFarm: -1,
      lastRewardToken: -1,
      lastPair: -1
    },
    farms: {}, // {farmId: {}}
    rewardTokens: {}, // {address: {}}
    pairs: {}, // {address: {}}
    ethers: {
      coinbase: null,
      coinbase_condensed: null,
      providerName: null, // values: Metamask, Coinbase
      provider: null,
      signer: null,
      network: 1,
      watcher: null, // watcher
      walletConnectProvider: null,
      walletLinkProvider: null
    },
    pendingTx: {
      pending: [], // [txhash, txhash]
      tx: {}
    },
  },
  mutations: {
    setTheme (state, {themeName, vuetify}) {
      state.theme = themeName
      vuetify.theme.setTheme('light', themes[themeName].light)
      // vuetify.theme.setTheme('dark', themes[themeName].themes.dark)
      // vuetify.theme.light = themeDark
      localStorage.setItem('theme', themeName)
      // localStorage.setItem('themeDark', themeDark)
      // vuetify.framework.theme.themes = themes[themeName].vuetifyTheme
    },
    setBlockNumber (state, blockNumber) {
      state.blockNumber = blockNumber
    },
    setLastBlockSync (state, blockNumber) {
      state.checker.lastBlockSync = blockNumber
    },
    addFarms (state, farms) {
      console.log(`adding ${farms.length} farms`)
      var lastFarm = state.checker.lastFarm
      for (var farm of farms) {
        state.farms[farm.id] = farm
        if (farm.id > lastFarm) {
          lastFarm = farm.id
        }
      }
      state.checker.lastFarm = lastFarm
    },
    addRewardTokens (state, tokens) {
      console.log(`adding ${tokens.length} tokens`)
      var lastRewardToken = state.checker.lastRewardToken
      for (var token of tokens) {
        state.rewardTokens[token.address] = token
        if (token.id > lastRewardToken) {
          lastRewardToken = token.id
        }
      }
      state.checker.lastRewardToken = lastRewardToken
    },
    addPairs (state, pairs) {
      console.log(`adding ${pairs.length} pairs`)
      var lastPair = state.checker.lastPair
      for (var pair of pairs) {
        state.pairs[pair.address] = pair
        if (pair.id > lastPair) {
          lastPair = pair.id
        }
      }
      state.checker.lastPair = lastPair
    },
    updateFarm (state, farm) {
      console.log(`updating farm ${farm.id}`)
      state.farms[farm.id] = farm
    },
    updateEthers (state, payload) {
      state.ethers = Object.assign(state.ethers, payload)
      state.ethers.watcher = state.ethers.coinbase + state.ethers.network
      if ((state.ethers.coinbase || '').length === 42) {
        state.ethers.coinbase_condensed = state.ethers.coinbase.slice(0, 6) + '...' + state.ethers.coinbase.slice(state.ethers.coinbase.length - 4)
      } 
    },
    addPendingTx (state, txhash) {
      state.pendingTx.pending.push(txhash)

      var item = {
        created: Date.now(),
        txhash: txhash,
        state: 'pending'
      }
      Vue.set(state.pendingTx.tx, txhash, item)
    },
    completePendingTx (state, txhash) {
      state.pendingTx.pending = state.pendingTx.pending.filter(item => item !== txhash)
      state.pendingTx.tx[txhash].state = 'completed'
    },
    failPendingTx (state, txhash) {
      state.pendingTx.pending = state.pendingTx.pending.filter(item => item !== txhash)
      if (state.pendingTx.tx[txhash]) {
        state.pendingTx.tx[txhash].state = 'failed'
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
