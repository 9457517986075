import {store} from '@/store/index'
import { ethers } from "ethers"
import WalletConnectProvider from "@walletconnect/web3-provider"
import WalletLink from 'walletlink'
import StaticConnectorVars from './static'

// Initialize WalletLink
const walletLink = new WalletLink({
  appName: "UniCrypt",
  appLogoUrl: "https://i.imgur.com/WCwbSiX.png",
  darkMode: false
})

const Self = {
  async getCoinbase (provider) {
    var accounts = await provider.send('eth_requestAccounts')
    // var accounts = await provider.eth.getAccounts()
    return accounts[0]
  },
  async connectViewOnlyProvider () {
    // var homesteadProvider = ethers.getDefaultProvider('kovan')
    // var homesteadProvider = ethers.getDefaultProvider('ropsten')
    // var homesteadProvider = new ethers.providers.JsonRpcProvider("https://ropsten.infura.io/v3/3cd774e14cf34ff78167908f8377051c");
    // var homesteadProvider = ethers.getDefaultProvider('homestead')
    // var homesteadProvider = new ethers.providers.JsonRpcProvider("http://127.0.0.1:7545"); // ganache
    var homesteadProvider = new ethers.providers.JsonRpcProvider("https://broken-proud-cherry.quiknode.pro/f146c59b4b14b3c76ea6c04cdb21d0a3/");
    store.commit('updateEthers', {
      provider: homesteadProvider,
      network: 1
    })
  },
  async connectToMetamask () {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    var coinbase = await this.getCoinbase(provider)
    var checksum_address = ethers.utils.getAddress(coinbase)
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.METAMASK,
      provider: provider,
      signer: signer,
      // network: StaticMap.networkHexToInt[window.ethereum.chainId]
    })
    this.removeMetamaskListeners()
    this.addMetamaskListeners()
  },
  // coinbase
  async connectToWalletLink () {
    var wlprovider = walletLink.makeWeb3Provider("https://broken-proud-cherry.quiknode.pro/f146c59b4b14b3c76ea6c04cdb21d0a3/", 1)
    const provider = new ethers.providers.Web3Provider(wlprovider)
    const signer = provider.getSigner()
    var coinbase = await this.getCoinbase(provider)
    var checksum_address = ethers.utils.getAddress(coinbase)
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.WALLETLINK,
      provider: provider,
      signer: signer,
      walletLinkProvider: wlprovider
      // network: StaticMap.networkHexToInt[window.ethereum.chainId]
    })
    this.removeMetamaskListeners()
  },
  async connectWalletConnect () {
    const walletConnectProvider = new WalletConnectProvider({
      rpc: {
        1: "https://broken-proud-cherry.quiknode.pro/f146c59b4b14b3c76ea6c04cdb21d0a3/"
      }
      // infuraId: '3cd774e14cf34ff78167908f8377051c' // Required
    });
    await walletConnectProvider.enable();
    var connector = new ethers.providers.Web3Provider(walletConnectProvider)
    const signer = connector.getSigner()

    var checksum_address = ethers.utils.getAddress(walletConnectProvider.wc.accounts[0])
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.WALLETCONNECT,
      provider: connector,
      signer: signer,
      walletConnectProvider: walletConnectProvider
    })

    // Check if connection is already established
    if (!walletConnectProvider.connected) {
      // create new session
      walletConnectProvider.createSession();
    }

    // Subscribe to connection events
    connector.on("connect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];
      console.log(accounts)
      console.log(chainId)
    })

    walletConnectProvider.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get updated accounts and chainId
      const { accounts, chainId } = payload.params[0]
      console.log(accounts)
      console.log(chainId)
    })

    walletConnectProvider.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Delete connector
    })
  },
  async connectToMobileWallet () {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    var coinbase = await this.getCoinbase(provider)
    var checksum_address = ethers.utils.getAddress(coinbase)
    var homesteadProvider = ethers.getDefaultProvider('homestead')

    var providerName = ""
    if (window.ethereum.isMetaMask) {
      providerName = StaticConnectorVars.METAMASK
    } else if (window.ethereum.isToshi || window.ethereum.isCoinbaseWallet) {
      providerName = 'Coinbase'
    }
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: providerName,
      provider: homesteadProvider,
      signer: signer,
    })
    // this.close()
  },
  removeMetamaskListeners () {
    window.ethereum.removeAllListeners('accountsChanged')
    window.ethereum.removeAllListeners('chainChanged')
  },
  addMetamaskListeners () {
    window.ethereum.on('accountsChanged', accounts => {
      var checksum_address = ethers.utils.getAddress(accounts[0])
      store.commit('updateEthers', {
        coinbase: checksum_address
      })
    })
    window.ethereum.on('chainChanged', () => {
      this.connectToMetamask()
    })
  },
  disconnect () {
    if (store.state.ethers.providerName === StaticConnectorVars.WALLETCONNECT) {
      store.state.ethers.walletConnectProvider.disconnect()
    }
    if (store.state.ethers.providerName === StaticConnectorVars.WALLETLINK) {
      store.state.ethers.walletLinkProvider.disconnect()
    }
    store.commit('updateEthers', {
      coinbase: null,
      coinbase_condensed: null,
      providerName: null,
      signer: null,
      walletConnectProvider: null,
      walletLinkProvider: null
    })
    
    this.connectViewOnlyProvider()
  },
}

export default Self