<template>
  <v-card class="pa-2 ma-1">

    <div class="d-flex align-center">
      
      <div>
        {{ addressCondensed }}
      </div>

      <v-spacer></v-spacer>

      <v-btn small icon @click="debug = !debug">
        <v-icon small>mdi-bug</v-icon>
      </v-btn>

    </div>

    <div v-if="debug" class="caption grey--text">
      <div>
        {{ address }}
      </div>

      <div>
        {{ pairInfo }}
      </div>
    </div>
    
    <template v-if="pairInfo.token0">
      <token-of-pair-row :address="pairInfo.token0" :reserve="pairInfo.reserve0" :debug="debug"></token-of-pair-row>
      <token-of-pair-row :address="pairInfo.token1" :reserve="pairInfo.reserve1" :debug="debug"></token-of-pair-row>
      <div>
        TotalSupply: {{ totalSupplyHuman }}
      </div>
    </template>
    
  </v-card>
</template>

<script>
import { ethers } from 'ethers'
import SMC from './smc'
import TokenOfPairRow from './token-of-pair-row'

export default {
  components: {
    TokenOfPairRow
  },

  props: {
    address: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    debug: false,
    pairInfo: {}
  }),

  computed: {
    addressCondensed () {
      var address = this.address
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.pairInfo.totalSupply, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 18})
    },
  },

  methods: {
    async sync () {
      var pair_info = await SMC.getPairInfo(this.address)
      this.pairInfo = pair_info
    }
  },

  created () {
    this.sync()
  }
}
</script>